import { GridPaginationModel } from '@mui/x-data-grid-premium';
import { IPaginationParams } from '../interfaces/IPaginationParams';

export const mapPaginationModelToPaginationParams = (
  paginationModel: GridPaginationModel,
): IPaginationParams => {
  return {
    page: paginationModel.page,
    first: paginationModel.pageSize,
  };
};
