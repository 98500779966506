import Axios, { AxiosInstance } from 'axios';

export class AlCaponeService {
  private http: AxiosInstance;

  constructor() {
    this.http = Axios.create({
      baseURL: import.meta.env.VITE_AL_CAPONE_URL,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
  }

  public async importPositions(form: FormData) {
    const { data } = await this.http.post('/positions/importer', form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: 0,
    });

    return data;
  }
  public async importOffers(filename: string) {
    const { data } = await this.http.post(
      '/offers/importer/import',
      { filename },
      {
        timeout: 0,
      },
    );

    return data;
  }
}
