import { NumberColorized } from '@common/components/NumberColorized';
import { safeFormatDate } from '@common/date/formatters';
import { columnYieldDescriptionFormatter } from '@common/formatters/columnYieldDescriptionFormatter';
import { formatReaisOrUndefined } from '@common/logic/formatReaisOrUndefined';
import { formatPercentage, formatReais } from '@invest-ai/common';
import LinkIcon from '@mui/icons-material/Link';
import { IconButton } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-premium';
import { columnReaisWithoutPrefixForamtter } from '../../../../common/formatters/columnReaisFormatter';
import { CustomNicknamePositionCell } from '../components/CustomNicknamePositionCell';
import { NavigateToDeal } from '../components/NavigateToDealOrCreateNew';
import { IFixedIncomePositionRow } from '../interfaces/IFixedIncomePositionRow';

export const compareOffersColumns: Array<GridColDef<IFixedIncomePositionRow>> =
  [
    {
      field: 'advisorExternalId',
      headerName: 'Assessor',
      minWidth: 100,
      sortable: false,
    },
    {
      field: 'brokerAccountNumber',
      headerName: 'Conta',
      minWidth: 100,
      sortable: false,
    },
    {
      field: 'nickname',
      headerName: 'Ativo a vender',
      flex: 1,
      renderCell: (params) => {
        const { nickname, creationMode, id } = params.row;
        return (
          <CustomNicknamePositionCell
            positionId={id}
            nickname={nickname}
            creationMode={creationMode}
            baseUrl='/comparador'
          />
        );
      },
    },
    {
      field: 'applicationDate',
      headerName: 'Data aplicação',
      minWidth: 150,
      valueFormatter: (v) => safeFormatDate(v.value),
    },
    {
      field: 'currentPrice',
      headerName: 'Posição atual',
      minWidth: 150,
      renderCell: ({ value }) => formatReais(value),
      valueFormatter: columnReaisWithoutPrefixForamtter,
    },
    {
      field: 'yieldDescription',
      headerName: 'Taxa',
      minWidth: 120,
      valueFormatter: (params) => {
        if (params.value) {
          return columnYieldDescriptionFormatter(params);
        }

        return '-';
      },
    },
    {
      field: 'duration',
      headerName: 'Duration',
      maxWidth: 80,
      sortable: false,
      valueFormatter: (params) => {
        if (params.value) {
          return params.value.toFixed(2).replace('.', ',');
        }
        return '-';
      },
    },
    {
      field: 'averageAgio',
      headerName: 'Ágio D+0',
      maxWidth: 80,
      renderCell: (params) => {
        if (params.value) {
          return (
            <NumberColorized
              value={formatPercentage(params.value)}
              valueStyle={{ fontSize: 14 }}
            />
          );
        }
        return '-';
      },
      valueFormatter: ({ value }) => formatPercentage(value),
    },
    {
      field: 'customerMarkUp',
      headerName: 'Ágio Duration',
      minWidth: 150,
      sortable: false,
      renderCell: (params) => {
        if (params.value) {
          return (
            <NumberColorized
              value={formatReaisOrUndefined(params.value)}
              valueStyle={{ fontSize: 14 }}
            />
          );
        }
        return '-';
      },
      valueFormatter: columnReaisWithoutPrefixForamtter,
    },

    {
      field: 'stretching',
      headerName: 'Alongamento',
      sortable: false,
      valueFormatter: (params) => {
        if (params.value) {
          return params.value;
        }
        return '-';
      },
    },
    {
      field: 'advisorROAReais',
      headerName: 'ROA',
      minWidth: 120,
      sortable: false,
      renderCell: (params) => {
        if (params.value) {
          return (
            <NumberColorized
              value={formatReaisOrUndefined(params.value)}
              valueStyle={{ fontSize: 14 }}
            />
          );
        }
        return '-';
      },
      valueFormatter: columnReaisWithoutPrefixForamtter,
    },
    {
      field: 'dealLink',
      headerName: 'Link Compartilhável',
      maxWidth: 50,
      sortable: false,
      renderCell: (params) => {
        return (
          <IconButton href={params.value} target='_blank'>
            <LinkIcon />
          </IconButton>
        );
      },
    },
    {
      field: 'Ações',
      type: 'actions',
      headerName: 'Ações',
      hideable: false,
      sortable: false,
      renderCell: (params) => {
        if (!params.row.rebuyUnitPrice) {
          return null;
        }
        return (
          <NavigateToDeal
            fixedIncomePositionId={params.row.id}
            offerId={params.row.offerId}
          />
        );
      },
    },
  ];
