import { safeFormatDate } from '@common/date/formatters';
import { formatReais } from '@invest-ai/common';
import { Box, Typography } from '@mui/material';
import { RebuyUnitPriceTickerDataSource } from './RebuyUnitPriceTickerDataSource';
import { getSourceLabelFromTickerDataSource } from './getSourceLabelFromTickerDataSource';

export const TickerDataSourceBlock: React.FC<{
  tickerDataSource: RebuyUnitPriceTickerDataSource;
}> = ({ tickerDataSource }) => {
  return (
    <Box
      key={tickerDataSource.source}
      sx={{ mb: 2, p: 1, borderRadius: 1 }}
      style={{
        backgroundColor: tickerDataSource.isChoosen ? '#D3FCD2' : 'inherit',
      }}
    >
      <Typography variant='overline' component='span'>
        {getSourceLabelFromTickerDataSource(tickerDataSource.source)}{' '}
        <Typography variant='caption' color='grey' component='span'>
          ({safeFormatDate(tickerDataSource.referenceDate)})
        </Typography>
      </Typography>

      <Typography>
        <strong>{formatReais(tickerDataSource.rebuyUnitPrice)}</strong>
      </Typography>
    </Box>
  );
};
