import { ProductTypes } from '@common/enum/ProductTypes';

type contentProps = {
  label: string;
  productKind: string[];
};

type productTypesProps = { [productType: string]: contentProps };

export const PRODUCT_TYPE_OPTIONS: productTypesProps = {
  [ProductTypes.ALL]: {
    label: 'Todos',
    productKind: [],
  },

  [ProductTypes.CP]: {
    label: 'CRI/CRA e Debêntures',
    productKind: ['CRI', 'CRA', 'DEBENTURE'],
  },
  [ProductTypes.EB]: {
    label: 'CDB/LC/LCA/LCI e LF',
    productKind: ['CDB', 'LCA', 'LCI', 'LF', 'LC'],
  },
  [ProductTypes.TP]: {
    label: 'LTN/NTN-B/NTN-F e LFT',
    productKind: ['LTN', 'NTN-B', 'NTN-F', 'LFT'],
  },
};
