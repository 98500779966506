import React from 'react';
import ContentLoader from 'react-content-loader';

export const LoadingText: React.FC = () => {
  return (
    <ContentLoader
      speed={1}
      backgroundColor='#f3f3f3'
      foregroundColor='#ecebeb'
      width={40}
      height={10}
    >
      <rect x='0' y='0' rx='5' ry='5' width={40} height={10} />
    </ContentLoader>
  );
};
