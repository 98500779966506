import { useMemo } from 'react';
import { countFilters } from '../logic/countFilters';
import { FixedIncomePositionsListParamsContext } from './useFixedIncomePositionsListParams';

export const usePositionsListFilterCount = () => {
  const { filterParams } = FixedIncomePositionsListParamsContext.useContainer();

  const count = useMemo(() => countFilters(filterParams), [filterParams]);

  return {
    filtersCount: count,
  };
};
