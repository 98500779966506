import { useState } from 'react';
import { ImportOfferSteps } from '../../hooks/ImportOfferSteps';
import { useUploadFile } from '../../hooks/useUploadFile';

export const useUploadFileToGCP = ({
  onUploadFinished,
}: {
  onUploadFinished: (filename: string) => Promise<any>;
}) => {
  const { uploadFile, progress, loading: loadingUploadFile } = useUploadFile();
  const [step, setStep] = useState(ImportOfferSteps.IDLE);
  const [error, setError] = useState<null | Error>(null);
  const uploadFileToGCP = async (file: File) => {
    setError(null);
    try {
      setStep(ImportOfferSteps.UPLOADING_FILE);
      const { filename } = await uploadFile(file);
      setStep(ImportOfferSteps.IMPORTING_OFFERS);

      await onUploadFinished(filename);
    } catch (err) {
      if (err instanceof Error) {
        setError(err);
      }
    } finally {
      setStep(ImportOfferSteps.IDLE);
    }
  };
  return {
    loading: loadingUploadFile || step === ImportOfferSteps.IMPORTING_OFFERS,
    uploadFileToGCP,
    progress,
    step,
  };
};
