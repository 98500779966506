import { gql, useMutation } from '@apollo/client';
import { TickerDataSource } from 'src/__generated__/types';

export const IMPORT_TICKER_DATA_MUTATION = gql`
  mutation ImportTickerData($gcpUrl: String!, $type: TickerDataSource!) {
    importTickerDataFromGCPUrl(gcpUrl: $gcpUrl, type: $type)
  }
`;

export const useImportTickerData = () => {
  const [_importTickerData, { loading, error }] = useMutation(
    IMPORT_TICKER_DATA_MUTATION,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const importTickerData = (gcpUrl: string, type: TickerDataSource) =>
    _importTickerData({
      variables: {
        gcpUrl,
        type,
      },
    });

  return {
    importTickerData,
    loading,
    error,
  };
};
