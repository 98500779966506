import { QueryHookOptions, gql, useQuery } from '@apollo/client';
import { useOfferCustomYieldDescription } from 'src/modules/deals/components/DisplayDeal/hooks/useOfferCustomYieldDescription';
import {
  FixedIncomeOfferByIdQuery,
  FixedIncomeOfferByIdQueryVariables,
} from '../../../__generated__/types';
import { MaybeNil } from '../../deals/components/DisplayDeal/logic/MaybeNil';
import { evolveFixedIncomeOffer } from './evolveFixedIncomeOffer';

export const FIXED_INCOME_OFFER_BY_ID_QUERY = gql`
  query FixedIncomeOfferById($id: UUID!) {
    fixedIncomeOfferById(id: $id) {
      id
      nickname
      externalId
      indexer
      yieldRate
      indexerPercentRate
      maturityDate
      financialInstitutionId
      marketType
      source
      referenceUnitPrice
      referenceDate
      productKind
      availableQuantity
      roa
      rating
      amortizationPeriod
      firstAmortizationPaymentDate
      duration
      freeOfIR
      indexerPercentRateMaximum
      isActive
      issueType
      issuerId
      target
      yieldRateMaximum
      interestPeriod
      firstInterestPaymentDate
      createdBy

      yieldDescription: yieldDescriptionMinimum {
        indexer
        yieldRate
        indexerPercentRate
      }
    }
  }
`;

export const useFixedIncomeOfferById = (
  id: MaybeNil<string>,
  options?: QueryHookOptions<
    FixedIncomeOfferByIdQuery,
    FixedIncomeOfferByIdQueryVariables
  >,
) => {
  const { data, loading, error, refetch } = useQuery<
    FixedIncomeOfferByIdQuery,
    FixedIncomeOfferByIdQueryVariables
  >(FIXED_INCOME_OFFER_BY_ID_QUERY, {
    ...options,
    variables: {
      id: id as string,
    },
    fetchPolicy: 'no-cache',
  });

  const { offerCustomYieldDescriptionMap } = useOfferCustomYieldDescription();

  const fixedIncomeOffer = data?.fixedIncomeOfferById;

  const evolvedFixedIncomeOffer = evolveFixedIncomeOffer(
    offerCustomYieldDescriptionMap,
    fixedIncomeOffer,
  );
  return {
    fixedIncomeOffer: evolvedFixedIncomeOffer,
    loading,
    error,
    refetch,
  };
};
