import { formatReaisOrUndefined } from '@common/logic/formatReaisOrUndefined';
import { formatYieldDescriptionOrUndefined } from '@common/logic/formatYieldDescription';
import { Box, Button, Grid, Typography } from '@mui/material';
import React from 'react';
import { FixedIncomeOfferSource } from '../../../../../__generated__/types';
import warningSymbol from '../../../../../assets/warning.svg';
import { FreeOfIRToken } from '../../../../fixed-income-positions/components/FakeSelectOffer/FreeOfIRToken';
import { QualifiedInvestorToken } from '../../../../fixed-income-positions/components/FakeSelectOffer/QualifiedInvestorToken';
import { ManualCreationToken } from '../../../../fixed-income-positions/components/FixedIncomePositionsList/components/ManualCreationToken';
import { useDisplayDealDrawersNavigation } from '../../../../offers/context/useDisplayDealDrawersNavigation';
import { useCalculateDealFromRoute } from '../../../hooks/useCalculateDealFromRoute';
import { VisualizationContext } from '../../../pages/DealsPage/VisualizationContext';
import { valueOrLoader } from '../../DealPitch/valueOrLoader';
import { InformationBlock } from '../../InformationBlock';
import { useOfferCustomYieldDescription } from '../hooks/useOfferCustomYieldDescription';
import { MaybeNil } from '../logic/MaybeNil';
import { formatDateOrUndefined } from '../logic/formatDateOrUndefined';
import { getAmortizationTextOrUndefined } from '../logic/getAmortizationText';
import { getMinorDuration } from '../logic/getMinorDuration';
import { getTooltipYearlyYieldRate } from '../logic/getTooltipYearlyYieldRate';
import { UpdateFixedIncomeOfferYieldDescription } from './UpdateFixedIncomeOfferYieldDescription';

export const DealFixedIncomeOffer: React.FC<{
  positionId: string;
  offerId: MaybeNil<string>;
}> = ({ offerId, positionId }) => {
  const { hideSensitiveInformation } = VisualizationContext.useContainer();
  const { openAvailableOffersDrawer, openEditOfferDrawer } =
    useDisplayDealDrawersNavigation();

  const { loading, fixedIncomePosition, dealFinancials, fixedIncomeOffer } =
    useCalculateDealFromRoute({
      offerId,
      positionId,
    });

  const isLoadingOffer = loading;
  const inactiveOffer =
    !!offerId && !isLoadingOffer && !fixedIncomeOffer?.isActive;

  const amortizationText =
    getAmortizationTextOrUndefined(
      fixedIncomeOffer?.amortizationPeriod,
      fixedIncomeOffer?.firstAmortizationPaymentDate as string,
    ) ?? '-';
  const maturityDateText = formatDateOrUndefined(
    fixedIncomeOffer?.maturityDate,
  );

  const interestPeriodText =
    getAmortizationTextOrUndefined(
      fixedIncomeOffer?.interestPeriod,
      fixedIncomeOffer?.firstInterestPaymentDate as string,
    ) ?? '-';

  const minorDuration = getMinorDuration(
    fixedIncomePosition?.ticker?.duration ?? 0,
    fixedIncomeOffer?.duration ?? 0,
  );
  const ratingLabel = fixedIncomeOffer?.rating ?? '-';

  const updatedAtText = formatDateOrUndefined(fixedIncomeOffer?.referenceDate);

  const durationLabel = fixedIncomeOffer?.duration?.toFixed(2);
  const selectedOfferNicknameLabel = offerId
    ? valueOrLoader(fixedIncomeOffer?.nickname)
    : 'Sem ativo selecionado';

  const updatedAtLabel = offerId ? (
    <>Atualizado em {valueOrLoader(updatedAtText)}</>
  ) : (
    '-'
  );

  const shouldDisplayManualCreationToken =
    !hideSensitiveInformation &&
    fixedIncomeOffer?.source === FixedIncomeOfferSource.CREATED_BY_ADVISOR;

  const shouldDisplayUpdateFixedIncomeOfferYieldDescription =
    !hideSensitiveInformation && fixedIncomeOffer?.yieldDescription;
  return (
    <Grid container rowSpacing={2} marginTop={6}>
      <Grid item xs={12}>
        <Box display='flex' flexDirection='row'>
          <Typography variant='h6'>Ativo a ser comprado</Typography>
          {inactiveOffer && (
            <img style={{ marginLeft: 5 }} src={warningSymbol} alt='img' />
          )}
        </Box>
        <Box display='flex' flexDirection='row'>
          {inactiveOffer && (
            <Typography fontSize={12} color='#B71D18' marginRight={1}>
              O ativo não está mais disponível.
            </Typography>
          )}
        </Box>
        {!hideSensitiveInformation && (
          <Box>
            <Typography fontSize={12} marginBottom={1} color='#919EAB'>
              {updatedAtLabel}
            </Typography>
          </Box>
        )}

        <Grid
          item
          flexDirection='row'
          display='flex'
          justifyContent='space-between'
        >
          <Grid
            container
            flex={1}
            padding={2}
            borderRadius={1}
            marginRight={2}
            bgcolor={'#F6F7F8'}
          >
            <Typography
              variant='subtitle1'
              style={{
                color: inactiveOffer ? '#919EAB' : '#637381',
              }}
            >
              {selectedOfferNicknameLabel}
            </Typography>
            {fixedIncomeOffer?.freeOfIR && (
              <FreeOfIRToken sx={{ marginLeft: 1 }} />
            )}
            {fixedIncomeOffer?.target === 'QUALIFIED' && (
              <QualifiedInvestorToken sx={{ marginLeft: 1 }} />
            )}
            {shouldDisplayManualCreationToken && (
              <ManualCreationToken
                onClick={() => openEditOfferDrawer(fixedIncomeOffer.id)}
                sx={{ marginLeft: 1 }}
              />
            )}
          </Grid>
          {!hideSensitiveInformation && (
            <Button
              onClick={openAvailableOffersDrawer}
              variant={inactiveOffer ? 'contained' : 'outlined'}
            >
              {inactiveOffer ? 'Trocar ativo' : 'Escolher ativo'}
            </Button>
          )}
        </Grid>
      </Grid>
      {offerId ? (
        <>
          <Grid item xs={4}>
            <InformationBlock
              label='Data de vencimento'
              value={maturityDateText}
              inactive={inactiveOffer}
              loading={loading}
            />
          </Grid>
          <Grid item xs={4}>
            <InformationBlock
              label='Juros'
              value={interestPeriodText}
              inactive={inactiveOffer}
              loading={loading}
            />
          </Grid>
          <Grid item xs={4}>
            <InformationBlock
              label='Amortização'
              value={amortizationText}
              inactive={inactiveOffer}
              loading={loading}
            />
          </Grid>
          <Grid item xs={4}>
            <InformationBlock
              label='Rating'
              loadingHeight={40}
              value={ratingLabel}
              inactive={inactiveOffer}
              loading={loading}
            />
          </Grid>
          <Grid item xs={4}>
            <InformationBlock
              label='Taxa'
              loadingHeight={40}
              addornment={
                shouldDisplayUpdateFixedIncomeOfferYieldDescription && (
                  <UpdateFixedIncomeOfferYieldDescription
                    id={offerId}
                    currentYieldDescription={fixedIncomeOffer?.yieldDescription}
                  />
                )
              }
              value={formatYieldDescriptionOrUndefined(
                fixedIncomeOffer?.yieldDescription,
              )}
              inactive={inactiveOffer}
              loading={loading}
            />
          </Grid>
          <Grid item xs={4}>
            <InformationBlock
              label='Duration'
              loadingHeight={40}
              value={durationLabel}
              inactive={inactiveOffer}
              loading={loading}
            />
          </Grid>
          <Grid item xs={4}>
            <InformationBlock
              label='Valor a aplicar'
              value={formatReaisOrUndefined(
                dealFinancials?.substituteFixedIncomeOfferPriceApplied,
              )}
              inactive={inactiveOffer}
              loading={loading}
            />
          </Grid>
          <Grid item xs={4}>
            <InformationBlock
              label={`Liquido projetado em ${minorDuration} anos`}
              value={formatReaisOrUndefined(
                dealFinancials?.substituteFixedIncomeOfferProjectedPrice,
              )}
              tooltip={getTooltipYearlyYieldRate(
                fixedIncomeOffer?.yieldDescription.indexer,
                dealFinancials?.offerYearlyYieldRate,
              )}
              inactive={inactiveOffer}
              loading={loading}
            />
          </Grid>
        </>
      ) : null}
    </Grid>
  );
};
