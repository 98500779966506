import { Button } from '@invest-ai/animatrix';
import {
  Box,
  Container,
  Grid,
  LinearProgress,
  Paper,
  Typography,
} from '@mui/material';
import { FormEvent } from 'react';

import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { useUserBrokerAccounts } from 'src/modules/broker-accounts/hooks/useBrokerAccounts';
import { useUserAdvisors } from 'src/modules/custom-position/hooks/useAvailableAdvisorsAndBrokerAccountsForMyUser';
import { ImportedItem } from '../../components/ImportedItem';
import { useImportPositions } from '../../hooks/useImportPositions';
import { useMyPositionsImporterHistoric } from '../../hooks/useMyPositionsImporterHistoric';
import { MGMBanner } from '../ListPositions/MGMBanner';
import { getMessageFromAxiosError } from './getMessageFromAxiosError';

export const ImportPositionsPage: React.FC = () => {
  const { historic, refetch: refetchHistoric } =
    useMyPositionsImporterHistoric();
  const { loading, importPositions } = useImportPositions();
  const { refetch: refetchAdvisors } = useUserAdvisors();
  const { refetch: refetchBrokerAccounts } = useUserBrokerAccounts();

  async function sendDocument(event: FormEvent<HTMLInputElement>) {
    const target = event.target as HTMLInputElement;
    const file = target.files as FileList;

    const formData = new FormData();
    formData.append('file', file[0]);

    try {
      await importPositions(formData);

      await Promise.all([
        refetchHistoric(),
        refetchAdvisors(),
        refetchBrokerAccounts(),
      ]);
      toast.success('Planilha importada com sucesso!');
    } catch (err) {
      if (err instanceof AxiosError) {
        const message = getMessageFromAxiosError(err);
        toast.error(message, {
          autoClose: false,
        });
      } else if (err instanceof Error) {
        toast.error(`Erro. Entre em contato com o suporte. ${err.message}`, {
          autoClose: false,
        });
      } else {
        throw err;
      }
    }
  }

  return (
    <Container maxWidth={'xl'}>
      <MGMBanner />
      <Paper elevation={5}>
        <Grid
          flexDirection='row'
          display='flex'
          justifyContent='space-between'
          alignItems={'center'}
          width={'100%'}
          paddingX={4}
          paddingY={3}
        >
          <Typography variant='h6'>Importar planilha de posições</Typography>
          <Button
            variant='contained'
            component='label'
            color='primary'
            loading={loading}
          >
            Adicionar planilha
            <input
              type='file'
              hidden
              onChange={sendDocument}
              accept='.xlsx,.xls,.csv,.xlsm'
            />
          </Button>
        </Grid>
        {loading && <LinearProgress />}
        {historic?.at(0) && (
          <>
            <Box>
              {historic?.map((item, index) => (
                <ImportedItem key={item?.id} data={item} first={index === 0} />
              ))}
            </Box>
          </>
        )}
      </Paper>
    </Container>
  );
};
