import { PublicDealPage } from 'src/modules/deals/pages/DealsPage/PublicDealPage';
import { PublicLayout } from 'src/modules/public-deal/layout/PublicLayout/PublicLayout';
import { urlJoin } from 'url-join-ts';

export const publicRoutes = [
  // Public routes
  {
    path: 'p',
    element: <PublicLayout />,
    children: [
      {
        path: urlJoin('negociacao', ':positionId', ':offerId'),
        element: <PublicDealPage isCustomer={false} />,
      },
    ],
  },
  {
    path: 'c',
    element: <PublicLayout />,
    children: [
      {
        path: urlJoin('negociacao', ':positionId', ':offerId'),
        element: <PublicDealPage isCustomer />,
      },
    ],
  },
];
