import { useMemo } from 'react';
import { useAvailableAdvisorsAndBrokerAccountsForMyUser } from './useAvailableAdvisorsAndBrokerAccountsForMyUser';

export const useBrokerAccountsByAdvisor = (advisorId: string | undefined) => {
  const { advisorsList } = useAvailableAdvisorsAndBrokerAccountsForMyUser();

  const advisor = useMemo(
    () =>
      advisorId
        ? advisorsList?.find((advisor: any) => advisor.id === advisorId)
        : undefined,
    [advisorsList, advisorId],
  );

  return {
    brokerAccounts: (advisor?.brokerAccounts as any[]) ?? [],
  };
};
