import { Loading } from '@invest-ai/animatrix';
import { Edit } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { isNil } from 'ramda';
import { toast } from 'react-toastify';
import { useCreateCustomPitch } from './useCreateCustomPitch';

export const CreateOrUpdateCustomPitchText: React.FC<{
  positionId: string;
  offerId: string;
  currentCustomText: string | undefined;
  onSuccess: () => Promise<void> | void;
}> = ({ positionId, offerId, currentCustomText, onSuccess }) => {
  const { loading, createCustomPitch } = useCreateCustomPitch();

  const handleClick = async () => {
    try {
      const customPitchText = prompt('Novo texto', currentCustomText ?? '');
      if (isNil(customPitchText)) {
        return;
      }

      await createCustomPitch({
        fixedIncomeOfferId: offerId,
        fixedIncomePositionId: positionId,
        customText: customPitchText,
      });
      await onSuccess();
      toast.success('Texto atualizado com sucesso');
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message);
      }
    }
  };

  if (loading) {
    return <Loading size={18} sx={{ margin: 1 }} />;
  }

  return (
    <IconButton onClick={handleClick} size={'small'}>
      <Edit />
    </IconButton>
  );
};
