export const FREE_OF_IR_OPTIONS = [
  {
    label: 'Sim',
    value: 1,
  },
  {
    label: 'Não',
    value: 0,
  },
];
