import { Button } from '@invest-ai/animatrix';
import React from 'react';
import { toast } from 'react-toastify';
import { useDeletePosition } from '../../hooks/useDeleteFixedIncomePosition';

export const DeletePositionButton: React.FC<{
  positionId: string;
  onSuccess: (positionId: string) => void;
}> = ({ positionId, onSuccess }) => {
  const { deleteFixedIncomePosition, loading: loadingDeletePosition } =
    useDeletePosition();

  async function deletePosition() {
    try {
      const confirmed = confirm('Deseja excluir a posição?');
      if (!confirmed) {
        return;
      }
      await deleteFixedIncomePosition(positionId ?? '');
      toast.success('Posição deletada com sucesso');
      onSuccess(positionId ?? '');
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message);
      }
    }
  }

  return (
    <Button
      loading={loadingDeletePosition}
      variant='outlined'
      onClick={deletePosition}
      color='error'
      sx={{ mr: 2 }}
    >
      Excluir posição
    </Button>
  );
};
