import { omit } from 'ramda';
import { toast } from 'react-toastify';
import { useUpdateFixedIncomePosition } from 'src/modules/deals/hooks/useUpdateFixedIncomePosition';
import { IFormData } from './interfaces/IFormData';
import { fixValues } from './logic/fixValues';

export const useUpdateFixedIncomePositionWithFeedback = (
  positionId: string,
  onSuccess: (positionId: string) => void,
) => {
  const { updateFixedIncomePosition, loading } = useUpdateFixedIncomePosition();
  const updateFixedIncomePositionWithFeedback = async (data: IFormData) => {
    if (!positionId) {
      return;
    }
    const fixedValues = omit(['advisorId', 'brokerAccountId'], fixValues(data));
    try {
      const response = await updateFixedIncomePosition(positionId, fixedValues);
      if (!response.data?.updateFixedIncomePosition) {
        return;
      }
      toast.success('Posição criada com sucesso');
      onSuccess(response.data?.updateFixedIncomePosition?.id);
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message);
      }
    }
  };
  return {
    updateFixedIncomePositionWithFeedback,
    loading,
  };
};
