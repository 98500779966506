import { SelectOption } from '@invest-ai/animatrix';
import { FixedIncomeProductKind } from '../../../__generated__/types';

export const PRODUCT_OPTIONS: SelectOption<FixedIncomeProductKind>[] = [
  {
    label: 'CDB',
    value: FixedIncomeProductKind.CDB,
  },
  {
    label: 'LCI',
    value: FixedIncomeProductKind.LCI,
  },
  {
    label: 'LCA',
    value: FixedIncomeProductKind.LCA,
  },
  {
    label: 'LF',
    value: FixedIncomeProductKind.LF,
  },
  {
    label: 'LC',
    value: FixedIncomeProductKind.LC,
  },
  {
    label: 'CRI',
    value: FixedIncomeProductKind.CRI,
  },
  {
    label: 'CRA',
    value: FixedIncomeProductKind.CRA,
  },
  {
    label: 'LTN',
    value: FixedIncomeProductKind.LTN,
  },
  {
    label: 'NTNF',
    value: FixedIncomeProductKind.NTNF,
  },
  {
    label: 'NTNB',
    value: FixedIncomeProductKind.NTNB,
  },
  {
    label: 'Debênture',
    value: FixedIncomeProductKind.DEBENTURE,
  },
];
