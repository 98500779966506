export class ClipboardService {
  copyTextToCliboard(text: string) {
    navigator.clipboard.writeText(text);
  }
  copyToClipboard(items: ClipboardItems) {
    navigator.clipboard.write(items);
  }
}

export const clipboardService = new ClipboardService();
