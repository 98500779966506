export class EmailSenderService {
  sendEmail(params: { to: string; subject: string; body: string }) {
    window.open(
      `mailto:${params.to}?subject=${params.subject}&body=${encodeURIComponent(
        params.body,
      )}`,
      '_blank',
    );
  }
}

export const emailSenderService = new EmailSenderService();
