import { isNil } from 'ramda';
import { formatStringDate } from './formatStringDate';

export const AMORTIZATION_PERIOD_TEXT_MAP: Record<number, string> = {
  [-1]: 'No vencimento',
  0: '',
  1: 'Mensal',
  3: 'Trimestral',
  6: 'Semestral',
  12: 'Anual',
};

export const getAmortizationText = (
  amortizationPeriod: number,
  firstAmortizationPaymentDate: string,
) => {
  return `${
    AMORTIZATION_PERIOD_TEXT_MAP[amortizationPeriod]
  }, ${formatStringDate(firstAmortizationPaymentDate)}`;
};

export const getAmortizationTextOrUndefined = (
  amortizationPeriod: number | undefined | null,
  firstAmortizationPaymentDate: string | undefined | null,
): string | undefined => {
  if (isNil(amortizationPeriod) || isNil(firstAmortizationPaymentDate)) {
    return undefined;
  }
  return `${
    AMORTIZATION_PERIOD_TEXT_MAP[amortizationPeriod]
  }, ${formatStringDate(firstAmortizationPaymentDate)}`;
};
