import { Box } from '@mui/material';
import React from 'react';
import { FixedIncomePositionCreationMode } from '../../../../../__generated__/types';
import { usePositionDrawer } from '../../../../custom-position/context/usePositionDrawer';
import { ManualCreationToken } from './ManualCreationToken';

interface ICustomNicknamePositionCellProps {
  positionId: string;
  nickname: string;
  creationMode: FixedIncomePositionCreationMode;
  baseUrl: string;
}

export const CustomNicknamePositionCell: React.FC<
  ICustomNicknamePositionCellProps
> = ({ positionId, nickname, creationMode, baseUrl }) => {
  const { openEditPositionDrawer } = usePositionDrawer(baseUrl);

  function editPositionClick(e: React.SyntheticEvent) {
    e.stopPropagation();
    openEditPositionDrawer(positionId);
  }

  return (
    <Box display='flex' flexDirection='row' alignItems='center'>
      <span>{nickname}</span>
      {creationMode === FixedIncomePositionCreationMode.MANUAL && (
        <ManualCreationToken onClick={editPositionClick} />
      )}
    </Box>
  );
};
