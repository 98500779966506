import { Container } from '@mui/material';
import React from 'react';

import { ImportOffersDashboard } from '../../components/ImportOffersDashboard';

export const ImportOffersPage: React.FC = () => {
  return (
    <Container maxWidth={'xl'}>
      <ImportOffersDashboard />
    </Container>
  );
};
