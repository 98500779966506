import { DEFAULT_COUNTRY_CODE } from '../constants/DEFAULT_COUNTRY_CODE';
import { OpenWhatsAppError } from '../errors/OpenWhatsAppError';

export const fixPhone = (phone: string): string | null => {
  const onlyNumbers = phone.replace(/\D/g, '');

  let finalPhone = onlyNumbers;

  if (onlyNumbers.length < 8 || onlyNumbers.length > 13) {
    throw new OpenWhatsAppError('INVALID_NUMBER', phone);
  }
  if ([8, 9].includes(onlyNumbers.length)) {
    throw new OpenWhatsAppError('NO_DDD', phone);
  }

  if ([11, 12, 13].includes(onlyNumbers.length)) {
    finalPhone = `${DEFAULT_COUNTRY_CODE}${finalPhone}`;
  }

  return finalPhone;
};
