import { GridActionsCellItem } from '@mui/x-data-grid-premium';
import { useUpdateOfferYieldDescription } from 'src/modules/deals/components/DisplayDeal/hooks/useUpdateOfferYieldDescription';
import { YieldDescription } from '../../../../../../../__generated__/types';

export const EditOfferYieldDescriptionAction: React.FC<{
  id: string;
  currentYieldDescription: YieldDescription;
  showInMenu: boolean;
}> = ({ id, currentYieldDescription }) => {
  const { handleEditFixedIncomeOfferYieldDescription } =
    useUpdateOfferYieldDescription({ id, currentYieldDescription });

  return (
    <GridActionsCellItem
      label={'Alterar taxa'}
      onClick={() => handleEditFixedIncomeOfferYieldDescription()}
      showInMenu
    />
  );
};
