import { Card, CardContent, Link, Typography } from '@mui/material';
import React from 'react';
import { InvestAIBU } from 'src/__generated__/types';
import { useMGMDashboard } from '../hooks/useMGMDashboard';

export const MGMCarteiraHowItWorks: React.FC = () => {
  const {
    loading,
    error,
    boletador: { refereePrize, referralPrize },
  } = useMGMDashboard({
    source: InvestAIBU.CARTEIRA,
    target: InvestAIBU.BOLETADOR,
  });

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant='overline' sx={{ mb: 2 }} component='div'>
          Como funciona?
        </Typography>

        <Typography sx={{ mb: 2 }} variant='h5' component='div'>
          Você sabia que a InvestAI também atua como um robô de investimentos,
          conseguindo os{' '}
          <Typography
            gutterBottom
            variant='h4'
            color='primary'
            component='span'
          >
            melhores investimentos de renda fixa do mercado secundário?
          </Typography>
        </Typography>
        <Typography gutterBottom>
          Temos milhares de clientes neste modelo de negócio e bilhões de reais
          já investidos através da nossa plataforma.
        </Typography>
        <Typography gutterBottom>
          Mostre para seu cliente que você está sempre antenado nas últimas
          tecnologias do mercado, indicando-o para utilizar a InvestAI para
          realizar seus investimentos.
        </Typography>
        <Typography gutterBottom>
          Caso queira conhecer nossa plataforma de investimentos, basta acessar{' '}
          <Link
            href='https://invest-ai.com.br'
            target='_blank'
            rel='noreferrer'
          >
            https://invest-ai.com.br
          </Link>
        </Typography>
        <Typography gutterBottom>
          Entrando com seu cupom, o cliente ganha{' '}
          <strong>isenção de taxa nos primeiros R$ 5.000,00</strong> investidos
          para que possa testar a plataforma
        </Typography>
        <Typography gutterBottom>
          Para cada cliente que realizar seu primeiro investimento na nossa
          plataforma, <strong>você ganha R$ 50,00</strong> que serão enviados
          via PIX para você no final do mês
        </Typography>
      </CardContent>
    </Card>
  );
};
