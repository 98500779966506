import { parseServerDate } from '@common/date/parsers';
import { evolve } from 'ramda';
import {
  DealDashboardQuery,
  YieldDescription,
} from '../../../__generated__/types';

export const evolveFixedIncomeOffer = (
  customYieldDescriptionMap: Record<string, YieldDescription>,
  fixedIncomeOffer: DealDashboardQuery['fixedIncomeOfferById'],
) => {
  const evolvedFixedIncomeOffer = fixedIncomeOffer
    ? evolve(
        {
          maturityDate: (v) => parseServerDate(v),
          yieldDescription: (v) =>
            customYieldDescriptionMap[fixedIncomeOffer.id] ?? v,
        },
        fixedIncomeOffer,
      )
    : undefined;
  return evolvedFixedIncomeOffer;
};
