import { gql, useQuery } from '@apollo/client';
import { parseServerDate } from '@common/date/parsers';
import { Evolver, evolve } from 'ramda';
import { useMemo } from 'react';
import {
  OperationByIdQuery,
  OperationByIdQueryVariables,
} from '../../../__generated__/types';

export const OPERATION_BY_ID_QUERY = gql`
  query OperationById($operationId: UUID!) {
    getOperationById(operationId: $operationId) {
      id
      operationDate
      soldPositionLoss
      customerMarkUp
      roaAmount
      roa

      newFixedIncomeOffer {
        nickname
        maturityDate
        interestPeriod
        firstInterestPaymentDate
        amortizationPeriod
        firstAmortizationPaymentDate
        rating
        duration
      }
      newFixedIncomeOfferIndexer
      newFixedIncomeOfferIndexerPercentRate
      newFixedIncomeOfferYieldRate
      newFixedIncomeOfferRemainingBalance

      newFixedIncomeOfferUnitPrice
      newFixedIncomeOfferUnitPriceReferenceDate

      newFixedIncomeOfferQuantity

      soldPositionUnitPrice
      soldPositionReferenceDate

      fixedIncomePosition {
        nickname
        yieldDescription {
          yieldRate
          indexer
          indexerPercentRate
        }
        applicationDate
        maturityDate

        brokerAccount {
          accountNumber
        }
        priceApplied
        duration
      }
      quantity

      rebuyYieldDescription {
        indexer
        yieldRate
        indexerPercentRate
      }
      rebuyPrice
      rebuyNetPrice
      newFixedIncomeOfferPrice
      positionSnapshot {
        currentPrice
        currentNetPrice
        rebuyUnitPrice
        substituteFixedIncomeOfferPriceApplied
        substituteFixedIncomeOfferProjectedPrice
      }
    }
  }
`;

export const useOperationById = (operationId: string) => {
  const { data, loading, error } = useQuery<
    OperationByIdQuery,
    OperationByIdQueryVariables
  >(OPERATION_BY_ID_QUERY, {
    variables: {
      operationId,
    },
  });

  const operationEvolver = evolve<
    Evolver<OperationByIdQuery['getOperationById']>
  >({
    operationDate: parseServerDate,
    newFixedIncomeOfferUnitPriceReferenceDate: parseServerDate,
    soldPositionReferenceDate: parseServerDate,
    fixedIncomePosition: {
      applicationDate: parseServerDate,
      maturityDate: parseServerDate,
    },
    newFixedIncomeOffer: {
      maturityDate: parseServerDate,
    },
  });
  const transformedOperation = useMemo(
    () => (data ? operationEvolver(data.getOperationById) : null),
    [data],
  );

  return {
    operation: transformedOperation,
    loading,
    error,
  };
};
