import { Drawer } from '@mui/material';
import React from 'react';
import { FixedIncomePositionsFilter } from './FixedIncomePositionsFilter';
import { FixedIncomePositionsFilterDrawerContext } from './FixedIncomePositionsFilterDrawerContext';

export const FixedIncomePositionsFilterDrawer: React.FC = () => {
  const { visible, toggleVisibility } =
    FixedIncomePositionsFilterDrawerContext.useContainer();

  return (
    <Drawer
      anchor={'right'}
      open={visible}
      onClose={toggleVisibility}
      keepMounted={false}
    >
      <FixedIncomePositionsFilter />
    </Drawer>
  );
};
