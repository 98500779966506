import { formatReaisOrUndefined } from '@common/logic/formatReaisOrUndefined';
import { formatYieldDescriptionOrUndefined } from '@common/logic/formatYieldDescription';
import { Color } from '@invest-ai/animatrix';
import { isNil } from 'ramda';
import { useCalculateDealFromRoute } from '../../../hooks/useCalculateDealFromRoute';
import { VisualizationContext } from '../../../pages/DealsPage/VisualizationContext';
import { DATE_TIME_PATTERN } from '../constants/DATE_TIME_PATTERN';
import { MaybeNil } from '../logic/MaybeNil';
import { formatDateOrUndefined } from '../logic/formatDateOrUndefined';
import { getMinorDuration } from '../logic/getMinorDuration';

export const useDealFixedIncomePositionComponentData = ({
  positionId,
  offerId,
}: { positionId: string; offerId: MaybeNil<string> }) => {
  const { hideSensitiveInformation } = VisualizationContext.useContainer();
  const {
    error: errorCalculateBondToBondDealFinancials,
    dealFinancials,
    fixedIncomeOffer,
    fixedIncomePosition,
    loading: loadingDealFinancials,
    refetch: refetchDealFromRoute,
  } = useCalculateDealFromRoute({
    positionId,
    offerId,
  });
  const loadingFixedIncomePosition = loadingDealFinancials;
  const errorFixedIncomePosition = errorCalculateBondToBondDealFinancials;
  const error =
    errorCalculateBondToBondDealFinancials || errorFixedIncomePosition;
  const loading = loadingFixedIncomePosition || loadingDealFinancials;

  const rebuyYieldDescriptionLabel = formatYieldDescriptionOrUndefined(
    fixedIncomePosition?.rebuyYieldDescription,
  );

  const fixedIncomePositionYieldDescription = formatYieldDescriptionOrUndefined(
    fixedIncomePosition?.yieldDescription,
  );

  const updatedAtText = formatDateOrUndefined(
    fixedIncomePosition?.referenceDate,
    DATE_TIME_PATTERN,
  );

  // TODO: Get from CalculateDeal
  const minorDuration = loading
    ? undefined
    : getMinorDuration(
        // TODO: Why is this nullable?
        fixedIncomePosition?.ticker?.duration ?? 0,
        fixedIncomeOffer?.duration ?? 0,
      );

  const shouldDisplayAsNegative =
    (dealFinancials?.customerLossOnOperationDate ?? 0) > 0;

  const customerMarkupOnOperationDate = -(
    dealFinancials?.customerLossOnOperationDate ?? 0
  );
  const customerMarkupOnOperationDateReais = formatReaisOrUndefined(
    customerMarkupOnOperationDate,
  );
  const durationString =
    fixedIncomePosition?.ticker?.duration?.toFixed(2) ?? 'Indisponível';

  const customerMarkupOnOperationDateColor = shouldDisplayAsNegative
    ? Color.NEGATIVE
    : Color.POSITIVE;

  const shouldDisplayUpdateRebuyUnitPrice = !!(
    fixedIncomePosition && !hideSensitiveInformation
  );

  const shouldDisplayCustomerMarkupOnOperationDate =
    offerId && (!hideSensitiveInformation || customerMarkupOnOperationDate > 0);

  const shouldDisplayProjectedPrice = !isNil(
    fixedIncomePosition?.ticker?.duration,
  );
  return {
    shouldDisplayCustomerMarkupOnOperationDate,
    shouldDisplayUpdateRebuyUnitPrice,
    customerMarkupOnOperationDateColor,
    durationString,
    customerMarkupOnOperationDateReais,
    minorDuration,
    updatedAtText,
    fixedIncomePositionYieldDescription,
    rebuyYieldDescriptionLabel,
    loadingDealFinancials,
    loadingFixedIncomePosition,
    fixedIncomePosition,
    fixedIncomeOffer,
    dealFinancials,
    error,
    refetchDealFromRoute,
    shouldDisplayProjectedPrice,
  };
};
