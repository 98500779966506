import { FixedIncomeProductKind } from '@common/enum/FixedIncomeProductKind';
import { FixedIncomePositionFragmentFragment } from '../../../../../__generated__/types';
import { IFixedIncomePositionRow } from '../interfaces/IFixedIncomePositionRow';
import { getStretchingString } from './getStretchingString';

export const getFixedIncomePositionFragmentToFixedIncomePositionRowMapper =
  (offerMaturityDate: Date, offerId?: string) =>
  (
    fixedIncomePositionFragment: FixedIncomePositionFragmentFragment,
  ): IFixedIncomePositionRow => {
    const stretching = getStretchingString(
      fixedIncomePositionFragment.maturityDate,
      offerMaturityDate,
    );
    return {
      advisorExternalId:
        fixedIncomePositionFragment.brokerAccount.advisor.externalId,
      averageAgio: fixedIncomePositionFragment.averageAgio,
      id: fixedIncomePositionFragment.id,
      brokerAccountCustomerName:
        fixedIncomePositionFragment.brokerAccount.customerName ?? '',
      brokerAccountNumber:
        fixedIncomePositionFragment.brokerAccount.accountNumber,
      nickname: fixedIncomePositionFragment.nickname,
      yieldDescription: fixedIncomePositionFragment.yieldDescription,
      productKind:
        fixedIncomePositionFragment.productKind as FixedIncomeProductKind,
      maturityDate: fixedIncomePositionFragment.maturityDate,
      applicationDate: fixedIncomePositionFragment.applicationDate,
      currentPrice: fixedIncomePositionFragment.currentPrice,
      rebuyYieldDescription: fixedIncomePositionFragment.rebuyYieldDescription,
      rebuyUnitPrice: fixedIncomePositionFragment.rebuyUnitPrice ?? 0,
      advisorROAReais: fixedIncomePositionFragment.financials?.advisorROAReais,
      customerMarkUp: fixedIncomePositionFragment.financials?.customerMarkUp,
      duration: fixedIncomePositionFragment.duration ?? undefined,
      stretching,
      offerId,
      createdBy: fixedIncomePositionFragment.createdBy ?? '',
      creationMode: fixedIncomePositionFragment.creationMode,
      dealLink: fixedIncomePositionFragment.financials?.dealLink,
    };
  };
