import { Indexers } from '../../../__generated__/types';
import { CustomIndexer, IFormData } from '../interfaces/IFormData';

export const fixValues = (data: IFormData): IFormData => {
  let newValues: any;
  if (data.indexer === Indexers.CDI) {
    // indexerPercentRate% CDI + 0%
    newValues = {
      indexer: Indexers.CDI,
      yieldRate: 0,
      indexerPercentRate: data.indexerPercentRate,
    };
  } else if (data.indexer === Indexers.IPCA) {
    // 100% IPCA + yieldRate%
    newValues = {
      indexer: Indexers.IPCA,
      yieldRate: data.yieldRate,
      indexerPercentRate: 1,
    };
  } else if (data.indexer === Indexers.PREFIXADO) {
    // 0% PREFIXADO + yieldRate
    newValues = {
      indexer: Indexers.PREFIXADO,
      yieldRate: data.yieldRate,
      indexerPercentRate: 0,
    };
  } else if ((data.indexer as any) === CustomIndexer.CDI_PLUS) {
    // 100% CDI + yieldRate
    newValues = {
      indexer: Indexers.CDI,
      yieldRate: data.yieldRate,
      indexerPercentRate: 1,
    };
  }

  if ('freeOfIR' in data) {
    newValues = {
      ...newValues,
      freeOfIR: !!data.freeOfIR,
    };
  }

  return {
    ...data,
    ...newValues,
  };
};
