import { DATE_FORMAT_FOR_PARAM, safeFormatDate } from '@common/date/formatters';
import { isNil } from 'ramda';
import { MaybeNil } from './MaybeNil';

export const formatDateOrUndefined = (
  d: MaybeNil<Date>,
  pattern: string = DATE_FORMAT_FOR_PARAM,
) => {
  if (isNil(d)) {
    return undefined;
  }
  return safeFormatDate(d, pattern);
};
