import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';

import { TagDescription } from '@common/components/TagDescription';
import { formatYieldDescriptionOrUndefined } from '@common/logic/formatYieldDescription';
import { formatPercentage } from '@invest-ai/common';
import { isNil } from 'ramda';
import { YieldDescription } from '../../../../__generated__/types';
import { MaybeNil } from '../../../deals/components/DisplayDeal/logic/MaybeNil';
import { FreeOfIRToken } from './FreeOfIRToken';
import { QualifiedInvestorToken } from './QualifiedInvestorToken';

interface IFakeSelectOfferProps {
  openDrawer: () => void;
  offerSelectedLabel: string | undefined;
  freeOfIR: MaybeNil<boolean>;
  investorQualified: MaybeNil<boolean>;
  yieldDescription: MaybeNil<YieldDescription>;
  roa: MaybeNil<number>;
  duration: MaybeNil<number>;
}

export const FakeSelectOffer: React.FC<IFakeSelectOfferProps> = ({
  openDrawer,
  offerSelectedLabel,
  freeOfIR,
  investorQualified,
  yieldDescription,
  roa,
  duration,
}) => {
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const durationLabel = !isNil(duration)
    ? `Duration ${duration.toFixed(2)} anos`
    : '';

  const roaLabel = !isNil(roa) ? `ROA ${formatPercentage(roa)}` : '';

  const yieldDescriptionLabel =
    formatYieldDescriptionOrUndefined(yieldDescription);
  return (
    <Box
      onClick={openDrawer}
      style={{
        flex: 1,
        alignItems: 'center',
        display: 'flex',
        border: '1px solid #e4e3e3',
        borderRadius: 5,
        cursor: 'pointer',
        // TODO: Use css
        opacity: isHover ? 0.6 : 1,
        padding: '10px 20px',
        justifyContent: 'space-between',
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Box display='flex' flexDirection='row'>
        <Typography>
          {offerSelectedLabel ? offerSelectedLabel : 'Comparar com...'}
        </Typography>
        {freeOfIR && <FreeOfIRToken />}
        {investorQualified && <QualifiedInvestorToken sx={{ marginLeft: 5 }} />}
        {yieldDescription && (
          <TagDescription
            text={yieldDescriptionLabel as string}
            sx={{ marginLeft: 2 }}
          />
        )}
        {roa && <TagDescription text={roaLabel} sx={{ marginLeft: 2 }} />}
        {duration && (
          <TagDescription text={durationLabel} sx={{ marginLeft: 2 }} />
        )}
      </Box>
      <KeyboardArrowDownIcon color='disabled' />
    </Box>
  );
};
