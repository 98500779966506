import { gql, useQuery } from '@apollo/client';
import { parseServerDate } from '@common/date/parsers';
import { useOfferCustomYieldDescription } from 'src/modules/deals/components/DisplayDeal/hooks/useOfferCustomYieldDescription';
import {
  GetAvailableFixedIncomeOffersQuery,
  GetAvailableFixedIncomeOffersQueryVariables,
} from '../../../../__generated__/types';
import { getAvailableFixedIncomeOffersVariables } from './getAvailableFixedIncomeOffersVariables';

export const AVAILABLE_FIXED_INCOME_OFFERS_QUERY = gql`
  query GetAvailableFixedIncomeOffers(
    $input: CalculateOfferFinancialsInput!
    $withFinancials: Boolean!
  ) {
    availableFixedIncomeOffers {
      ...FixedIncomeOfferFragment
    }
  }

  fragment FixedIncomeOfferFragment on AL_CAPONEFixedIncomeOffer {
    id
    nickname
    externalId
    yieldDescription: yieldDescriptionMinimum {
      indexer
      indexerPercentRate
      yieldRate
    }

    maturityDate
    financialInstitutionId
    marketType
    source
    referenceUnitPrice
    referenceDate
    productKind
    availableQuantity
    roa
    rating
    amortizationPeriod
    firstAmortizationPaymentDate
    duration
    freeOfIR
    isActive
    issueType
    issuerId
    target
    interestPeriod
    firstInterestPaymentDate
    createdBy
    financials(input: $input) @include(if: $withFinancials) {
      customerMarkUp
      stretchingInMonths
    }
  }
`;

export const useAvailableFixedIncomeOffers = ({
  positionIdToCompare,
}: {
  positionIdToCompare?: string;
}) => {
  const { data, loading, error, refetch } = useQuery<
    GetAvailableFixedIncomeOffersQuery,
    GetAvailableFixedIncomeOffersQueryVariables
  >(AVAILABLE_FIXED_INCOME_OFFERS_QUERY, {
    variables: getAvailableFixedIncomeOffersVariables(positionIdToCompare),
    fetchPolicy: 'no-cache',
  });

  const { offerCustomYieldDescriptionMap } = useOfferCustomYieldDescription();

  return {
    fixedIncomeOffers: data?.availableFixedIncomeOffers.map(
      (fixedIncomeOffer) => ({
        ...fixedIncomeOffer,
        maturityDate: parseServerDate(fixedIncomeOffer.maturityDate),
        yieldDescription:
          offerCustomYieldDescriptionMap[fixedIncomeOffer.id] ??
          fixedIncomeOffer.yieldDescription,
      }),
    ),
    loading,
    error,
    refetch,
  };
};
