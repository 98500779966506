import { SelectOption } from '@invest-ai/animatrix';
import { Indexers } from '../enum/Indexers';

export const INDEXER_OPTIONS: SelectOption<Indexers>[] = [
  {
    label: 'Prefixado',
    value: Indexers.PREFIXADO,
  },
  {
    label: 'CDI%',
    value: Indexers.CDI,
  },
  {
    label: 'CDI+',
    value: Indexers.CDI_PLUS,
  },
  {
    label: 'IPCA+',
    value: Indexers.IPCA,
  },
];
