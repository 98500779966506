import { QueryHookOptions, gql, useQuery } from '@apollo/client';
import { evolveFixedIncomeOffer } from 'src/modules/offers/hooks/evolveFixedIncomeOffer';
import {
  DealDashboardQuery,
  DealDashboardQueryVariables,
  YieldDescription,
} from '../../../__generated__/types';
import { useOfferCustomYieldDescription } from '../components/DisplayDeal/hooks/useOfferCustomYieldDescription';
import { MaybeNil } from '../components/DisplayDeal/logic/MaybeNil';

const DEAL_DASHBOARD_QUERY = gql`
  query DealDashboard(
    $positionId: UUID!
    $offerId: UUID!
    $hasOffer: Boolean!
    $offerYieldDescription: YieldDescriptionInput
  ) {
    customPitch (fixedIncomeOfferId: $offerId, fixedIncomePositionId: $positionId) @include(if: $hasOffer) {
      id
      text: customText
    }
    fixedIncomeOfferById(id: $offerId) @include(if: $hasOffer) {
      id
      nickname
      externalId
      maturityDate
      financialInstitutionId
      marketType
      source
      referenceUnitPrice
      referenceDate
      productKind
      availableQuantity
      roa
      rating
      amortizationPeriod
      firstAmortizationPaymentDate
      duration
      freeOfIR
      isActive
      issueType
      issuerId
      target
      interestPeriod
      firstInterestPaymentDate
      createdBy

      yieldDescription: yieldDescriptionMinimum {
        indexer
        yieldRate
        indexerPercentRate
      }

    }
    fixedIncomePositionById(id: $positionId) {
      id

      rebuyTotalPrice
      appliedTotalPrice

      nickname

      quantity
      referenceUnitPrice
      currentPrice
      referenceDate
      applicationDate
      maturityDate
      productKind
      averageAgio
      ticker {
        id
        duration
        rebuyUnitPrice
        maturityDate
        referenceDate
        freeOfIR
        rebuyTotalPrice
        rebuyTotalNetPrice

        rebuyUnitPriceSources {
          source
          rebuyUnitPrice
          referenceDate
          isChoosen
        }
      }
      rebuyYieldDescription {
        indexer
        yieldRate
        indexerPercentRate
      }
      yieldDescription {
        indexer
        yieldRate
        indexerPercentRate
      }
      brokerAccount {
        id
        customerName
        accountNumber
        advisor {
          externalId
        }
      }
      createdBy
      freeOfIR

      issuerId
    }
    calculateDeal(
      input: {
        fixedIncomeOfferId: $offerId
        fixedIncomePositionId: $positionId
        offerYieldDescription: $offerYieldDescription
      }
    ) @include(if: $hasOffer) {
      dealFinancials {
        customerMarkUp
        advisorROAReais
        customerLossOnOperationDate
        rebuyUnitPrice
        currentPrice
        currentNetPrice
        substituteFixedIncomeOfferPriceApplied
        substituteFixedIncomeOfferQuantity
        remainingBalance
        substituteFixedIncomeOfferProjectedPrice
        fixedIncomePositionProjectedPrice
        offerYearlyYieldRate
        positionYearlyYieldRate
      }
    }
  }
`;

export const useDealDashboard = (
  {
    positionId,
    offerId,
  }: {
    positionId: string;
    offerId: MaybeNil<string>;
  },
  options?: QueryHookOptions<DealDashboardQuery, DealDashboardQueryVariables>,
) => {
  const { offerCustomYieldDescriptionMap } = useOfferCustomYieldDescription();

  const offerYieldDescription = offerId
    ? offerCustomYieldDescriptionMap[offerId]
    : null;

  const { data, loading, error, refetch } = useQuery<
    DealDashboardQuery,
    DealDashboardQueryVariables
  >(DEAL_DASHBOARD_QUERY, {
    ...options,
    variables: {
      positionId,
      offerId: offerId ?? positionId,
      offerYieldDescription,
      hasOffer: !!offerId,
    },
  });

  const fixedIncomeOffer = evolveFixedIncomeOffer(
    offerCustomYieldDescriptionMap,
    data?.fixedIncomeOfferById,
  );

  return {
    fixedIncomePosition: data?.fixedIncomePositionById,
    fixedIncomeOffer,
    dealFinancials: data?.calculateDeal?.dealFinancials,
    customPitch: data?.customPitch,
    loading,
    error,
    refetch,
  };
};
