export const CURRENT_PRICE_RANGE_MARKS = [
  {
    value: 100 * 1000,
    label: '100k',
  },
  {
    value: 250 * 1000,
    label: '250k',
  },
  {
    value: 500 * 1000,
    label: '500k',
  },
  {
    value: 750 * 1000,
    label: '750k',
  },
  {
    value: 1000 * 1000,
    label: '1M',
  },
  {
    value: 1250 * 1000,
    label: '1.25M',
  },
  {
    value: 1500 * 1000,
    label: '1.5M',
  },
  {
    value: 1750 * 1000,
    label: '1.75M',
  },
  {
    value: 2000 * 1000,
    label: '2M+',
  },
];
