import { safeFormatDate } from '@common/date/formatters';
import { columnReaisFormatter } from '@common/formatters/columnReaisFormatter';
import { columnYieldDescriptionFormatter } from '@common/formatters/columnYieldDescriptionFormatter';
import { formatPercentage } from '@invest-ai/common';
import { GridColDef } from '@mui/x-data-grid-premium';
import { CustomNicknamePositionCell } from '../components/CustomNicknamePositionCell';
import { NavigateToDeal } from '../components/NavigateToDealOrCreateNew';
import { IFixedIncomePositionRow } from '../interfaces/IFixedIncomePositionRow';

export const positionsListColumns: Array<GridColDef<IFixedIncomePositionRow>> =
  [
    {
      field: 'advisorExternalId',
      headerName: 'Assessor',
      minWidth: 100,
      sortable: false,
    },
    {
      field: 'brokerAccountNumber',
      headerName: 'Conta',
      minWidth: 100,
      sortable: false,
    },
    {
      field: 'nickname',
      headerName: 'Ativo',
      flex: 1,
      renderCell: (params) => {
        const { nickname, id, creationMode } = params.row;
        return (
          <CustomNicknamePositionCell
            positionId={id}
            nickname={nickname}
            creationMode={creationMode}
            baseUrl='/'
          />
        );
      },
    },
    {
      field: 'indexer',
      headerName: 'Indexador',
    },
    {
      field: 'productKind',
      headerName: 'Produto',
    },
    {
      field: 'yieldDescription',
      headerName: 'Taxa',
      minWidth: 120,
      valueFormatter: (params) => {
        if (params.value) {
          return columnYieldDescriptionFormatter(params);
        }

        return '-';
      },
    },
    {
      field: 'currentPrice',
      headerName: 'Posição atual',
      minWidth: 150,
      valueFormatter: columnReaisFormatter,
    },
    {
      field: 'applicationDate',
      headerName: 'Aplicação',
      valueFormatter: (v) => safeFormatDate(v.value),
    },
    {
      field: 'maturityDate',
      headerName: 'Vencimento',
      valueFormatter: (v) => safeFormatDate(v.value),
    },

    {
      field: 'rebuyYieldDescription',
      headerName: 'Mesa',
      minWidth: 120,
      sortable: false,
      valueFormatter: (params) => {
        if (params.value) {
          return columnYieldDescriptionFormatter(params);
        }

        return '-';
      },
    },
    {
      field: 'averageAgio',
      headerName: 'Ágio D+0',
      sortable: false,
      valueFormatter: (params) => {
        console.log(params);
        if (params.value) {
          return formatPercentage(params.value);
        }
        return '-';
      },
    },
    {
      field: 'Ações',
      type: 'actions',
      hideable: false,
      sortable: false,
      renderCell: (params) => {
        return <NavigateToDeal fixedIncomePositionId={params.row.id} />;
      },
    },
  ];
