import { DisplayError, Loading } from '@invest-ai/animatrix';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import React from 'react';
import { InvestAIBU } from 'src/__generated__/types';
import { useMGMDashboard } from '../hooks/useMGMDashboard';
import { MGMCard } from './MGMCard';

export const MGMCarteiraMyReferrals: React.FC = () => {
  const { loading, error, couponUsage } = useMGMDashboard({
    source: InvestAIBU.CARTEIRA,
    target: InvestAIBU.CARTEIRA,
  });

  if (error) {
    return <DisplayError error={error} />;
  }
  return (
    <Card>
      <CardContent>
        <Typography variant='overline' sx={{ mb: 2 }} component='div'>
          Minhas indicações
        </Typography>

        {loading ? (
          <Loading />
        ) : (
          <Grid container spacing={1}>
            {couponUsage?.map((usage) => {
              return (
                <Grid item sm={4} key={usage.id}>
                  <MGMCard
                    date={usage.createdAt}
                    name={usage.user?.name ?? ''}
                    value={50}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};
