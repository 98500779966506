import { gql, useMutation } from '@apollo/client';
import { FixedIncomePositionsListParamsContext } from '../../fixed-income-positions/components/FixedIncomePositionsList/hooks/useFixedIncomePositionsListParams';
import { FIXED_INCOME_POSITIONS_TO_DEAL_QUERY } from '../../fixed-income-positions/components/FixedIncomePositionsList/hooks/useFixedIncomePositionsToDeal';
import { getFixedIncomePositionsToDealVariables } from '../../fixed-income-positions/components/FixedIncomePositionsList/logic/getFixedIncomePositionsToDealVariables';
export const CREATE_FIXED_INCOME_POSITION_MUTATION = gql`
  mutation CreateFixedIncomePosition($input: CreateFixedIncomePositionInput!) {
    createFixedIncomePosition(input: $input) {
      id
    }
  }
`;

export const useCreateFixedIncomePosition = () => {
  const { filterParams, paginationParams, sortParams, search } =
    FixedIncomePositionsListParamsContext.useContainer();
  const [_createFixedIncomePosition, { loading, error }] = useMutation(
    CREATE_FIXED_INCOME_POSITION_MUTATION,
    {
      refetchQueries: [
        {
          query: FIXED_INCOME_POSITIONS_TO_DEAL_QUERY,
          variables: getFixedIncomePositionsToDealVariables(
            search,
            paginationParams,
            sortParams,
            filterParams,
          ),
        },
      ],
      awaitRefetchQueries: true,
    },
  );

  const createFixedIncomePosition = (input: any) =>
    _createFixedIncomePosition({
      variables: {
        input: {
          ...input,
          referenceDate: new Date(),
        },
      },
    });

  return {
    createFixedIncomePosition,
    loading,
    error,
  };
};
