import { formatReais } from '@invest-ai/common';
import { isNil } from 'ramda';
import { MaybeNil } from '../../deals/components/DisplayDeal/logic/MaybeNil';

export const formatReaisOrUndefined = (n: MaybeNil<number>) => {
  if (isNil(n)) {
    return undefined;
  }
  return formatReais(n);
};
