import { Box, SxProps, Typography } from '@mui/material';

interface ITagDescriptionProps {
  text: string;
  sx?: SxProps;
}

export const TagDescription: React.FC<ITagDescriptionProps> = ({
  text,
  sx,
}) => {
  return (
    <Box
      bgcolor='#DFE3E8'
      display='flex'
      justifyContent='center'
      alignItems='center'
      style={{ padding: '2px 8px', borderRadius: 6 }}
      sx={sx}
    >
      <Typography fontSize={12} fontWeight={700} color='#212B36'>
        {text}
      </Typography>
    </Box>
  );
};
