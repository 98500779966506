import { Box } from '@mui/material';
import React from 'react';
import { ImportedOffersItem } from './ImportedItem';

export const ImportedOffersList: React.FC<{
  historic: Array<any>;
}> = ({ historic }) => {
  return (
    <Box paddingX={4}>
      {historic?.map((item, index) => (
        <ImportedOffersItem key={item?.id} data={item} first={index === 0} />
      ))}
    </Box>
  );
};
