import { useCallback } from 'react';
import {
  useLocation,
  useMatch,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { urlJoin } from 'url-join-ts';

export const usePositionDrawer = (baseUrl: string) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  function closeDrawer() {
    navigate(`${baseUrl}${search}`);
  }

  const openCreatePositionDrawer = useCallback(() => {
    navigate(`${baseUrl}posicoes/criar${search}`);
  }, [baseUrl, search, navigate]);

  function openEditPositionDrawer(positionId: string) {
    const url = urlJoin(baseUrl, 'posicoes', 'editar', positionId);
    navigate(`${url}${search}`);
  }

  const isCreatePositionDrawerOpen = !!useMatch(`${baseUrl}posicoes/criar`);
  const isEditPositionModalOpen = !!useMatch(
    `${baseUrl}posicoes/editar/:positionId`,
  );
  const params = useParams();
  const editPositionId = params.positionId ?? '';

  return {
    isCreatePositionDrawerOpen,
    closeDrawer,
    openCreatePositionDrawer,
    openEditPositionDrawer,
    isEditPositionModalOpen,
    editPositionId,
  };
};
