import { Box, SxProps, Tooltip } from '@mui/material';
import investorQualifiedSymbol from '../../../../assets/investorQualifiedSymbol.svg';

export const QualifiedInvestorToken: React.FC<{
  sx?: SxProps;
}> = ({ sx }) => (
  <Box sx={sx} display='flex'>
    <Tooltip title='Investidor qualificado'>
      <img src={investorQualifiedSymbol} alt='img' />
    </Tooltip>
  </Box>
);
