import { Card, CardContent, Typography } from '@mui/material';
import React from 'react';
import { InvestAIBU } from 'src/__generated__/types';
import { useMGMDashboard } from '../hooks/useMGMDashboard';
import { CopyCouponButton } from './CopyCouponButton';
import { DisplayErrorOrChildren } from './DisplayErrorOrChildren';

export const MyCoupon: React.FC<{
  target: InvestAIBU;
}> = ({ target }) => {
  const { loading, error, code } = useMGMDashboard({
    source: InvestAIBU.CARTEIRA,
    target,
  });

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant='overline' sx={{ mb: 2 }} component='div'>
          Meu cupom
        </Typography>
        <DisplayErrorOrChildren error={error}>
          <CopyCouponButton code={code as string} loading={loading} />
        </DisplayErrorOrChildren>
      </CardContent>
    </Card>
  );
};
