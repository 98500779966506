import { MaybeNil } from '../../../../deals/components/DisplayDeal/logic/MaybeNil';

export const removeMaxBoundary = (
  maxValue: number,
  [min, max]: [number, number],
): [number, MaybeNil<number>] => {
  if (max >= maxValue) {
    return [min, null];
  }
  return [min, max];
};
