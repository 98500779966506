import { Edit } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';
import { YieldDescription } from 'src/__generated__/types';
import { useUpdateOfferYieldDescription } from '../hooks/useUpdateOfferYieldDescription';

export const UpdateFixedIncomeOfferYieldDescription: React.FC<{
  id: string;
  currentYieldDescription: YieldDescription;
}> = ({ id, currentYieldDescription }) => {
  const { handleEditFixedIncomeOfferYieldDescription } =
    useUpdateOfferYieldDescription({
      id,
      currentYieldDescription,
    });
  return (
    <IconButton
      onClick={handleEditFixedIncomeOfferYieldDescription}
      style={{ padding: 4 }}
    >
      <Edit style={{ width: 16, height: 16 }} />
    </IconButton>
  );
};
