import { Loading } from '@invest-ai/animatrix';
import { Edit } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { isNil } from 'ramda';
import { toast } from 'react-toastify';
import { useUpdateFixedIncomePosition } from '../../../hooks/useUpdateFixedIncomePosition';

export const UpdateRebuyUnitPrice: React.FC<{
  positionId: string;
  currentRebuyUnitPrice: number;
  onSuccess: () => Promise<void>;
}> = ({ positionId, currentRebuyUnitPrice, onSuccess }) => {
  const {
    loading: loadingUpdateFixedIncomePosition,
    updateFixedIncomePosition,
  } = useUpdateFixedIncomePosition();

  const handleEditRebuyUnitPriceClick = async () => {
    try {
      const newRebuyUnitPriceString = prompt(
        'R$',
        currentRebuyUnitPrice.toFixed(2).replace('.', ',') ?? '',
      );
      if (isNil(newRebuyUnitPriceString)) {
        return;
      }

      const newRebuyUnitPrice = Number.parseFloat(
        newRebuyUnitPriceString.replace(',', '.'),
      );

      if (Number.isNaN(newRebuyUnitPrice)) {
        alert('Insira uma quantidade válida em reais');
        return;
      }

      await updateFixedIncomePosition(positionId, {
        rebuyUnitPrice: newRebuyUnitPrice,
      });
      await onSuccess();
      toast.success('Posição editada com sucesso');
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message);
      }
    }
  };

  if (loadingUpdateFixedIncomePosition) {
    return <Loading size={18} sx={{ margin: 1 }} />;
  }

  return (
    <IconButton onClick={handleEditRebuyUnitPriceClick} style={{ padding: 4 }}>
      <Edit style={{ width: 16, height: 16 }} />
    </IconButton>
  );
};
