import { Button } from '@mui/material';
import { createSearchParams, useNavigate } from 'react-router-dom';

export interface INavigateToDealProps {
  fixedIncomePositionId: string;
  offerId?: string;
}

export const NavigateToDeal: React.FC<INavigateToDealProps> = ({
  fixedIncomePositionId,
  offerId,
}) => {
  const navigate = useNavigate();

  return (
    <Button
      variant='soft'
      color='primary'
      size='small'
      onClick={() => {
        navigate({
          pathname: `/negociacoes/${fixedIncomePositionId}`,
          search:
            offerId &&
            `?${createSearchParams({
              offer: offerId,
            })}`,
        });
      }}
    >
      Negociar
    </Button>
  );
};
