import { useQueryState } from '@common/hooks/useQueryState';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { createContainer } from 'unstated-next';
import { FixedIncomePositionFilter } from '../../../../../__generated__/types';
import { CURRENT_PRICE_MAX } from '../../FixedIncomePositionsFilterDrawer/CURRENT_PRICE_MAX';
import { MATURITY_MONTHS_FILTER_MAX } from '../../FixedIncomePositionsFilterDrawer/MATURITY_MONTHS_FILTER_MAX';
import { PAGINATION_PARAMS_INITIAL_STATE } from '../constants/PAGINATION_PARAMS_INITIAL_STATE';
import { IPaginationParams } from '../interfaces/IPaginationParams';
import { ISortParams } from '../interfaces/ISortParams';
import { mapIntervalToGraphQLInterval } from '../logic/mapIntervalToGraphQLInterval';
import { removeMaxBoundary } from '../logic/removeMaxBoundary';
import { IFilterPositionFormValues } from './interfaces/IFilterPositionFormValues';

export const COMPARATOR_POSITIONS_FILTER_KEY = 'comparator_positions_filter_v2';
export const LIST_POSITIONS_FILTER_KEY = 'list_positions_filter_v2';

const FILTER_KEY = 'filter_v2';
const PAGINATION_KEY = 'pagination';
const SORT_KEY = 'sort';

export interface UseFixedIncomePositionsListParamsOptions {
  localStorageFilterKey: string;
}
const DEFAULT_OPTIONS = {
  localStorageFilterKey: FILTER_KEY,
};

export const useFixedIncomePositionsListParams = ({
  localStorageFilterKey,
}: UseFixedIncomePositionsListParamsOptions = DEFAULT_OPTIONS) => {
  const { control, watch } = useForm<IFilterPositionFormValues>({
    defaultValues: JSON.parse(
      localStorage.getItem(localStorageFilterKey) ?? '{}',
    ),
  });

  const watcher = watch();

  useEffect(() => {
    localStorage.setItem(localStorageFilterKey, JSON.stringify(watcher));
  }, [watcher, localStorageFilterKey]);

  const [paginationParams, setPaginationParams] =
    useQueryState<IPaginationParams>(
      PAGINATION_KEY,
      PAGINATION_PARAMS_INITIAL_STATE,
    );

  const [sortParams, setSortParams] = useQueryState<ISortParams | undefined>(
    SORT_KEY,
  );

  const [search, setSearch] = useQueryState<string>('search', '');

  const filterParams: FixedIncomePositionFilter = {
    advisorIds: watcher.advisorIds,

    brokerAccountIds: watcher.brokerAccountIds,
    creationModes: watcher.creationModes,
    indexers: watcher.indexers,
    productKinds: watcher.productKinds,
    applicationDateInterval: mapIntervalToGraphQLInterval(
      watcher.applicationDateInterval,
    ),
    currentPriceInterval: mapIntervalToGraphQLInterval(
      removeMaxBoundary(CURRENT_PRICE_MAX, watcher.currentPriceInterval ?? []),
    ),

    maturityDateInterval: mapIntervalToGraphQLInterval(
      removeMaxBoundary(
        MATURITY_MONTHS_FILTER_MAX,
        watcher.maturityDateInterval ?? [],
      ),
    ),
  };

  const clearFilters = () => {};

  return {
    filterParams,
    search: search ?? '',
    paginationParams: paginationParams ?? PAGINATION_PARAMS_INITIAL_STATE,
    sortParams: sortParams,
    setSortParams,
    setPaginationParams,
    setSearch,
    control,
    clearFilters,
  };
};

export const FixedIncomePositionsListParamsContext = createContainer(
  useFixedIncomePositionsListParams,
);
