import {
  NavListProps,
  NavSectionData,
} from '@invest-ai/animatrix/src/layout/dashboard/components/NavSection';
import AccountBalanceWallet from '@mui/icons-material/AccountBalanceWallet';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { FEATURE_FLAGS } from './FeatureFlags';
import { UserRoles } from './UserRoles';
import { hasSomeRole } from './hasSomeRole';
import { routerPaths } from './routePaths';

const ICONS = {
  logout: <ExitToAppIcon />,
  positions: <AccountBalanceWallet />,
  buy: <ShoppingCartIcon />,
  users: <PersonIcon />,
  mgm: <LoyaltyIcon />,
  import: <ImportExportIcon />,
  account: <AccountCircle />,
  importOffers: <ImportExportIcon />,
  compareOffers: <LabelImportantIcon />,
};

export const PAGE_TITLES = {
  positions: 'Carteira',
  deals: 'Negociações',
  users: 'Usuários',
  import: 'Importar Posições',
  importTickerData: 'Importar Ticker Data',
  mgm: 'Indique',
  importOffers: 'Importar Ofertas',
  compareOffers: 'Comparador de ativos',
  account: 'Minha conta',
  logout: 'Sair',
};

export type SidebarConfig = Array<NavSectionData>;

export const getSidebarConfig = (roles: string | null): SidebarConfig =>
  [
    {
      subheader: 'Carteira',
      items: [
        {
          title: PAGE_TITLES.positions,
          path: routerPaths.app.positions,
          icon: ICONS.positions,
          exactPath: true,
        },
        {
          title: PAGE_TITLES.compareOffers,
          path: routerPaths.app.compareOffers,
          icon: ICONS.compareOffers,
          exactPath: false,
        },
        {
          title: PAGE_TITLES.import,
          path: routerPaths.app.importPositions,
          icon: ICONS.import,
          exactPath: true,
        },
        FEATURE_FLAGS.DISPLAY_MGM && {
          title: PAGE_TITLES.mgm,
          path: routerPaths.app.mgm,
          icon: ICONS.mgm,
          exactPath: true,
        },
      ].filter(Boolean) as Array<NavListProps>,
    },
    {
      subheader: 'Conta',
      items: [
        {
          title: PAGE_TITLES.logout,
          path: routerPaths.logout,
          icon: ICONS.logout,
        },
      ],
    },
    hasSomeRole([UserRoles.Admin, UserRoles.AlCaponeOffersManager]) && {
      subheader: 'Admin',
      items: [
        {
          title: PAGE_TITLES.importOffers,
          path: routerPaths.app.importOffers,
          icon: ICONS.importOffers,
          exactPath: true,
        },
        {
          title: PAGE_TITLES.importTickerData,
          path: routerPaths.app.importTickerData,
          icon: ICONS.importOffers,
          exactPath: true,
        },
      ],
    },
  ].filter(Boolean) as Array<NavSectionData>;
