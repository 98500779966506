import { Loading } from '@invest-ai/animatrix';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, Drawer, IconButton, Typography } from '@mui/material';
import React from 'react';
import { usePositionDrawer } from '../../context/usePositionDrawer';
import { useFixedIncomePositionById } from '../../hooks/useFixedIncomePositionById';
import { PositionForm } from '../PositionForm';
import { DEFAULT_VALUES } from '../PositionForm/constants/DEFAULT_VALUES';
import { IFormData } from '../PositionForm/interfaces/IFormData';

export interface IEditPositionDrawerProps {
  baseUrl: string;
}

const fixedIncomePositionToFormValues = (
  fixedIncomePosition: ReturnType<
    typeof useFixedIncomePositionById
  >['fixedIncomePosition'],
): IFormData => ({
  indexer:
    fixedIncomePosition?.yieldDescription.indexer ?? DEFAULT_VALUES.indexer,
  referenceUnitPrice:
    fixedIncomePosition?.currentPrice ?? DEFAULT_VALUES.referenceUnitPrice,
  appliedUnitPrice:
    fixedIncomePosition?.priceApplied ?? DEFAULT_VALUES.appliedUnitPrice,
  rebuyUnitPrice:
    fixedIncomePosition?.rebuyUnitPrice ?? DEFAULT_VALUES.rebuyUnitPrice,
  yieldRate:
    fixedIncomePosition?.yieldDescription.yieldRate ?? DEFAULT_VALUES.yieldRate,
  indexerPercentRate:
    fixedIncomePosition?.yieldDescription.indexerPercentRate ??
    DEFAULT_VALUES.indexerPercentRate,
  productKind: fixedIncomePosition?.productKind ?? DEFAULT_VALUES.productKind,
  brokerAccountId:
    fixedIncomePosition?.brokerAccount?.id ?? DEFAULT_VALUES.brokerAccountId,
  applicationDate:
    fixedIncomePosition?.applicationDate ?? DEFAULT_VALUES.applicationDate,
  maturityDate:
    fixedIncomePosition?.maturityDate ?? DEFAULT_VALUES.maturityDate,
  issuerId: fixedIncomePosition?.issuerId ?? DEFAULT_VALUES.issuerId,
  freeOfIR: fixedIncomePosition?.freeOfIR ?? DEFAULT_VALUES.freeOfIR,
  duration: fixedIncomePosition?.duration ?? DEFAULT_VALUES.duration,
  // TODO: Use enum
  investmentCategory: DEFAULT_VALUES.investmentCategory,
  advisorId:
    fixedIncomePosition?.brokerAccount.advisor.externalId ||
    DEFAULT_VALUES.advisorId,
});

export const EditPositionDrawer: React.FC<IEditPositionDrawerProps> = ({
  baseUrl,
}) => {
  const { isEditPositionModalOpen, closeDrawer, editPositionId } =
    usePositionDrawer(baseUrl);
  const { fixedIncomePosition, loading: loadingPosition } =
    useFixedIncomePositionById(editPositionId, {
      skip: !editPositionId,
    });

  const fixedIncomeFormValues =
    fixedIncomePositionToFormValues(fixedIncomePosition);

  return (
    <Drawer
      anchor={'right'}
      open={isEditPositionModalOpen}
      onClose={closeDrawer}
    >
      <Box
        sx={{
          width: 738,
          paddingRight: 4,
          paddingLeft: 4,
          paddingTop: 4,
          paddingBottom: 4,
        }}
      >
        <Box
          display='flex'
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Typography variant='h4'>Editar posição</Typography>
          <IconButton onClick={closeDrawer}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider sx={{ marginTop: 4, marginBottom: 4 }} />
        {loadingPosition ? (
          <Loading />
        ) : (
          <PositionForm
            onCancel={closeDrawer}
            onSuccess={closeDrawer}
            defaultValues={fixedIncomeFormValues}
            editPositionId={editPositionId}
          />
        )}
      </Box>
    </Drawer>
  );
};
