import { useSearchParams } from 'react-router-dom';
import { useMyAdvisoryOffice } from 'src/modules/advisory-office/hooks/useMyAdvisoryOffice';

export const useAdvisoryOfficeId = () => {
  const { advisoryOffice } = useMyAdvisoryOffice();
  const [searchParams] = useSearchParams();
  const officeId = searchParams.get('o');

  return {
    officeId: officeId ?? advisoryOffice?.id,
  };
};
