import { Indexers } from '@common/enum/Indexers';
import { YieldDescription } from '@common/interfaces/YieldDescription';
import { formatPercentage } from '@invest-ai/common';
import { isNil } from 'ramda';
import { MaybeNil } from '../../deals/components/DisplayDeal/logic/MaybeNil';

export const _formatYieldDescription = (
  indexer: Indexers,
  yieldRate: number,
  indexerPercentRate: number,
) => {
  if (indexer === Indexers.PREFIXADO) {
    return `${formatPercentage(yieldRate)} a.a`;
  }
  if (indexer === Indexers.CDI_PLUS) {
    return `CDI + ${formatPercentage(yieldRate)}`; // CDI + 4%
  }
  if (indexer === Indexers.CDI) {
    if (yieldRate !== 0) {
      // old data compatibility
      if (indexerPercentRate === 1) {
        return `CDI + ${formatPercentage(yieldRate)}`; // CDI + 4%
      }

      return `${formatPercentage(indexerPercentRate)} CDI + ${formatPercentage(
        yieldRate,
      )}`; // 102% CDI + 4%
    }
    return `${formatPercentage(indexerPercentRate)} CDI`; // 100% CDI
  }
  if (indexer === Indexers.IPCA) {
    return `IPCA + ${formatPercentage(yieldRate)}`;
  }

  return `${formatPercentage(
    indexerPercentRate,
  )} ${indexer} + ${formatPercentage(yieldRate)}`;
};

export const formatYieldDescription = (yieldDescription: YieldDescription) =>
  _formatYieldDescription(
    yieldDescription.indexer,
    yieldDescription.yieldRate,
    yieldDescription.indexerPercentRate,
  );

export const formatYieldDescriptionOrUndefined = (
  yieldDescription: MaybeNil<YieldDescription>,
) => {
  if (isNil(yieldDescription)) {
    return undefined;
  }
  return formatYieldDescription(yieldDescription);
};
