import { Grid } from '@mui/material';
import { DisplayOperation } from '../DisplayOperation';

export const OperationsDashboard: React.FC<{
  operationId: string;
}> = ({ operationId }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <DisplayOperation operationId={operationId} />
      </Grid>
    </Grid>
  );
};
