import { gql, useQuery } from '@apollo/client';
import {
  PositionsImporterHistoricQuery,
  PositionsImporterHistoricQueryVariables,
} from '../../../__generated__/types';

const POSITIONS_IMPORTER_HISTORIC_QUERY = gql`
  query PositionsImporterHistoric {
    getPositionsImporterHistoric {
      id
      date
      referenceDate
      parsingErrors {
        line
        columnName
        value
        reason
      }
      successCount
      parsingIgnored {
        line
        columnName
        value
        reason
      }
    }
  }
`;

export const useMyPositionsImporterHistoric = () => {
  const { data, loading, error, refetch } = useQuery<
    PositionsImporterHistoricQuery,
    PositionsImporterHistoricQueryVariables
  >(POSITIONS_IMPORTER_HISTORIC_QUERY);

  return {
    historic: data?.getPositionsImporterHistoric,
    loading,
    error,
    refetch,
  };
};
