import {
  Grid,
  LinearProgress,
  Paper,
  SxProps,
  Typography,
} from '@mui/material';
import { AxiosError } from 'axios';
import React from 'react';
import { toast } from 'react-toastify';
import { ImportOfferSteps } from '../hooks/ImportOfferSteps';
import { ImportSheetButton } from './ImportOffersSheetButton';

export interface UploadAndImportComponentProps {
  step: string;
  onSelectFile: (file: File) => Promise<void>;
  sheetName: string;
  loading: boolean;
  progress: number;
  sx?: SxProps;
}

export const UploadWithProgress: React.FC<UploadAndImportComponentProps> = ({
  step,
  onSelectFile,
  sheetName,
  loading,
  progress,
  sx,
}) => {
  const handleSelectOffersFile = async (file: File) => {
    try {
      await onSelectFile(file);
      toast.success(`Planilha ${sheetName} importada com sucesso!`);
    } catch (err) {
      if (err instanceof AxiosError) {
        const message = err.response?.data?.message ?? err.message;
        toast.error(`Erro. Entre em contato com o suporte. ${message}`, {
          autoClose: false,
        });
      } else if (err instanceof Error) {
        toast.error(`Erro. Entre em contato com o suporte. ${err.message}`, {
          autoClose: false,
        });
      } else {
        throw err;
      }
    }
  };

  return (
    <Paper elevation={5} sx={sx}>
      <Grid
        flexDirection='row'
        display='flex'
        justifyContent='space-between'
        alignItems={'center'}
        width={'100%'}
        paddingX={4}
        paddingY={3}
      >
        {step === ImportOfferSteps.IDLE && (
          <Typography>
            Selecione um arquivo com a <strong>{sheetName}</strong> para
            realizar a importação
          </Typography>
        )}
        {step === ImportOfferSteps.UPLOADING_FILE && (
          <Typography>Fazendo upload do arquivo...</Typography>
        )}

        {step === ImportOfferSteps.IMPORTING_OFFERS && (
          <Typography>Realizando processo de importação...</Typography>
        )}

        <ImportSheetButton
          onSelectFile={handleSelectOffersFile}
          loading={loading}
        />
      </Grid>
      {step === ImportOfferSteps.UPLOADING_FILE && (
        <LinearProgress value={(progress ?? 0) * 100} variant='determinate' />
      )}
      {step === ImportOfferSteps.IMPORTING_OFFERS && (
        <LinearProgress variant='indeterminate' />
      )}
    </Paper>
  );
};
