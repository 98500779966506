import logoImageSrc from './assets/carteira_logo.png';

export const product = {
  name: 'Carteira+',
  logo: {
    src: logoImageSrc,
    dimensions: {
      width: 130,
      height: 25,
    },
  },
};
