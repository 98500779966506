import { Stack, Typography } from '@mui/material';

export const RowProblem: React.FC<{
  rowProblem: {
    line?: number | null;
    columnName?: string | null;
    reason?: string | null;
    value?: string | null;
  };
}> = ({ rowProblem }) => {
  const t = `L${rowProblem.line}: [${rowProblem.columnName}]`.padEnd(50, '.');
  return (
    <Stack flex={1} direction={'row'}>
      <Typography
        color='error'
        variant='caption'
        mr={2}
        fontFamily={'Courier New'}
      >
        <strong>{t}</strong>
      </Typography>
      <Typography color='GrayText' variant='caption' fontFamily={'Courier New'}>
        {rowProblem.reason}
      </Typography>
    </Stack>
  );
};
