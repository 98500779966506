import {
  createSearchParams,
  useLocation,
  useMatch,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { IOfferDrawersNavigationHook } from './IOfferDrawersNavigationHook';

export const useDisplayDealDrawersNavigation: IOfferDrawersNavigationHook =
  () => {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const closeDrawer = () => {
      navigate(`/negociacoes/${params.positionId}${location.search}`);
    };

    const openAvailableOffersDrawer = () => {
      navigate(`/negociacoes/${params.positionId}/ofertas${location.search}`);
    };

    const openCreateOfferDrawer = () => {
      navigate(
        `/negociacoes/${params.positionId}/ofertas/criar${location.search}`,
      );
    };

    const openEditOfferDrawer = (offerId: string) => {
      navigate(
        `/negociacoes/${params.positionId}/ofertas/editar/${offerId}${location.search}`,
      );
    };

    const isAvailableOffersDrawerOpen = !!useMatch(
      '/negociacoes/:positionId/ofertas',
    );

    const isCreateOfferDrawerOpen = !!useMatch(
      '/negociacoes/:positionId/ofertas/criar',
    );
    const isEditOfferDrawerOpen = !!useMatch(
      '/negociacoes/:positionId/ofertas/editar/:offerId',
    );

    const editOfferId = params.editOfferId;

    const goBack = () => navigate(-1);

    const onSelectOfferId = (offerId: string) => {
      navigate({
        pathname: `/negociacoes/${params.positionId}`,
        search: `?${createSearchParams({
          offer: offerId,
        })}`,
      });
    };

    return {
      isAvailableOffersDrawerOpen,
      isCreateOfferDrawerOpen,
      isEditOfferDrawerOpen,
      editOfferId,
      closeDrawer,
      openAvailableOffersDrawer,
      openCreateOfferDrawer,
      openEditOfferDrawer,
      goBack,
      onSelectOfferId,
    };
  };
