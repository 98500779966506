import { INDEXER_OPTIONS } from '@common/constants/INDEXER_OPTIONS';
import { PRODUCT_OPTIONS } from '@common/constants/PRODUCT_OPTIONS';
import { FormSearchableSelect } from '@common/form/SearchableSelect/FormSearchableSelect';
import {
  Button,
  DisplayError,
  FormCheckbox,
  FormDatePicker,
  FormEasySelect,
  FormMoneyInput,
  FormPercentageInput,
  FormTextField,
} from '@invest-ai/animatrix';
import { Grid, Typography } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
  FixedIncomeProductKind,
  Indexers,
} from '../../../../__generated__/types';
import { floatTransformer } from '../../../common/logic/floatTransformer';
import { useAvailableAdvisorsAndBrokerAccountsForMyUser } from '../../hooks/useAvailableAdvisorsAndBrokerAccountsForMyUser';
import { useBrokerAccountsByAdvisor } from '../../hooks/useBrokerAccountsByAdvisor';
import { DeletePositionButton } from './DeletePositionButton';
import { DEFAULT_VALUES } from './constants/DEFAULT_VALUES';
import { IFormData } from './interfaces/IFormData';
import { useCreateFixedIncomePositionWithFeedback } from './useCreateFixedIncomePositionWithFeedback';
import { useUpdateFixedIncomePositionWithFeedback } from './useUpdateFixedIncomePositionWithFeedback';

export const PositionForm: React.FC<{
  onSuccess: (positionId: string) => void;
  onCancel: () => void;
  defaultValues?: IFormData;
  editPositionId?: string;
}> = ({
  onSuccess,
  onCancel,
  defaultValues = DEFAULT_VALUES,
  editPositionId,
}) => {
  const isEdit = !!editPositionId;
  const {
    updateFixedIncomePositionWithFeedback,
    loading: loadingUpdatePosition,
  } = useUpdateFixedIncomePositionWithFeedback(editPositionId ?? '', onSuccess);
  const {
    createFixedIncomePositionWithFeedback,
    loading: loadingCreatePosition,
  } = useCreateFixedIncomePositionWithFeedback(onSuccess);

  const {
    handleSubmit,
    control,
    formState: { isValid },
    watch,
    setValue,
  } = useForm<IFormData>({
    mode: 'onChange',
    defaultValues,
  });

  const advisorId = watch('advisorId');
  const indexer = watch('indexer');
  const productKind = watch('productKind');

  useEffect(() => {
    setValue('brokerAccountId', '');
  }, [advisorId]);

  const {
    loading: loadingAdvisorsList,
    error: errorAdvisorsList,
    advisorsList,
  } = useAvailableAdvisorsAndBrokerAccountsForMyUser();
  const { brokerAccounts } = useBrokerAccountsByAdvisor(advisorId);

  const onSubmit = async (data: IFormData) => {
    if (isEdit) {
      return await updateFixedIncomePositionWithFeedback(data);
    }
    return await createFixedIncomePositionWithFeedback(data);
  };

  const shouldDisplayYieldRate = [
    Indexers.IPCA,
    Indexers.PREFIXADO,
    Indexers.CDI_PLUS,
  ].includes(indexer);
  const shouldDisplayIndexerPercentRate = indexer === Indexers.CDI;

  const shouldDisplayDuration = [
    FixedIncomeProductKind.CRI,
    FixedIncomeProductKind.CRA,
    FixedIncomeProductKind.DEBENTURE,
    FixedIncomeProductKind.LTN,
    FixedIncomeProductKind.NTNB,
    FixedIncomeProductKind.NTNF,
  ].includes(productKind);
  const shouldDisplayFreeOfIR =
    productKind === FixedIncomeProductKind.DEBENTURE;

  const advisorOptions = useMemo(
    () =>
      advisorsList.map((advisor) => ({
        id: advisor.id,
        label: advisor.externalId,
      })),
    [advisorsList],
  );
  const brokerAccountOptions = useMemo(
    () =>
      brokerAccounts.map((brokerAccount) => ({
        id: brokerAccount.id,
        label: brokerAccount.accountNumber,
      })),
    [brokerAccounts],
  );
  if (errorAdvisorsList) {
    return <DisplayError error={errorAdvisorsList} />;
  }

  const isEmptyAdvisorsOptions =
    !loadingAdvisorsList && advisorOptions.length === 0;

  const isEmptyBrokerAccountOptions =
    advisorId && brokerAccountOptions.length === 0;

  const selectAdvisorsLabel = `Assessores (${
    loadingAdvisorsList ? 'carregando...' : advisorOptions.length
  })`;

  const selectBrokerAccountLabel = `Clientes (${
    loadingAdvisorsList ? 'carregando...' : brokerAccountOptions.length
  })`;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={4}>
        {!isEdit && (
          <>
            <Grid item xs={6} display='flex' flexDirection={'column'}>
              <FormSearchableSelect
                label={selectAdvisorsLabel}
                name='advisorId'
                placeholder='Escolha um assessor...'
                control={control}
                options={advisorOptions}
              />
              {isEmptyAdvisorsOptions && (
                <Typography
                  fontSize={12}
                  color='red'
                  marginLeft={1.5}
                  marginTop={1}
                >
                  Nenhum assessor cadastrado no sistema.
                </Typography>
              )}
            </Grid>
            <Grid item xs={6} display='flex' flexDirection='column'>
              <FormSearchableSelect
                label={selectBrokerAccountLabel}
                name='brokerAccountId'
                placeholder='Selecione um clente'
                control={control}
                options={brokerAccountOptions}
                rules={{
                  required: true,
                }}
              />

              {isEmptyBrokerAccountOptions && (
                <Typography
                  fontSize={12}
                  color='red'
                  marginLeft={1.5}
                  marginTop={1}
                >
                  Nenhum cliente cadastrado no sistema.
                </Typography>
              )}
            </Grid>
          </>
        )}

        <Grid item xs={12} display='flex' flexDirection='column'>
          <FormTextField name='issuerId' control={control} label='Emissor' />
        </Grid>

        <Grid item xs={6} display='flex' flexDirection='column'>
          <FormEasySelect
            name='productKind'
            control={control}
            label='Produto'
            options={PRODUCT_OPTIONS}
            rules={{
              required: true,
            }}
          />
        </Grid>

        <Grid item xs={6} display='flex' flexDirection='column'>
          <FormEasySelect
            name='indexer'
            control={control}
            label='Indexador'
            options={INDEXER_OPTIONS}
            rules={{
              required: true,
            }}
          />
        </Grid>

        <Grid item xs={6} display='flex' flexDirection='column'>
          <FormDatePicker
            name='applicationDate'
            control={control}
            label='Data da aplicação'
            rules={{
              required: true,
            }}
          />
        </Grid>

        <Grid item xs={6} display='flex' flexDirection='column'>
          <FormDatePicker
            name='maturityDate'
            control={control}
            label='Data de vencimento'
            rules={{
              required: true,
            }}
          />
        </Grid>

        <Grid item xs={6} display='flex' flexDirection='column'>
          {shouldDisplayYieldRate && (
            <FormPercentageInput
              control={control}
              name='yieldRate'
              label='Taxa de aplicação'
              precision={2}
              rules={{
                required: true,
              }}
            />
          )}

          {shouldDisplayIndexerPercentRate && (
            <FormPercentageInput
              control={control}
              name='indexerPercentRate'
              label='Taxa de aplicação (%)'
              precision={0}
              rules={{
                required: true,
              }}
            />
          )}
        </Grid>

        <Grid item xs={6} display='flex' flexDirection='column'>
          <FormMoneyInput
            control={control}
            name='appliedUnitPrice'
            label='Valor da aplicação'
            rules={{
              required: true,
            }}
          />
        </Grid>

        <Grid item xs={6} display='flex' flexDirection='column'>
          <FormMoneyInput
            control={control}
            name='referenceUnitPrice'
            label='Posição atual bruta'
            rules={{
              required: true,
            }}
          />
        </Grid>

        <Grid item xs={6} display='flex' flexDirection='column'>
          <FormMoneyInput
            control={control}
            name='rebuyUnitPrice'
            label='Valor bruto de venda'
            rules={{
              required: true,
            }}
          />
        </Grid>

        <Grid item xs={6}>
          {shouldDisplayDuration && (
            <FormTextField
              name='duration'
              control={control}
              label='Duration'
              type='number'
              inputProps={{
                step: '.01',
              }}
              transform={floatTransformer}
              rules={{
                required: true,
              }}
            />
          )}
        </Grid>

        <Grid item xs={6} alignItems='center' display='flex'>
          {shouldDisplayFreeOfIR && (
            <FormCheckbox
              name='freeOfIR'
              control={control}
              label='Livre de Imposto de Renda'
              rules={{
                required: true,
              }}
            />
          )}
        </Grid>

        <Grid item xs={12} display={'flex'} justifyContent={'flex-end'}>
          <Button
            variant='text'
            onClick={onCancel}
            color='inherit'
            sx={{ mr: 2 }}
          >
            Cancelar
          </Button>
          {isEdit && (
            <DeletePositionButton
              positionId={editPositionId}
              onSuccess={onSuccess}
            />
          )}

          <Button
            variant='contained'
            color='primary'
            type='submit'
            sx={{ minWidth: 200 }}
            loading={
              loadingCreatePosition ||
              loadingAdvisorsList ||
              loadingUpdatePosition
            }
            disabled={!isValid}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
