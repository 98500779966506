import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { authLink } from './authLink';
import { errorHandlerLink } from './errorHandlerLink';
import { httpLink } from './httpLink';

const link = ApolloLink.from([authLink, errorHandlerLink, httpLink]);

export const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  name: 'corleone',
  version: '0.0.1',
});
