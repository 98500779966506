import { Card, CardContent, Typography } from '@mui/material';
import React from 'react';
import { InvestAIBU } from 'src/__generated__/types';
import { useMGMDashboard } from '../hooks/useMGMDashboard';
import { CouponUsageGrid } from './CouponUsageGrid';
import { DisplayErrorOrChildren } from './DisplayErrorOrChildren';
import { DisplayLoadingOrChildren } from './DisplayLoadingOrChildren';

export const MGMBoletadorMyReferrals: React.FC = () => {
  const { loading, error, couponUsage } = useMGMDashboard({
    source: InvestAIBU.CARTEIRA,
    target: InvestAIBU.BOLETADOR,
  });

  return (
    <Card>
      <CardContent>
        <Typography variant='overline' sx={{ mb: 2 }} component='div'>
          Minhas indicações
        </Typography>

        <DisplayErrorOrChildren error={error}>
          <DisplayLoadingOrChildren loading={loading}>
            <CouponUsageGrid couponUsages={couponUsage} />
          </DisplayLoadingOrChildren>
        </DisplayErrorOrChildren>
      </CardContent>
    </Card>
  );
};
