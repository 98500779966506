import { omit } from 'ramda';
import { toast } from 'react-toastify';
import { useCreateFixedIncomePosition } from '../../hooks/useCreateFixedIncomePosition';
import { IFormData } from './interfaces/IFormData';
import { fixValues } from './logic/fixValues';

export const useCreateFixedIncomePositionWithFeedback = (
  onSuccess: (positionId: string) => void,
) => {
  const { createFixedIncomePosition, loading, error } =
    useCreateFixedIncomePosition();

  const createFixedIncomePositionWithFeedback = async (data: IFormData) => {
    const fixedValues = omit(['advisorId'], fixValues(data));
    try {
      const response = await createFixedIncomePosition(fixedValues);
      toast.success('Posição criada com sucesso');
      onSuccess(response.data?.createFixedIncomePosition?.id);
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message);
      }
    }
  };

  return {
    createFixedIncomePositionWithFeedback,
    loading,
    error,
  };
};
