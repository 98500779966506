import { Indexers, YieldDescription } from 'src/__generated__/types';
import { isCDUPlus } from 'src/modules/offers/components/OffersDrawer/components/AvailableOffersDrawer/isCDUPlus';

export const getCurrentEditableRate = (yieldDescription: YieldDescription) => {
  if (yieldDescription.indexer === Indexers.PREFIXADO) {
    return yieldDescription.yieldRate;
  }
  if (yieldDescription.indexer === Indexers.IPCA) {
    return yieldDescription.yieldRate;
  }
  if (yieldDescription.indexer === Indexers.CDI) {
    if (isCDUPlus(yieldDescription)) {
      return yieldDescription.yieldRate;
    }
    return yieldDescription.indexerPercentRate;
  }
  if (yieldDescription.indexer === Indexers.CDI_PLUS) {
    return yieldDescription.yieldRate;
  }
};
