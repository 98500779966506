import { YieldDescription } from '../../../../../../__generated__/types';
import { AvailableOfferIndexers } from '../../../../constants/AVAILABLE_OFFER_INDEXERS';
import { isCDUPlus } from './isCDUPlus';
import { mapIndexers } from './mapIndexers';

export const yieldDescriptionFilter =
  (filters: { indexer: AvailableOfferIndexers; minimumYieldRate: number }) =>
  (partialOffer: { yieldDescription: YieldDescription }) => {
    if (filters.indexer === AvailableOfferIndexers.ALL) {
      return true;
    }
    const { indexer, indexerPercentRate, yieldRate } =
      partialOffer.yieldDescription;
    const { minimumYieldRate, indexer: filterIndexer } = filters;

    const offerIndexer = isCDUPlus(partialOffer.yieldDescription)
      ? AvailableOfferIndexers.CDI_PLUS
      : mapIndexers(indexer);

    if (offerIndexer === filterIndexer) {
      if (offerIndexer === AvailableOfferIndexers.PREFIXADO) {
        return yieldRate >= minimumYieldRate;
      }

      if (offerIndexer === AvailableOfferIndexers.CDI) {
        return indexerPercentRate >= minimumYieldRate && yieldRate === 0;
      }

      if (offerIndexer === AvailableOfferIndexers.CDI_PLUS) {
        return indexerPercentRate === 1 && yieldRate >= minimumYieldRate;
      }

      if (offerIndexer === AvailableOfferIndexers.IPCA) {
        return yieldRate >= minimumYieldRate;
      }
    }

    return false;
  };
