import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, Drawer, IconButton, Typography } from '@mui/material';
import { IOfferDrawersNavigationHook } from '../../../../context/IOfferDrawersNavigationHook';
import { CreateOfferForm } from './CreateOfferForm';

export const CreateOfferDrawer: React.FC<{
  navigation: IOfferDrawersNavigationHook;
}> = ({ navigation }) => {
  const { isCreateOfferDrawerOpen, closeDrawer, goBack } = navigation();

  return (
    <Drawer
      anchor={'right'}
      open={isCreateOfferDrawerOpen}
      onClose={closeDrawer}
    >
      <Box
        sx={{
          width: 700,
          p: 4,
        }}
      >
        <Box
          display='flex'
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
          mb={4}
        >
          <Box display='flex' flexDirection='row' alignItems='center'>
            <IconButton onClick={() => goBack()}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant='h4'>Cadastro de oferta</Typography>
          </Box>
          <IconButton onClick={closeDrawer}>
            <CloseIcon />
          </IconButton>
        </Box>
        <CreateOfferForm />
      </Box>
    </Drawer>
  );
};
