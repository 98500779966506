import { gql, useMutation } from '@apollo/client';
import { CustomFixedIncomeOfferInput } from 'src/__generated__/types';

export const UPDATE_FIXED_INCOME_OFFER_MUTATION = gql`
  mutation updateFixedIncomeOffer(
    $id: String!
    $input: CustomFixedIncomeOfferInput!
  ) {
    updateFixedIncomeOffer(id: $id, input: $input) {
      id
    }
  }
`;

export const useUpdateFixedIncomeOffer = () => {
  const [_updateFixedIncomeOffer, { loading, error }] = useMutation(
    UPDATE_FIXED_INCOME_OFFER_MUTATION,
  );

  const updateFixedIncomeOffer = (
    id: string,
    input: Partial<CustomFixedIncomeOfferInput>,
  ) =>
    _updateFixedIncomeOffer({
      variables: {
        id,
        input,
      },
    });

  return {
    updateFixedIncomeOffer,
    loading,
    error,
  };
};
