import { Box, SxProps, Tooltip } from '@mui/material';
import freeOfIRSymbol from '../../../../assets/freeOfIRSymbol.svg';

export const FreeOfIRToken: React.FC<{ sx?: SxProps }> = ({ sx }) => (
  <Box sx={sx} display='flex'>
    <Tooltip title='Isento de imposto de renda'>
      <img src={freeOfIRSymbol} alt='img' />
    </Tooltip>
  </Box>
);
