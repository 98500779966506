import { formatReais } from '@invest-ai/common';
import { ChartOptions } from 'chart.js';
import { format } from 'date-fns';
import { formatReaisShortened } from '../logic/formatReaisShortened';

export const COMPARISON_CHART_OPTIONS: ChartOptions<'line'> = {
  responsive: true,

  scales: {
    y: {
      ticks: {
        callback: (label) => formatReaisShortened(label as number),
      },
    },
    x: {
      type: 'time',
      time: {
        displayFormats: {
          month: 'MM/yy',
        },
      },
    },
  },

  plugins: {
    legend: {
      position: 'top' as const,

      labels: {
        boxHeight: 10,
        boxWidth: 10,
        pointStyle: 'circle',
        useBorderRadius: true,
        borderRadius: 5,
      },
    },
    title: {
      display: true,
      text: 'Rentabilidade estimada',
    },
    tooltip: {
      callbacks: {
        label: (item) => `${item.dataset.label}: ${formatReais(item.parsed.y)}`,
        title: (items) =>
          items.map((item) => format(new Date(item.parsed.x), 'dd/MM/yyyy')),
      },
    },
  },
};
