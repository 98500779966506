import {
  type CheckboxProps,
  FormControlLabel,
  Checkbox as MUICheckbox,
} from '@mui/material';
import React from 'react';

interface _CheckboxProps {
  label: string;
  value: boolean;
  onChangeValue: (checked: boolean) => void;
}
export type ICheckboxProps = CheckboxProps & _CheckboxProps;

export const Checkbox: React.FC<ICheckboxProps> = ({
  label,
  value,
  onChangeValue,
}) => (
  <FormControlLabel
    label={label}
    control={
      <MUICheckbox
        checked={value}
        onChange={(_, v) => {
          onChangeValue?.(v);
        }}
      />
    }
  />
);
