import { ISearchableSelectOption } from '@common/form/SearchableSelect/interfaces/ISearchableSelectOption';

export const RATING_OPTIONS: Array<ISearchableSelectOption> = [
  { label: 'AAA+', id: 'AAA+' },
  { label: 'AAA', id: 'AAA' },
  { label: 'AA+', id: 'AA+' },
  { label: 'AA', id: 'AA' },
  { label: 'AA-', id: 'AA-' },
  { label: 'A+', id: 'A+' },
  { label: 'A', id: 'A' },
  { label: 'A-', id: 'A-' },
  { label: 'BBB+', id: 'BBB+' },
  { label: 'BBB', id: 'BBB' },
  { label: 'BB+', id: 'BB+' },
  { label: 'BB', id: 'BB' },
  { label: 'BB-', id: 'BB-' },
  { label: 'B+', id: 'B+' },
  { label: 'B', id: 'B' },
  { label: 'B-', id: 'B-' },
  { label: 'Sem Rating', id: 'sem' },
];
