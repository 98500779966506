import { DataGrid } from '@global/DataGrid';

import {
  DataGridPremiumProps,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid-premium';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export const EnhancedDataGrid: React.FC<
  { columnsToHide?: Record<string, boolean> | null } & DataGridPremiumProps<any>
> = ({
  columns,
  loading,
  columnsToHide,
  slotProps,
  initialState,
  ...props
}) => {
  const columnsWithHiddenRulesApplied = columns.map((column) => {
    if (columnsToHide?.[column.field] === false) {
      return {
        ...column,
        hideable: false,
        sortable: false,
      };
    }

    return column;
  });

  return (
    <DataGrid
      columns={columnsWithHiddenRulesApplied}
      loading={loading}
      disableRowSelectionOnClick
      autoHeight
      pagination
      disableColumnFilter
      pageSizeOptions={[5, 50, 100, 250, 500, 1000, 2500, 5000]}
      slots={{ toolbar: CustomToolbar, ...props.slots }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: {
            debounceMs: 500,
            ...slotProps?.toolbar?.quickFilterProps,
          },
          ...slotProps?.toolbar,
        },
        ...slotProps,
      }}
      initialState={{
        columns: {
          columnVisibilityModel: {
            ...columnsToHide,
            ...initialState?.columns?.columnVisibilityModel,
          },
          ...initialState?.columns,
        },
        ...initialState,
      }}
      {...props}
    />
  );
};
