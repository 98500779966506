import { EnhancedDataGrid } from '@common/components/EnhancedDataGrid/EnhancedDataGrid';
import { safeFormatDate } from '@common/date/formatters';
import { DisplayError } from '@invest-ai/animatrix';
import { GridColDef } from '@mui/x-data-grid-premium';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { OperationFragmentFragment } from '../../../../__generated__/types';
import { useShouldDisplayCustomerName } from '../../../advisor-authentication/hooks/useShouldDisplayCustomerName';
import { columnReaisFormatter } from '../../../common/formatters/columnReaisFormatter';
import { useOperations } from '../../hooks/useOperations';

export interface IOperationRow {
  id: string;
  operationDate: Date;
  customerName: string;
  fixedIncomePositionNickname: string;
  fixedIncomeOfferNickname: string;
}

const operationToOperationRow = (
  operation: OperationFragmentFragment,
): IOperationRow => {
  return {
    ...operation,
    customerName:
      operation.fixedIncomePosition.brokerAccount.customerName ?? '',
    fixedIncomePositionNickname: operation.fixedIncomePosition.nickname,
    fixedIncomeOfferNickname: operation.newFixedIncomeOffer.nickname,
  };
};

const columns: GridColDef<IOperationRow>[] = [
  {
    field: 'operationDate',
    headerName: 'Finalizado',
    valueFormatter: (v) => safeFormatDate(v.value),
  },
  {
    field: 'customerName',
    headerName: 'Cliente',
    flex: 1,
  },
  {
    field: 'fixedIncomePositionNickname',
    headerName: 'Ativo vendido',
    flex: 1,
  },
  {
    field: 'fixedIncomeOfferNickname',
    headerName: 'Ativo comprado',
    flex: 1,
  },
  {
    field: 'soldPositionLoss',
    headerName: 'Deságio D+0',
    valueFormatter: columnReaisFormatter,
  },
  {
    field: 'customerMarkUp',
    headerName: 'Ágio final',
    valueFormatter: columnReaisFormatter,
  },
  {
    field: 'roaAmount',
    headerName: 'ROA',
    valueFormatter: columnReaisFormatter,
  },
];

export const OperationsList: React.FC = () => {
  const { operations, loading: loadingOperations, error } = useOperations();
  const navigate = useNavigate();
  const rows = useMemo(() => {
    return operations?.map(operationToOperationRow) ?? [];
  }, [operations]);

  const {
    shouldDisplayCustomerName,
    loading: loadingShouldDisplayCustomerName,
  } = useShouldDisplayCustomerName();

  const columnsToHide = useMemo(() => {
    return {
      customerName: shouldDisplayCustomerName,
    };
  }, [shouldDisplayCustomerName]);

  if (error) {
    return <DisplayError error={error} />;
  }

  return (
    <EnhancedDataGrid
      onRowClick={(row) => {
        navigate(`/operacoes/${row.id}`);
      }}
      loading={loadingOperations || loadingShouldDisplayCustomerName}
      rows={rows}
      columns={columns}
      disableRowSelectionOnClick
      autoHeight
      columnsToHide={columnsToHide}
    />
  );
};
