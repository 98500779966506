export const getCustomerIdentificationLabel = (
  customerName: string | undefined | null,
  accountNumber: string | undefined,
  tickerId: string | undefined | null,
): string | undefined => {
  if (customerName && accountNumber) {
    return `Nome: ${customerName} | Conta: ${accountNumber} | Ticker: ${tickerId}`;
  }
  if (accountNumber) {
    return `Conta: ${accountNumber} | Ticker: ${tickerId}`;
  }
  return '-';
};
