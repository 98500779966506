import { YieldDescription } from '@common/interfaces/YieldDescription';
import { formatYieldDescription } from '@common/logic/formatYieldDescription';
import { formatReais } from '@invest-ai/common';
import { MaybeNil } from '../../DisplayDeal/logic/MaybeNil';
import { formatDateOrUndefined } from '../../DisplayDeal/logic/formatDateOrUndefined';

export const getWhatsAppText = ({
  name,
  position,
  offer,
  financials,
  customPitch,
}: {
  name: string;
  customPitch?: MaybeNil<{
    text: string;
  }>;
  position: {
    nickName: string;
    appliedTotalPrice: number;
    maturityDate: Date;
    yieldDescription: YieldDescription;
    rebuyTotalNetPrice: number;
  };
  offer: {
    nickName: string;
    appliedTotalPrice: number;
    maturityDate: Date;
    yieldDescription: YieldDescription;
  };
  financials: {
    markup: number;
  };
}) => {
  const defaultPitch = `Essa mudança poderá proporcionar a carteira um *ganho de ${formatReais(
    financials.markup,
  )}* em relação a operação atual de sua carteira.
Esse valor pode oscilar para mais ou menos dependendo do cenário da curva de juros futura e inflação. Utilizamos premissas de cálculos baseado nas taxas de mercado atualizadas para a montagem dessa estratégia.

Caso tenha interesse nessa operação ou queira saber um pouco mais, basta me avisar!
Muito obrigado e estamos à disposição.
`;

  return `Olá ${name}, tudo bem?
Estamos fazendo um *trabalho especializado na carteira de Renda Fixa*, buscando detectar oportunidades de melhorar ainda mais a performance da sua carteira.
Identificamos uma *operação interessante* que gostaria de compartilhar com você.
A operação consiste na venda antecipada de um título de renda fixa com baixa perspectiva de rentabilização em sua carteira atual, e respectivamente a compra de um ativo com potencial mais elevado de ganho.

_Resumo da Estratégia de Venda_:
Venda do ativo: ${position.nickName}
Valor Inicial Aplicado: ${formatReais(position.appliedTotalPrice)}
Vencimento em: ${formatDateOrUndefined(position.maturityDate)}
Taxa Contratada: \`\`\`${formatYieldDescription(position.yieldDescription)}\`\`\`
Valor de Resgate: ${formatReais(position.rebuyTotalNetPrice)}

_Resumo da Estratégia de Compra_:
Compra do ativo: ${offer.nickName}
Valor Inicial aplicado: ${formatReais(offer.appliedTotalPrice)}
Novo vencimento em: ${formatDateOrUndefined(offer.maturityDate)}
Nova taxa contratada: \`\`\`${formatYieldDescription(offer.yieldDescription)}\`\`\`
Ganho proporcionado: \`\`\`${formatReais(financials.markup)}\`\`\`

_Explicações Adicionais_:
${customPitch?.text ?? defaultPitch}`;
};
