import { Controller } from 'react-hook-form';
import { DatePicker, type IDatePickerProps } from '../components/DatePicker';
import { type BuildFormProps } from '../types/BuildFormProps';

export const FormDatePicker: React.FC<BuildFormProps<IDatePickerProps>> = ({
  name,
  control,
  rules,
  ...props
}) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({ field, fieldState }) => (
      <DatePicker
        value={field.value}
        onChange={field.onChange}
        error={!!fieldState.error}
        helperText={fieldState?.error?.message}
        {...props}
      />
    )}
  />
);
