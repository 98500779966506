import { urlJoin } from 'url-join-ts';

const ROOT_PATH = '/';

export const routerPaths = {
  root: ROOT_PATH,
  logout: '/logout',
  landingPage: {
    main: 'https://invest-ai.com.br',
  },
  terms: urlJoin('/', 'termos'),
  privacyPolicy: urlJoin('/', 'politica-privacidade'),
  auth: {
    root: '/',
    signIn: 'login',
    signUp: 'signup',
    signUpOld: 'cadastro',
    signInOld: 'entrar',
    signUpOld2: 'signup',
    signInOld2: 'login',
  },
  app: {
    positions: ROOT_PATH,
    operations: urlJoin(ROOT_PATH, 'operacoes'),
    operationById: urlJoin(ROOT_PATH, 'operacoes', ':operationId'),
    deals: urlJoin(ROOT_PATH, 'negociacoes'),
    dealsById: urlJoin(ROOT_PATH, 'negociacoes', ':positionId'),
    users: urlJoin(ROOT_PATH, 'usuarios'),
    login: urlJoin(ROOT_PATH, 'login'),
    signup: urlJoin(ROOT_PATH, 'signup'),
    mgm: urlJoin(ROOT_PATH, 'mgm'),
    accountCreated: urlJoin(ROOT_PATH, 'conta-criada'),
    forgotPassword: urlJoin(ROOT_PATH, 'recuperar-senha'),
    resetPassword: urlJoin(ROOT_PATH, 'nova-senha'),
    brokerAccounts: urlJoin(ROOT_PATH, 'clientes'),
    importPositions: urlJoin(ROOT_PATH, 'importar-posicoes'),
    account: urlJoin(ROOT_PATH, 'minha-conta'),
    importOffers: urlJoin(ROOT_PATH, 'importar-ofertas'),
    importTickerData: urlJoin(ROOT_PATH, 'importar-ticker-data'),
    compareOffers: urlJoin(ROOT_PATH, 'comparador'),
    compareOffersWithId: urlJoin(ROOT_PATH, 'comparador/:offerId'),
  },
};
