import {
  type DatePickerProps,
  DatePicker as MUIDatePicker,
  type PickersActionBarAction,
} from '@mui/x-date-pickers';
import React from 'react';

export type IDatePickerProps = Omit<DatePickerProps<Date>, 'renderInput'> & {
  error?: boolean;
  loading?: boolean;
  helperText?: string;
  fullWidth?: boolean;
  actions?: PickersActionBarAction[];
};

export const DatePicker: React.FC<IDatePickerProps> = ({
  // TODO
  error,
  // TODO
  loading,
  actions,
  fullWidth,
  ...props
}) => {
  const fullWidthSx = fullWidth ? { width: '100%' } : null;
  return (
    <MUIDatePicker
      format='dd/MM/yyyy'
      slotProps={{
        ...props.slotProps,
        actionBar: {
          actions,
        },
      }}
      closeOnSelect
      sx={{
        ...fullWidthSx,
        ...props.sx,
      }}
      {...props}
    />
  );
};
