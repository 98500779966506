import { Stack, Typography } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import React, { useMemo } from 'react';
import { ISearchableSelectOption } from './interfaces/ISearchableSelectOption';
import { ISearchableSelectProps } from './interfaces/ISearchableSelectProps';

export function SearchableSelect({
  value,
  onChange,
  options,
  multiple,
  limit = 20,
  ...props
}: ISearchableSelectProps<any>): React.ReactNode {
  const renderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: ISearchableSelectOption,
  ) => (
    <Stack component='li' direction='row' key={option.id} {...props}>
      <Typography variant='body2'>{option.label}</Typography>
      {option.description && (
        <Typography variant='caption' color='textSecondary' sx={{ mt: 1 }}>
          {option.description}
        </Typography>
      )}
    </Stack>
  );

  const defaultValue = multiple ? [] : null;
  const placeholder = multiple
    ? value?.length
      ? undefined
      : props.placeholder
    : value
      ? undefined
      : props.placeholder;

  const _onChange = (
    newValueOrValues: ISearchableSelectOption[] | ISearchableSelectOption,
  ) => {
    if (Array.isArray(newValueOrValues)) {
      onChange(newValueOrValues.map((option) => option.id));
      return;
    }

    onChange(newValueOrValues?.id ?? null);
  };

  const _value = multiple
    ? options.filter((option) => {
        if (!Array.isArray(value)) {
          return false;
        }
        return value.indexOf(option.id) !== -1;
      })
    : options.find((option) => option.id === value);

  return (
    <Autocomplete<any, any, any, any>
      isOptionEqualToValue={(option, value) => option.id === value.id}
      options={options}
      value={_value || defaultValue}
      autoHighlight
      onChange={(_: React.SyntheticEvent, newValue) => {
        if (!newValue) {
          onChange(null);
        }
        _onChange(newValue);
      }}
      getOptionLabel={(option: any) => {
        return option.label ?? '';
      }}
      filterOptions={createFilterOptions({
        limit,
      })}
      ChipProps={{
        variant: 'soft',
        color: 'primary',
        size: 'small',
      }}
      renderOption={renderOption}
      renderInput={({ inputProps, ...params }) => {
        return (
          <TextField
            {...params}
            label={props.label}
            placeholder={placeholder}
            inputProps={{
              ...inputProps,
              autoComplete: 'off', // disable autocomplete and autofill
            }}
          />
        );
      }}
      multiple={multiple}
      {...props}
    />
  );
}
