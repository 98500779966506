import { Page } from '@invest-ai/animatrix';
import { Container } from '@mui/material';
import { useParams } from 'react-router-dom';
import { OperationsDashboard } from '../components/OperationsDashboard';

export const OperationPage: React.FC = () => {
  const params = useParams<{ operationId: string }>();
  const operationId = params.operationId;
  if (!operationId) {
    return null;
  }
  return (
    <Page title='InvestAI Carteira+ | Operação'>
      <Container maxWidth={'xl'}>
        <OperationsDashboard operationId={operationId} />
      </Container>
    </Page>
  );
};
