import { AvailableOfferIndexers } from '../constants/AVAILABLE_OFFER_INDEXERS';
export interface IIndexerYieldFilterConfig {
  min: number;
  max: number;
  step: number;
  marks: Array<{
    value: number;
    label: string;
  }>;
}
export const PREFIXADO_YIELD_RATE_MARKS = [
  {
    value: 0.1,
    label: '10,00%',
  },
  {
    value: 0.125,
    label: '12,50%',
  },
  {
    value: 0.15,
    label: '15,00%',
  },
  {
    value: 0.2,
    label: '20,00%',
  },
];

const CDI_YIELD_RATE_MARKS = [
  {
    value: 1,
    label: '100,00%',
  },
  {
    value: 1.25,
    label: '125,00%',
  },
  {
    value: 1.5,
    label: '150,00%',
  },
  {
    value: 2,
    label: '200,00%',
  },
];
export const CDI_PLUS_YIELD_RATE_MARKS = [
  {
    value: 0.025,
    label: '2,50%',
  },
  {
    value: 0.05,
    label: '5,00%',
  },
  {
    value: 0.1,
    label: '10,00%',
  },
  {
    value: 0.15,
    label: '15,00%',
  },
  {
    value: 0.2,
    label: '20,00%',
  },
];
const PREFIXADO_YIELD_FILTER_CONFIG: IIndexerYieldFilterConfig = {
  min: 0.05,
  max: 0.2,
  step: 0.001,
  marks: PREFIXADO_YIELD_RATE_MARKS,
};
const CDI_PLUS_YIELD_FILTER_CONFIG: IIndexerYieldFilterConfig = {
  min: 0.001,
  max: 0.2,
  step: 0.001,
  marks: CDI_PLUS_YIELD_RATE_MARKS,
};
const CDI_YIELD_FILTER_CONFIG: IIndexerYieldFilterConfig = {
  min: 0.9,
  max: 2,
  step: 0.01,
  marks: CDI_YIELD_RATE_MARKS,
};
export const yieldFilterConfigMap: Record<
  AvailableOfferIndexers,
  IIndexerYieldFilterConfig
> = {
  [AvailableOfferIndexers.ALL]: PREFIXADO_YIELD_FILTER_CONFIG,
  [AvailableOfferIndexers.PREFIXADO]: PREFIXADO_YIELD_FILTER_CONFIG,
  [AvailableOfferIndexers.CDI]: CDI_YIELD_FILTER_CONFIG,
  [AvailableOfferIndexers.CDI_PLUS]: CDI_PLUS_YIELD_FILTER_CONFIG,
  [AvailableOfferIndexers.IPCA]: PREFIXADO_YIELD_FILTER_CONFIG,
};

export const getYieldFilterConfig = (
  indexer: AvailableOfferIndexers,
): IIndexerYieldFilterConfig => {
  return (
    yieldFilterConfigMap[indexer] ||
    yieldFilterConfigMap[AvailableOfferIndexers.PREFIXADO]
  );
};
