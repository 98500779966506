import { INDEXER_OPTIONS } from '@common/constants/INDEXER_OPTIONS';
import { PRODUCT_OPTIONS } from '@common/constants/PRODUCT_OPTIONS';
import { formatMonths } from '@common/logic/formatMonths';
import { DisplayError } from '@invest-ai/animatrix';
import { FormEasySelect } from '@invest-ai/animatrix';
import { formatReais } from '@invest-ai/common';
import CloseIcon from '@mui/icons-material/Close';
import { Grid, IconButton, Slider, Stack, Typography } from '@mui/material';
import { Container } from '@mui/system';
import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useUserBrokerAccounts } from '../../../broker-accounts/hooks/useBrokerAccounts';
import { FormSearchableSelect } from '../../../common/form/SearchableSelect/FormSearchableSelect';
import { useUserAdvisors } from '../../../custom-position/hooks/useAvailableAdvisorsAndBrokerAccountsForMyUser';
import { MATURITY_DAYS_MARKS } from '../../../offers/constants/MATURITY_DAYS_MARKS';
import { FixedIncomePositionsListParamsContext } from '../FixedIncomePositionsList/hooks/useFixedIncomePositionsListParams';
import { CREATION_MODE_OPTIONS } from './CREATION_MODE_OPTIONS';
import { CURRENT_PRICE_MAX } from './CURRENT_PRICE_MAX';
import { CURRENT_PRICE_RANGE_MARKS } from './CURRENT_PRICE_RANGE_MARKS';
import { FixedIncomePositionsFilterDrawerContext } from './FixedIncomePositionsFilterDrawerContext';
import { MATURITY_MONTHS_FILTER_MAX } from './MATURITY_MONTHS_FILTER_MAX';

export const FixedIncomePositionsFilter: React.FC = () => {
  const { toggleVisibility } =
    FixedIncomePositionsFilterDrawerContext.useContainer();

  const { control } = FixedIncomePositionsListParamsContext.useContainer();

  const {
    advisors,
    loading: loadingUserAdvisors,
    error: advisorsError,
  } = useUserAdvisors();
  const {
    brokerAccounts,
    loading: loadingUserBrokerAccounts,
    error: brokerAccountsError,
  } = useUserBrokerAccounts();

  const advisorOptions = useMemo(
    () =>
      advisors?.map((advisor) => ({
        id: advisor.id,
        label: advisor.externalId as string,
      })) ?? [],
    [advisors],
  );

  const brokerAccountOptions = useMemo(
    () =>
      brokerAccounts?.map((brokerAccount) => ({
        id: brokerAccount.id,
        label: brokerAccount.accountNumber as string,
      })) ?? [],
    [brokerAccounts],
  );

  const shouldDisplayBrokerAccounts =
    !brokerAccountsError &&
    brokerAccountOptions.length < 5000 &&
    !loadingUserAdvisors;
  const shouldDisplayAdvisors = !advisorsError && advisorOptions.length < 1000;

  const brokerAccountsLabel = `Clientes (${
    loadingUserBrokerAccounts ? 'carregando...' : brokerAccountOptions.length
  })`;
  const advisorsLabel = `Assessores (${
    loadingUserAdvisors ? 'carregando...' : advisorOptions.length
  })`;

  return (
    <Container sx={{ width: 700, p: 4 }}>
      <Stack flexDirection='row' justifyContent='space-between' mb={4}>
        <Typography variant='h4'>Filtro de posições</Typography>
        <IconButton
          aria-label='Encerrar modal de filtro'
          onClick={toggleVisibility}
          size='large'
        >
          <CloseIcon />
        </IconButton>
      </Stack>
      <Grid item xs={12} display='flex' flexDirection='column'>
        <Typography gutterBottom variant='overline'>
          Posição atual (R$)
        </Typography>
        <Controller
          name={'currentPriceInterval'}
          control={control}
          defaultValue={[0, 2 * 1000 * 1000]}
          render={({ field, fieldState }) => (
            <Slider
              value={field.value}
              min={0}
              max={CURRENT_PRICE_MAX}
              step={1000}
              valueLabelFormat={formatReais}
              marks={CURRENT_PRICE_RANGE_MARKS}
              onChange={(e, v) => {
                field.onChange(v as [number, number]);
              }}
              valueLabelDisplay='auto'
            />
          )}
        />

        <Typography gutterBottom sx={{ marginTop: 2 }} variant='overline'>
          Vencimento (meses)
        </Typography>
        <Controller
          name={'maturityDateInterval'}
          control={control}
          defaultValue={[1, 300]}
          render={({ field, fieldState }) => (
            <Slider
              value={field.value}
              min={1}
              max={MATURITY_MONTHS_FILTER_MAX}
              step={1}
              valueLabelFormat={formatMonths}
              marks={MATURITY_DAYS_MARKS}
              onChange={(e, v) => {
                field.onChange(v as [number, number]);
              }}
              valueLabelDisplay='auto'
            />
          )}
        />

        <FormEasySelect
          label='Produtos'
          name='productKinds'
          control={control}
          sx={{ mt: 2 }}
          options={PRODUCT_OPTIONS}
          defaultValue={[]}
          multiple
        />

        <FormEasySelect
          label='Indexadores'
          name='indexers'
          control={control}
          sx={{ mt: 2 }}
          options={INDEXER_OPTIONS}
          defaultValue={[]}
          multiple
        />

        <FormEasySelect
          label='Origem'
          name='creationModes'
          control={control}
          sx={{ mt: 2 }}
          options={CREATION_MODE_OPTIONS}
          defaultValue={[]}
          multiple
        />

        {shouldDisplayAdvisors && (
          <FormSearchableSelect
            label={advisorsLabel}
            name='advisorIds'
            placeholder='Escolha um ou mais assessores...'
            control={control}
            sx={{ marginTop: 2 }}
            options={advisorOptions}
            multiple
          />
        )}
        {advisorsError && (
          <DisplayError error={advisorsError} sx={{ marginTop: 2 }} />
        )}

        {shouldDisplayBrokerAccounts && (
          <FormSearchableSelect
            label={brokerAccountsLabel}
            name='brokerAccountIds'
            placeholder='Escolha um ou mais clientes...'
            control={control}
            sx={{ marginTop: 2 }}
            options={brokerAccountOptions}
            multiple
          />
        )}

        {brokerAccountsError && (
          <DisplayError error={brokerAccountsError} sx={{ marginTop: 2 }} />
        )}
      </Grid>
    </Container>
  );
};
