import { Grid } from '@mui/material';
import React from 'react';
import { useMGMDashboard } from '../hooks/useMGMDashboard';
import { MGMCard } from './MGMCard';

export const CouponUsageGrid: React.FC<{
  couponUsages: ReturnType<typeof useMGMDashboard>['couponUsage'];
}> = ({ couponUsages }) => {
  return (
    <Grid container spacing={1}>
      {couponUsages?.map((usage) => {
        return (
          <Grid item sm={4} key={usage.id}>
            <MGMCard
              date={usage.createdAt}
              name={usage.user?.name ?? ''}
              value={50}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};
