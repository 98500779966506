import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export type Maybe<T> = T | undefined;

type Creator<T> = (x: T) => T;

type ValueOrCreator<T> = T | Creator<T>;

export function useQueryState<T>(
  key: string,
  defaultValue?: T,
): [T, (state: ValueOrCreator<T>) => void] {
  const [searchParams, setSearchParams] = useSearchParams();
  const state = useMemo(() => {
    const value = searchParams.get(key);
    if (value) {
      return JSON.parse(value);
    }
    return defaultValue;
  }, [searchParams.get(key)]);

  const setState = (stateBuilder: ValueOrCreator<T>) => {
    const newState =
      typeof stateBuilder === 'function'
        ? (stateBuilder as Creator<T>)(state)
        : stateBuilder;

    if (newState) {
      setSearchParams((searchParams) => {
        searchParams.set(key, JSON.stringify(newState));
        return searchParams;
      });
    } else {
      setSearchParams((searchParams) => {
        searchParams.delete(key);
        return searchParams;
      });
    }
  };

  return [state, setState];
}
