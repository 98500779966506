import { isNil } from 'ramda';
import { toast } from 'react-toastify';
import { YieldDescription } from 'src/__generated__/types';
import { getCurrentEditableRate } from '../logic/getCurrentEditableRate';
import { getNewYieldDescription } from '../logic/getNewYieldDescription';
import { safeParseFloatWithCommaOrDot } from '../logic/safeParseFloatWithCommaOrDot';
import { useOfferCustomYieldDescription } from './useOfferCustomYieldDescription';

export const useUpdateOfferYieldDescription = ({
  id,
  currentYieldDescription,
}: {
  id: string;
  currentYieldDescription: YieldDescription;
}) => {
  const { setOfferYieldDescription } = useOfferCustomYieldDescription();

  const handleEditFixedIncomeOfferYieldDescription = async () => {
    const currentRate = getCurrentEditableRate(currentYieldDescription);
    if (isNil(currentRate)) {
      return;
    }

    const newRate = window.prompt('Qual a nova taxa?', `${currentRate * 100}`);
    if (isNil(newRate)) {
      return;
    }
    try {
      const newRateNumber = safeParseFloatWithCommaOrDot(newRate);

      const newRateNumberDecimal = newRateNumber / 100;

      const newYieldDescription = getNewYieldDescription(
        currentYieldDescription,
        newRateNumberDecimal,
      );
      if (isNil(newYieldDescription)) {
        return;
      }
      setOfferYieldDescription(id, newYieldDescription);
      toast.success('Taxa editada com sucesso!');
    } catch (err) {
      toast.error('Número inválido.');
    }
  };

  return {
    handleEditFixedIncomeOfferYieldDescription,
  };
};
