import { formatReais } from '@invest-ai/common';
import { GridValueFormatterParams } from '@mui/x-data-grid-premium';

export const formatReaisWithoutPrefix = (v: number) => {
  return formatReais(v).replace('R$ ', '');
};

export const columnReaisWithoutPrefixForamtter = (
  params: GridValueFormatterParams<number>,
) => (params.value ? formatReaisWithoutPrefix(params.value) : '-');

export const columnReaisFormatter = (
  params: GridValueFormatterParams<number>,
) => (params.value ? formatReais(params.value) : '-');
