import { getMonthDifference } from '@common/logic/getMonthDifference';

export const getStretchingString = (
  positionMaturityDate: Date,
  offerMaturityDate: Date,
) => {
  const monthsDifference =
    offerMaturityDate && positionMaturityDate
      ? getMonthDifference(positionMaturityDate, offerMaturityDate)
      : '-';

  return `${monthsDifference} meses`;
};
