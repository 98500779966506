import signInImageSrc from './assets/signin_bg.png';
import signUpImageSrc from './assets/signup_bg.png';
import { SignInTitle } from './components/SignIn/SignInTitle';

export const layout = {
  signUp: {
    image: {
      src: signUpImageSrc,
      dimensions: { width: 352, height: 300.44 },
    },
    title: 'Plataforma 100% segura',
    subtitle:
      'A plataforma conta com tecnologia e criptografia de ponta. O Carteira+ também segue todos os protocolos e requisitos da LGPD e é auditada frequentemente. ',
  },
  signIn: {
    image: {
      src: signInImageSrc,
      dimensions: {
        width: 382,
        height: 326,
      },
    },
    title: <SignInTitle />,
    subtitle:
      'Analise a carteira completa dos seus clientes e faça trocas de ativos automaticamente em poucos cliques.',
  },
};
