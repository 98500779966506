import { useState } from 'react';
import { createContainer } from 'unstated-next';

export const FixedIncomePositionsFilterDrawerContext = createContainer(() => {
  const [visible, setVisible] = useState<boolean>(false);

  const toggleVisibility = () => setVisible((s) => !s);

  return {
    visible,
    setVisible,
    toggleVisibility,
  };
});
