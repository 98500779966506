import { formatReais } from '@invest-ai/common';
import { Card, Stack, Typography } from '@mui/material';
import React from 'react';
import { formatDateOrUndefined } from 'src/modules/deals/components/DisplayDeal/logic/formatDateOrUndefined';

export interface IMGMCardProps {
  date: Date;
  name: string;
  value: number;
}

export const MGMCard: React.FC<IMGMCardProps> = ({ date, name, value }) => {
  return (
    <Card sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
      <Stack direction='row' justifyContent='space-between' sx={{ mb: 1 }}>
        <Typography>{name}</Typography>
        <Typography variant='caption' color='GrayText'>
          {formatDateOrUndefined(date)}
        </Typography>
      </Stack>

      <Typography
        variant='subtitle1'
        color='primary'
        sx={{ alignSelf: 'flex-end' }}
      >
        +{formatReais(value)}
      </Typography>
    </Card>
  );
};
