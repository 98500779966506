import { parseServerDate, safeParseServerDate } from '@common/date/parsers';
import { formatReaisOrUndefined } from '@common/logic/formatReaisOrUndefined';
import { getMonthDifference } from '@common/logic/getMonthDifference';
import { renderMonthsLabel } from '@common/logic/renderMonthsLabel';
import { DisplayError } from '@invest-ai/animatrix';
import { Color } from '@invest-ai/animatrix';
import { formatPercentage, formatReais } from '@invest-ai/common';
import { Box, Grid } from '@mui/material';
import { isNil } from 'ramda';
import { useCalculateDealFromRoute } from '../../../hooks/useCalculateDealFromRoute';
import { VisualizationContext } from '../../../pages/DealsPage/VisualizationContext';
import { InformationBlock } from '../../InformationBlock';
import { MaybeNil } from '../logic/MaybeNil';
import { getMinorDuration } from '../logic/getMinorDuration';

export const DealFinancials: React.FC<{
  offerId: MaybeNil<string>;
  positionId: string;
}> = ({ offerId, positionId }) => {
  const { hideSensitiveInformation } = VisualizationContext.useContainer();
  const {
    error,
    loading,
    fixedIncomePosition,
    fixedIncomeOffer,
    dealFinancials,
  } = useCalculateDealFromRoute({
    offerId,
    positionId,
  });

  if (error) {
    return <DisplayError error={error} />;
  }

  // TODO: Get from Deal
  const monthsDifference =
    fixedIncomeOffer &&
    fixedIncomePosition &&
    getMonthDifference(
      safeParseServerDate(fixedIncomePosition.maturityDate),
      safeParseServerDate(fixedIncomeOffer.maturityDate),
    );

  const monthsDifferenceLabel = monthsDifference
    ? `${monthsDifference ?? 0} ${renderMonthsLabel(monthsDifference ?? 0)}`
    : undefined;

  // TODO: Get from Deal
  const minorDuration = getMinorDuration(
    fixedIncomePosition?.ticker?.duration ?? 0,
    fixedIncomeOffer?.duration ?? 0,
  );

  const roaLabel =
    dealFinancials &&
    fixedIncomeOffer &&
    `+${formatReais(dealFinancials.advisorROAReais)} (${formatPercentage(
      fixedIncomeOffer.roa,
    )})`;

  const inactiveOffer = !fixedIncomeOffer?.isActive;

  const isMarkupPositive = (dealFinancials?.customerMarkUp ?? 0) > 0;

  const shouldDisplayCustomerMarkup = !isNil(
    fixedIncomePosition?.ticker?.duration,
  );

  const markupLabel = shouldDisplayCustomerMarkup
    ? `${isMarkupPositive ? '+' : ''}${
        formatReaisOrUndefined(dealFinancials?.customerMarkUp) ?? ''
      }`
    : 'Indisponível';

  return (
    <Box style={{ backgroundColor: '#F4F6F8' }}>
      <Grid paddingX={3} paddingY={2} item display='flex' flexDirection='row'>
        <Grid item xs={3}>
          <InformationBlock
            label='Alongamento'
            value={monthsDifferenceLabel}
            inactive={inactiveOffer}
            loading={loading}
          />
        </Grid>
        <Grid xs={1} item />
        <Grid item xs={3}>
          {!hideSensitiveInformation && (
            <InformationBlock
              label='ROA'
              value={roaLabel}
              valueStyle={{
                color: Color.POSITIVE,
              }}
              inactive={inactiveOffer}
              loading={loading}
            />
          )}
        </Grid>

        <Grid xs={1} item />
        <Grid item xs={4}>
          <InformationBlock
            valueStyle={{
              color: shouldDisplayCustomerMarkup
                ? isMarkupPositive
                  ? Color.POSITIVE
                  : Color.NEGATIVE
                : Color.BLACK,
            }}
            label={
              shouldDisplayCustomerMarkup
                ? `Ágio líquido projetado em ${minorDuration} anos`
                : 'Ágio líquido projetado'
            }
            value={markupLabel}
            inactive={inactiveOffer}
            loading={loading}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
