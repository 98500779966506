import { Color } from '@invest-ai/animatrix';
import { Button } from '@invest-ai/animatrix';
import {
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-premium';

export const CustomToolbar: React.FC<{
  onClickCreateOffer: () => void;
}> = ({ onClickCreateOffer }) => {
  return (
    <GridToolbarContainer style={{ display: 'flex' }}>
      <GridToolbarQuickFilter
        debounceMs={500}
        placeholder='Procurar ofertas...'
        sx={{ flex: 1, mr: 2 }}
      />
      <Button
        variant='contained'
        color='primary'
        style={{ color: Color.WHITE }}
        onClick={onClickCreateOffer}
      >
        Criar oferta
      </Button>
    </GridToolbarContainer>
  );
};
