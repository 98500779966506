import { gql, useMutation } from '@apollo/client';
import { FIXED_INCOME_POSITIONS_TO_DEAL_QUERY } from '../../fixed-income-positions/components/FixedIncomePositionsList/hooks/useFixedIncomePositionsToDeal';

export const DELETE_FIXED_INCOME_POSITION_MUTATION = gql`
  mutation deleteFixedIncomePosition($id: String!) {
    deleteFixedIncomePosition(id: $id)
  }
`;

export const useDeletePosition = () => {
  const [_deleteFixedIncomePosition, { loading, error }] = useMutation(
    DELETE_FIXED_INCOME_POSITION_MUTATION,
  );

  const deleteFixedIncomePosition = (id: string) =>
    _deleteFixedIncomePosition({
      variables: {
        id,
      },
      refetchQueries: [FIXED_INCOME_POSITIONS_TO_DEAL_QUERY],
    });

  return {
    deleteFixedIncomePosition,
    loading,
    error,
  };
};
