import { gql, useQuery } from '@apollo/client';
import { UserAdvisorsQuery } from '../../../__generated__/types';
export const AVAILABLE_ADVISOR_AND_BROKER_ACCOUNTS_FOR_MY_USER = gql`
  query AvailableAdvisorAndBrokerAccountsForMyUser {
    availableAdvisorsForMyUser {
      id
      externalId
      name
      brokerAccounts {
        id
        accountNumber
        customerName
      }
    }
  }
`;

export const useAvailableAdvisorsAndBrokerAccountsForMyUser = () => {
  const { data, loading, error } = useQuery(
    AVAILABLE_ADVISOR_AND_BROKER_ACCOUNTS_FOR_MY_USER,
  );

  return {
    advisorsList: (data?.availableAdvisorsForMyUser as any[]) ?? [],
    loading,
    error,
  };
};

export const USER_ADVISORS_QUERY = gql`
  query UserAdvisors {
    availableAdvisorsForMyUser {
      id
      externalId
      name
    }
  }
`;

export const useUserAdvisors = () => {
  const { data, loading, error, refetch } =
    useQuery<UserAdvisorsQuery>(USER_ADVISORS_QUERY);

  return {
    advisors: data?.availableAdvisorsForMyUser ?? [],
    loading,
    error,
    refetch,
  };
};
