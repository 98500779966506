import { AlCaponeService } from '@common/services/AlCaponeService';
import { useState } from 'react';
import { clearPositionFilters } from '../components/FixedIncomePositionsList/hooks/clearPositionFilters';

export const useImportPositions = () => {
  const [loading, setLoading] = useState(false);

  const alCaponeService = new AlCaponeService();

  const importPositions = async (form: FormData) => {
    setLoading(true);
    try {
      await alCaponeService.importPositions(form);
      clearPositionFilters();
    } finally {
      setLoading(false);
    }
  };

  return {
    importPositions,
    loading,
  };
};
