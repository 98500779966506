import { Loading } from '@invest-ai/animatrix';
import React, { PropsWithChildren } from 'react';

export const DisplayLoadingOrChildren: React.FC<
  PropsWithChildren<{
    loading: boolean;
  }>
> = ({ loading, children }) => {
  if (!loading) {
    return children;
  }
  return <Loading />;
};
