import { SxProps } from '@mui/material';
import React from 'react';
import { TickerDataSource } from 'src/__generated__/types';
import { UploadWithProgress } from '../../components/UploadAndImportComponent';
import { useImportTickerData } from './useImportTickerData';
import { useUploadFileToGCP } from './useUploadFileToGCP';

export const TickerDataSheetUpload: React.FC<{
  sheetName: string;
  type: TickerDataSource;
  sx?: SxProps;
}> = ({ sheetName, type, sx }) => {
  const { importTickerData } = useImportTickerData();

  const { loading, progress, step, uploadFileToGCP } = useUploadFileToGCP({
    onUploadFinished: (filename: string) => importTickerData(filename, type),
  });

  return (
    <UploadWithProgress
      loading={loading}
      onSelectFile={uploadFileToGCP}
      progress={progress ?? 0}
      sheetName={sheetName}
      step={step}
      sx={sx}
    />
  );
};
