import { CalculateOfferFinancialsInput } from '../../../../__generated__/types';

export const INPUT_EMPTY = {
  positionId: '',
};

export const getAvailableFixedIncomeOffersVariables = (
  positionIdToCompare = '',
) => {
  let financials: CalculateOfferFinancialsInput = INPUT_EMPTY;
  if (positionIdToCompare) {
    financials = {
      positionId: positionIdToCompare,
    };
  }

  return {
    input: financials,
    withFinancials: financials !== INPUT_EMPTY,
  };
};
