import { pick } from 'ramda';
import { Indexers, YieldDescription } from 'src/__generated__/types';
import { isCDUPlus } from 'src/modules/offers/components/OffersDrawer/components/AvailableOffersDrawer/isCDUPlus';

export const getNewYieldDescription = (
  yieldDescription: YieldDescription,
  editableRate: number,
): YieldDescription | undefined => {
  const original = pick(
    ['indexer', 'yieldRate', 'indexerPercentRate'],
    yieldDescription,
  );

  if (yieldDescription.indexer === Indexers.PREFIXADO) {
    return {
      ...original,
      yieldRate: editableRate,
    };
  }
  if (yieldDescription.indexer === Indexers.IPCA) {
    return {
      ...original,
      yieldRate: editableRate,
    };
  }
  if (yieldDescription.indexer === Indexers.CDI) {
    if (isCDUPlus(yieldDescription)) {
      return {
        ...original,
        yieldRate: editableRate,
      };
    }
    return {
      ...original,
      indexerPercentRate: editableRate,
    };
  }
  if (yieldDescription.indexer === Indexers.CDI_PLUS) {
    return {
      ...original,
      yieldRate: editableRate,
    };
  }
};
