import { PRODUCT_TYPE_OPTIONS } from '@common/constants/PRODUCT_TYPES';
import { Indexers } from '@common/enum/Indexers';
import { ProductTypes } from '@common/enum/ProductTypes';
import { formatMonths } from '@common/logic/formatMonths';
import { formatYieldDescription } from '@common/logic/formatYieldDescription';
import { formatPercentage } from '@invest-ai/common';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Slider,
  Stack,
  Typography,
} from '@mui/material';

import { SearchableSelect } from '@common/form/SearchableSelect/SearchableSelect';
import { EasySelect } from '@invest-ai/animatrix';
import { MATURITY_MONTHS_FILTER_MAX } from 'src/modules/fixed-income-positions/components/FixedIncomePositionsFilterDrawer/MATURITY_MONTHS_FILTER_MAX';
import { AvailableOfferIndexers } from '../../../../constants/AVAILABLE_OFFER_INDEXERS';
import { AvailableOffersFiltersContext } from '../../../../context/AvailableOffersFiltersContext';
import { getYieldFilterConfig } from '../../../../logic/getYieldFilterConfig';
import { RATING_OPTIONS } from './RATING_OPTIONS';

export const FixedIncomeOfferFilters: React.FC = () => {
  const {
    productType,
    minimumROA,
    maturityDateRange,
    minimumYieldRate,
    indexer,
    maturityDateRangeLowerBoundary,
    onlyInvestorQualified,
    onlyFreeOfIR,
    setMinimumROA,
    setMaturityDateRange,
    setMinimumYieldRate,
    changeIndexer,
    setProductType,
    ratings,
    setRatings,
    setOnlyInvestorQualified,
    setOnlyFreeOfIR,
  } = AvailableOffersFiltersContext.useContainer();
  const yieldFilterConfig = getYieldFilterConfig(indexer);

  const transformedYieldDescription = {
    indexer: (indexer === AvailableOfferIndexers.CDI_PLUS
      ? Indexers.CDI
      : indexer) as Indexers,
    yieldRate: indexer === AvailableOfferIndexers.CDI ? 0 : minimumYieldRate,
    indexerPercentRate:
      indexer === AvailableOfferIndexers.CDI ? minimumYieldRate : 1,
  };
  return (
    <Stack>
      <Typography variant='overline'>Tipo de produto</Typography>
      <RadioGroup
        value={productType}
        row={true}
        onChange={(_, type) => {
          setProductType(type as ProductTypes);
        }}
      >
        <Stack>
          <FormControlLabel
            value={ProductTypes.ALL}
            control={<Radio />}
            label={PRODUCT_TYPE_OPTIONS[ProductTypes.ALL].label}
          />
          <FormControlLabel
            value={ProductTypes.CP}
            control={<Radio />}
            label={PRODUCT_TYPE_OPTIONS[ProductTypes.CP].label}
          />

          <FormControlLabel
            value={ProductTypes.EB}
            control={<Radio />}
            label={PRODUCT_TYPE_OPTIONS[ProductTypes.EB].label}
          />

          <FormControlLabel
            value={ProductTypes.TP}
            control={<Radio />}
            label={PRODUCT_TYPE_OPTIONS[ProductTypes.TP].label}
          />
        </Stack>
      </RadioGroup>

      <Typography variant='overline' sx={{ mt: 2 }}>
        Indexador
      </Typography>
      <RadioGroup
        row={true}
        value={indexer}
        onChange={(_, indexer) => {
          changeIndexer(indexer as AvailableOfferIndexers);
        }}
      >
        <FormControlLabel
          value={AvailableOfferIndexers.ALL}
          control={<Radio />}
          label='Todos'
        />
        <FormControlLabel
          value={AvailableOfferIndexers.CDI_PLUS}
          control={<Radio />}
          label='CDI+'
        />
        <FormControlLabel
          value={AvailableOfferIndexers.CDI}
          control={<Radio />}
          label='CDI%'
        />

        <FormControlLabel
          value={AvailableOfferIndexers.IPCA}
          control={<Radio />}
          label='IPCA+'
        />
        <FormControlLabel
          value={AvailableOfferIndexers.PREFIXADO}
          control={<Radio />}
          label='Prefixado'
        />
      </RadioGroup>

      <Typography variant='overline' sx={{ mt: 2 }}>
        Vencimento ({formatMonths(maturityDateRange[0])} até{' '}
        {formatMonths(maturityDateRange[1])})
      </Typography>

      <Box sx={{ px: 1 }}>
        <Slider
          value={maturityDateRange}
          min={maturityDateRangeLowerBoundary}
          max={MATURITY_MONTHS_FILTER_MAX}
          step={1}
          onChange={(e, v) => {
            setMaturityDateRange(v as [number, number]);
          }}
          valueLabelDisplay='auto'
        />
      </Box>

      <Typography variant='overline' sx={{ mt: 2 }}>
        ROA mínimo ({formatPercentage(minimumROA)})
      </Typography>

      <Box sx={{ px: 1 }}>
        <Slider
          min={0}
          max={0.05}
          step={0.001}
          valueLabelFormat={(v) => formatPercentage(v)}
          value={minimumROA}
          // marks={ROA_MARKS}
          onChange={(e, v) => {
            setMinimumROA(v as number);
          }}
          valueLabelDisplay='auto'
        />
      </Box>

      <Typography variant='overline' sx={{ mt: 2 }}>
        Taxa mínima{' '}
        {indexer !== AvailableOfferIndexers.ALL &&
          `(${formatYieldDescription(transformedYieldDescription)})`}
      </Typography>

      {indexer === AvailableOfferIndexers.ALL ? (
        <Typography variant='caption' color='GrayText'>
          Selecione um indexador específico para aplicar o filtro de taxa mínima
        </Typography>
      ) : (
        <Box sx={{ px: 1 }}>
          <Slider
            value={minimumYieldRate}
            min={yieldFilterConfig.min}
            max={yieldFilterConfig.max}
            step={yieldFilterConfig.step}
            valueLabelFormat={(v) => formatPercentage(v)}
            // marks={yieldFilterConfig.marks}
            onChange={(e, v) => {
              setMinimumYieldRate(v as number);
            }}
            valueLabelDisplay='auto'
          />
        </Box>
      )}

      <Typography variant='overline' sx={{ mt: 2 }}>
        Outros filtros
      </Typography>

      <FormControlLabel
        value={onlyInvestorQualified}
        control={<Checkbox />}
        label='Investidor qualificado'
        onChange={(_, value) => setOnlyInvestorQualified(value)}
      />
      <FormControlLabel
        value={onlyFreeOfIR}
        control={<Checkbox />}
        label='Isento de impostos'
        onChange={(_, value) => setOnlyFreeOfIR(value)}
      />

      <Typography variant='overline' sx={{ mt: 2 }}>
        Rating
      </Typography>
      <SearchableSelect
        label=''
        options={RATING_OPTIONS}
        onChange={(v) => setRatings(v as string[])}
        value={ratings}
        sx={{ mt: 1 }}
        limit={RATING_OPTIONS.length}
        multiple
      />
    </Stack>
  );
};
