import { PRODUCT_TYPE_OPTIONS } from '@common/constants/PRODUCT_TYPES';
import { DisplayError } from '@invest-ai/animatrix';
import { Grid, Paper } from '@mui/material';
import { differenceInMonths } from 'date-fns';
import { compose, toLower, trim } from 'ramda';
import { useMemo } from 'react';
import { FixedIncomeOfferFragmentFragment } from '../../../../../../__generated__/types';
import { AvailableOffersFiltersContext } from '../../../../context/AvailableOffersFiltersContext';
import { useAvailableFixedIncomeOffers } from '../../../../hooks/useAvailableFixedIncomeOffers/useAvailableFixedIncomeOffers';
import { FixedIncomeOfferFilters } from './FixedIncomeOfferFilters';
import { FixedIncomeOffersList } from './FixedIncomeOffersList';
import { getFirstWord } from './getFirstWord';
import { yieldDescriptionFilter } from './yieldDescriptionFilter';

export interface IDealSubstituteFixedIncomeOfferProps {
  positionIdToCompare?: string;
  onSelectFixedIncomeOffer: (fixedIncomeOfferId: string) => void;
  onClickCreateOffer: () => void;
  onClickEditOffer: (offerId: string) => void;
}

export const FixedIncomeOffers: React.FC<
  IDealSubstituteFixedIncomeOfferProps
> = ({
  positionIdToCompare,
  onSelectFixedIncomeOffer,
  onClickCreateOffer,
  onClickEditOffer,
}) => {
  const { fixedIncomeOffers, loading, error } = useAvailableFixedIncomeOffers({
    positionIdToCompare,
  });

  const {
    productType,
    minimumROA,
    maturityDateRange,
    minimumYieldRate,
    indexer: filterIndexer,
    onlyInvestorQualified,
    onlyFreeOfIR,
    ratings,
  } = AvailableOffersFiltersContext.useContainer();

  const [minimumMaturityMonths, maximumMaturityMonths] = maturityDateRange;

  const filteredFixedIncomeOffers: Array<FixedIncomeOfferFragmentFragment> =
    useMemo(
      () =>
        fixedIncomeOffers
          ?.filter((offer) => {
            // TODO: Extract filter
            const products = PRODUCT_TYPE_OPTIONS[productType].productKind;
            return (
              products.includes(offer.productKind) || products.length === 0
            );
          })
          .filter((offer) => {
            // TODO: Extract filter
            const offerMaturityMonths = differenceInMonths(
              offer.maturityDate,
              new Date(),
            );

            const insideMaturityDateRange =
              offerMaturityMonths >= minimumMaturityMonths &&
              offerMaturityMonths <= maximumMaturityMonths;
            return insideMaturityDateRange;
          })
          .filter((offer) => {
            if (!ratings || !offer.rating || !ratings.length) {
              return true;
            }

            const normalizedRatings = ratings.map(
              compose(getFirstWord, trim, toLower),
            );
            return normalizedRatings.includes(
              trim(toLower(getFirstWord(offer.rating))),
            );
          })
          .filter(
            yieldDescriptionFilter({
              indexer: filterIndexer,
              minimumYieldRate,
            }),
          )
          .filter((offer) => {
            // TODO: Extract filter
            return offer.roa >= minimumROA;
          })
          .filter((offer) => {
            // TODO: Extract filter
            if (onlyInvestorQualified) {
              return offer.target === 'QUALIFIED';
            }

            return true;
          })
          .filter((offer) => {
            // TODO: Extract filter
            if (onlyFreeOfIR) {
              return offer.freeOfIR;
            }

            return true;
          }) ?? [],
      [
        fixedIncomeOffers,
        minimumROA,
        filterIndexer,
        minimumYieldRate,
        minimumMaturityMonths,
        maximumMaturityMonths,
        productType,
        onlyInvestorQualified,
        onlyFreeOfIR,
        ratings,
      ],
    );

  if (error) {
    return <DisplayError error={error} />;
  }

  if (!loading && fixedIncomeOffers?.length === 0) {
    return <div>Não há ofertas.</div>;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
        <Paper elevation={5} sx={{ padding: 2, marginBottom: 2 }}>
          <FixedIncomeOfferFilters />
        </Paper>
      </Grid>
      <Grid item xs={12} md={9}>
        <Paper elevation={5}>
          <FixedIncomeOffersList
            fixedIncomeOffers={filteredFixedIncomeOffers}
            onSelectFixedIncomeOffer={onSelectFixedIncomeOffer}
            onClickCreateOffer={onClickCreateOffer}
            onClickEditOffer={onClickEditOffer}
            loading={loading}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};
