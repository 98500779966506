import { gql, useMutation } from '@apollo/client';

export const DELETE_FIXED_INCOME_OFFER_MUTATION = gql`
  mutation deleteFixedIncomeOffer($id: String!) {
    deleteFixedIncomeOffer(id: $id)
  }
`;

export const useDeleteOffer = () => {
  const [_deleteFixedIncomeOffer, { loading, error }] = useMutation(
    DELETE_FIXED_INCOME_OFFER_MUTATION,
  );

  const deleteFixedIncomeOffer = (id: string) =>
    _deleteFixedIncomeOffer({
      variables: {
        id,
      },
    });

  return {
    deleteFixedIncomeOffer,
    loading,
    error,
  };
};
