import { gql, useQuery } from '@apollo/client';
import { parseServerDate } from '@common/date/parsers';
import { Evolver, evolve } from 'ramda';
import { useMemo } from 'react';
import {
  MyOperationsQuery,
  OperationFragmentFragment,
} from '../../../__generated__/types';

export const OPERATION_FRAGMENT = gql`
  fragment OperationFragment on Operation {
    id
    operationDate
    soldPositionLoss
    customerMarkUp
    roaAmount
    newFixedIncomeOffer {
      nickname
    }
    fixedIncomePosition {
      nickname
      brokerAccount {
        customerName
      }
    }
  }
`;

const MY_OPERATIONS_QUERY = gql`
  query MyOperations {
    getMyOperations {
      ...OperationFragment
    }
  }

  ${OPERATION_FRAGMENT}
`;
export const useOperations = () => {
  const { data, loading, error } = useQuery<MyOperationsQuery>(
    MY_OPERATIONS_QUERY,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const operationEvolver = evolve<Evolver<OperationFragmentFragment>>({
    operationDate: parseServerDate,
  });

  const evolvedOperations = useMemo(
    () => (data ? data.getMyOperations.map(operationEvolver) : []),
    [data],
  );

  return {
    operations: evolvedOperations,
    loading,
    error,
  };
};
