import { safeFormatDate } from '@common/date/formatters';
import { formatReaisOrUndefined } from '@common/logic/formatReaisOrUndefined';
import { formatYieldDescription } from '@common/logic/formatYieldDescription';
import { getMonthDifference } from '@common/logic/getMonthDifference';
import { Label } from '@global/Label';
import { DisplayError } from '@invest-ai/animatrix';
import { Loading } from '@invest-ai/animatrix';
import { formatPercentage, formatReais } from '@invest-ai/common';
import { Grid, Paper, Typography } from '@mui/material';
import { getAmortizationTextOrUndefined } from '../../../deals/components/DisplayDeal/logic/getAmortizationText';
import { getMinorDurationOrUndefined } from '../../../deals/components/DisplayDeal/logic/getMinorDuration';
import { InformationBlock } from '../../../deals/components/InformationBlock';
import { useOperationById } from '../../hooks/useOperationById';

export const DisplayOperation: React.FC<{
  operationId: string;
}> = ({ operationId }) => {
  const { operation, loading, error } = useOperationById(operationId);

  const interestPeriodText = getAmortizationTextOrUndefined(
    operation?.newFixedIncomeOffer?.interestPeriod,
    operation?.newFixedIncomeOffer?.firstInterestPaymentDate,
  );

  const amortizationText = getAmortizationTextOrUndefined(
    operation?.newFixedIncomeOffer?.amortizationPeriod,
    operation?.newFixedIncomeOffer?.firstAmortizationPaymentDate,
  );

  const monthsDifference =
    operation?.newFixedIncomeOffer && operation?.fixedIncomePosition
      ? getMonthDifference(
          operation.fixedIncomePosition.maturityDate,
          operation.newFixedIncomeOffer.maturityDate,
        )
      : undefined;

  const minorDuration = getMinorDurationOrUndefined(
    operation?.fixedIncomePosition?.duration,
    operation?.newFixedIncomeOffer?.duration,
  );

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <DisplayError error={error} />;
  }
  if (!operation) {
    return <div>Operação não encontrada</div>;
  }

  return (
    <Grid container spacing={2}>
      <Grid xs={12} item>
        <Typography variant='h4'>Detalhe da operação</Typography>
        <Typography variant='caption' color='GrayText'>
          Finalizado em {safeFormatDate(operation.operationDate)}
        </Typography>
      </Grid>
      <Grid xs={12} item>
        <Typography
          variant='caption'
          color='GrayText'
          style={{ marginRight: 8 }}
        >
          Status:
        </Typography>
        <Label color='success'>Concluído</Label>
      </Grid>
      <Grid xs={6} item>
        <Paper style={{ padding: 24 }} elevation={5}>
          <Typography variant='overline' color='GrayText'>
            Ativo vendido
          </Typography>

          <Typography
            variant='subtitle1'
            style={{ marginBottom: 24, marginTop: 12 }}
          >
            {operation.fixedIncomePosition.nickname}
          </Typography>

          <Grid container rowSpacing={3}>
            <Grid item xs={12}>
              <InformationBlock
                loading={loading}
                label='Cliente'
                value={
                  operation.fixedIncomePosition.brokerAccount.accountNumber
                }
              />
            </Grid>
            <Grid item xs={4}>
              <InformationBlock
                loading={loading}
                label='Data de aplicação'
                value={safeFormatDate(
                  operation.fixedIncomePosition.applicationDate,
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <InformationBlock
                loading={loading}
                label='Data de vencimento'
                value={safeFormatDate(
                  operation.fixedIncomePosition?.maturityDate,
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <InformationBlock
                loading={loading}
                label='Taxa'
                value={formatYieldDescription(
                  operation.fixedIncomePosition.yieldDescription,
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <InformationBlock
                loading={loading}
                label='Valor aplicado'
                value={formatReais(operation.fixedIncomePosition?.priceApplied)}
              />
            </Grid>
            <Grid item xs={4}>
              <InformationBlock
                loading={loading}
                label='Valor bruto na curva'
                value={formatReais(operation.positionSnapshot.currentPrice)}
              />
            </Grid>
            <Grid item xs={4}>
              <InformationBlock
                loading={loading}
                label='Valor líquido na curva'
                value={formatReais(operation.positionSnapshot.currentNetPrice)}
              />
            </Grid>
            <Grid item xs={4}>
              <InformationBlock
                loading={loading}
                label='Duration'
                value={String(
                  operation.fixedIncomePosition?.duration?.toFixed(2) ?? '-',
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <InformationBlock
                loading={loading}
                label='Deságio'
                value={formatReais(operation.soldPositionLoss)}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant='subtitle1'>Recompra</Typography>
            </Grid>

            <Grid item xs={4}>
              <InformationBlock
                loading={loading}
                label='Taxa de recompra'
                value={formatYieldDescription(operation.rebuyYieldDescription)}
              />
            </Grid>
            <Grid item xs={4}>
              <InformationBlock
                loading={loading}
                label='Valor bruto de recompra'
                value={formatReais(operation.positionSnapshot?.rebuyUnitPrice)}
              />
            </Grid>
            <Grid item xs={4}>
              <InformationBlock
                loading={loading}
                label='Valor líquido de recompra'
                value={formatReais(operation.rebuyNetPrice)}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid xs={6} item>
        <Paper style={{ padding: 24 }} elevation={5}>
          <Typography variant='overline' color='GrayText'>
            Ativo comprado
          </Typography>
          <Typography
            variant='subtitle1'
            style={{ marginBottom: 24, marginTop: 12 }}
          >
            {operation.newFixedIncomeOffer.nickname}
          </Typography>

          <Grid container rowSpacing={3}>
            <Grid item xs={4}>
              <InformationBlock
                loading={loading}
                label='Data da compra'
                value={safeFormatDate(
                  operation.newFixedIncomeOfferUnitPriceReferenceDate, // ver se é necessário
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <InformationBlock
                loading={loading}
                label='Data de vencimento'
                value={safeFormatDate(
                  operation.newFixedIncomeOffer.maturityDate,
                )}
              />
            </Grid>

            <Grid item xs={4}>
              <InformationBlock
                loading={loading}
                label='Juros'
                value={interestPeriodText}
              />
            </Grid>

            <Grid item xs={4}>
              <InformationBlock
                loading={loading}
                label='Amortização'
                value={amortizationText}
              />
            </Grid>

            <Grid item xs={4}>
              <InformationBlock
                loading={loading}
                label='Rating'
                value={operation.newFixedIncomeOffer?.rating || ''}
              />
            </Grid>
            <Grid item xs={4}>
              <InformationBlock
                loading={loading}
                label='Duration'
                value={String(
                  operation.newFixedIncomeOffer?.duration?.toFixed(2) ?? '-',
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <InformationBlock
                loading={loading}
                label='Valor a aplicar'
                value={formatReais(
                  operation.positionSnapshot
                    ?.substituteFixedIncomeOfferPriceApplied,
                )}
              />
            </Grid>

            <Grid item xs={4}>
              <InformationBlock
                loading={loading}
                label={`Valor liquido projetado em ${
                  minorDuration ?? '-'
                } anos`}
                value={formatReaisOrUndefined(
                  operation.positionSnapshot
                    ?.substituteFixedIncomeOfferProjectedPrice,
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <InformationBlock
                loading={loading}
                label='Taxa'
                value={formatYieldDescription({
                  indexer: operation.newFixedIncomeOfferIndexer,
                  yieldRate: operation.newFixedIncomeOfferYieldRate,
                  indexerPercentRate:
                    operation.newFixedIncomeOfferIndexerPercentRate,
                })}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid xs={12} item>
        <Paper style={{ padding: 24 }} elevation={5}>
          <Grid container rowSpacing={3}>
            <Grid item xs={12}>
              <Typography variant='overline' color='GrayText'>
                Financeiro
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <InformationBlock
                loading={loading}
                label='Alongamento'
                value={`${monthsDifference} meses`}
              />
            </Grid>
            <Grid item xs={3}>
              <InformationBlock
                loading={loading}
                label='ROA'
                value={`+ ${formatReais(
                  operation?.roaAmount,
                )} (${formatPercentage(operation?.roa)})`}
              />
            </Grid>
            <Grid item xs={3}>
              <InformationBlock
                loading={loading}
                label={`Ágio líquido projetado em ${minorDuration} anos`}
                value={formatReais(operation.customerMarkUp)}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
