import {
  createSearchParams,
  useLocation,
  useMatch,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { IOfferDrawersNavigationHook } from './IOfferDrawersNavigationHook';

export const useComparadorOfferDrawersNavigation: IOfferDrawersNavigationHook =
  () => {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const closeDrawer = () => {
      navigate(`/comparador/${location.search}`);
    };

    const openAvailableOffersDrawer = () => {
      navigate(`/comparador/ofertas${location.search}`);
    };

    const openCreateOfferDrawer = () => {
      navigate(`/comparador/ofertas/criar${location.search}`);
    };

    const openEditOfferDrawer = (offerId: string) => {
      navigate(`/comparador/ofertas/editar/${offerId}${location.search}`);
    };

    const isAvailableOffersDrawerOpen = !!useMatch('/comparador/ofertas');

    const isCreateOfferDrawerOpen = !!useMatch('comparador/ofertas/criar');
    const isEditOfferDrawerOpen = !!useMatch(
      'comparador/ofertas/editar/:offerId',
    );

    const editOfferId = params.offerId;

    const goBack = () => {
      navigate(-1);
    };

    const onSelectOfferId = (offerId: string) => {
      navigate({
        pathname: '/comparador',
        search: `?${createSearchParams({
          offer: offerId,
        })}`,
      });
    };
    return {
      isAvailableOffersDrawerOpen,
      isCreateOfferDrawerOpen,
      isEditOfferDrawerOpen,
      openAvailableOffersDrawer,
      openCreateOfferDrawer,
      openEditOfferDrawer,
      editOfferId,
      goBack,
      closeDrawer,
      onSelectOfferId,
    };
  };
