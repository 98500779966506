import { Logo } from '@common/components/Logo';
import {
  AmplitudeEventTracker,
  AnimatrixApp,
  ChatWidget,
} from '@invest-ai/animatrix';
import React, { useRef } from 'react';
import 'react-toastify/dist/ReactToastify.min.css';
import 'simplebar-react/dist/simplebar.min.css';
import { apolloClient } from 'src/config/apollo/client';
import { InvestAIBU } from './__generated__/types';
import { bugsnagClient } from './bugsnagClient';
import { privateRoutes } from './config/dashboardRoutes';
import { getSidebarConfig } from './config/getSidebarConfig';
import { layout } from './config/layout';
import { product } from './config/product';
import { publicRoutes } from './config/publicRoutes';
import { useJWT } from './modules/common/hooks/useJWT';

export const GOOGLE_CLIENT_ID = import.meta.env.VITE_GOOGLE_CLIENT_ID as string;

export const CorleoneApp: React.FC = () => {
  const chatWidgetRef = useRef(new ChatWidget());
  const trackerRef = useRef(
    new AmplitudeEventTracker(import.meta.env.VITE_AMPLITUDE_API_KEY as string),
  );

  const payload = useJWT();

  return (
    <AnimatrixApp
      apolloClient={apolloClient}
      googleClientId={GOOGLE_CLIENT_ID}
      chatWidget={chatWidgetRef.current}
      tracker={trackerRef.current}
      product={product}
      logo={<Logo />}
      layout={layout}
      errorTracker={bugsnagClient}
      sidebarConfig={getSidebarConfig(payload?.roles)}
      privateRoutes={privateRoutes}
      publicRoutes={publicRoutes}
      buName={InvestAIBU.CARTEIRA}
    />
  );
};
