import { FixedIncomeProductKind } from '@invest-ai/common-fixed-income';
import { addDays, subDays } from 'date-fns';
import {
  Indexers,
  InvestmentCategory,
} from '../../../../../__generated__/types';
import { IFormData } from '../interfaces/IFormData';

export const DEFAULT_VALUES: IFormData = {
  indexer: Indexers.CDI,
  referenceUnitPrice: 15000,
  appliedUnitPrice: 10000,
  rebuyUnitPrice: 14000,
  yieldRate: 0.1,
  indexerPercentRate: 1,
  productKind: FixedIncomeProductKind.CDB,
  brokerAccountId: '',
  applicationDate: subDays(new Date(), 100),
  maturityDate: addDays(new Date(), 100),
  issuerId: '',
  freeOfIR: true,
  duration: 1,

  investmentCategory: InvestmentCategory.FIXED_INCOME,
  advisorId: '',
};
