import { gql, useMutation } from '@apollo/client';

export const CREATE_FIXED_INCOME_OFFER_MUTATION = gql`
  mutation createFixedIncomeOffer($input: CustomFixedIncomeOfferInput!) {
    createFixedIncomeOffer(input: $input) {
      id
    }
  }
`;

export const useCreateFixedIncomeOffer = () => {
  const [_createFixedIncomeOffer, { loading, error }] = useMutation(
    CREATE_FIXED_INCOME_OFFER_MUTATION,
  );

  const createFixedIncomeOffer = (input: any) =>
    _createFixedIncomeOffer({
      variables: {
        input,
      },
    });

  return {
    createFixedIncomeOffer,
    loading,
    error,
  };
};
