import { Box, SxProps, Tooltip } from '@mui/material';
import React from 'react';
import positionCreatedByUserSymbol from '../../../../../assets/offerCreatedByUserSymbol.svg';

export const ManualCreationToken: React.FC<{
  onClick: React.MouseEventHandler<HTMLDivElement>;
  sx?: SxProps;
}> = ({ onClick, sx }) => {
  return (
    <Box
      sx={sx}
      display='flex'
      onClick={(e) => {
        e.stopPropagation();
        onClick(e);
      }}
    >
      <Tooltip title='Criado por usuário'>
        <Box
          component='img'
          sx={{ ml: 1 }}
          src={positionCreatedByUserSymbol}
          alt='img'
        />
      </Tooltip>
    </Box>
  );
};
