import { YieldDescription } from '@common/interfaces/YieldDescription';
import { formatYieldDescription } from '@common/logic/formatYieldDescription';
import { formatReais } from '@invest-ai/common';
import { formatDateOrUndefined } from '../../DisplayDeal/logic/formatDateOrUndefined';

export const getEmailText = ({
  name,
  position,
  offer,
  financials,
}: {
  name: string;
  position: {
    nickName: string;
    appliedTotalPrice: number;
    maturityDate: Date;
    yieldDescription: YieldDescription;
    rebuyTotalNetPrice: number;
  };
  offer: {
    nickName: string;
    appliedTotalPrice: number;
    maturityDate: Date;
    yieldDescription: YieldDescription;
  };
  financials: {
    markup: number;
  };
}) => `Olá ${name}, tudo bem?
Estamos fazendo um trabalho especializado na carteira de Renda Fixa, buscando detectar oportunidades de melhorar ainda mais a performance da sua carteira.
Identificamos uma operação interessante que gostaria de compartilhar com você.
A operação consiste na venda antecipada de um título de renda fixa com baixa perspectiva de rentabilização em sua carteira atual, e respectivamente a compra de um ativo com potencial mais elevado de ganho.

Resumo da Estratégia de Venda:
Venda do ativo: ${position.nickName}
Valor Inicial Aplicado: ${formatReais(position.appliedTotalPrice)}
Vencimento em: ${formatDateOrUndefined(position.maturityDate)}
Taxa Contratada: ${formatYieldDescription(position.yieldDescription)}
Valor de Resgate: ${formatReais(position.rebuyTotalNetPrice)}

Resumo da Estratégia de Compra:
Compra do ativo: ${offer.nickName}
Valor Inicial aplicado: ${formatReais(offer.appliedTotalPrice)}
Novo vencimento em: ${formatDateOrUndefined(offer.maturityDate)}
Nova taxa contratada: ${formatYieldDescription(offer.yieldDescription)}

Explicações Adicionais:
Essa mudança poderá proporcionar a carteira um ganho de ${formatReais(
  financials.markup,
)} em relação a operação atual de sua carteira.
Esse valor pode oscilar para mais ou menos dependendo do cenário da curva de juros futura e inflação. Utilizamos premissas de cálculos baseado nas taxas de mercado atualizadas para a montagem dessa estratégia.

Caso tenha interesse nessa operação ou queira saber um pouco mais, basta me avisar!\n
Muito obrigado e estamos à disposição.`;
