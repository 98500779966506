import { Page } from '@invest-ai/animatrix';
import { Container } from '@mui/material';
import { useParams } from 'react-router-dom';
import { FixedIncomePositionsListParamsContext } from 'src/modules/fixed-income-positions/components/FixedIncomePositionsList/hooks/useFixedIncomePositionsListParams';
import { OfferDrawers } from '../../../offers/components/OffersDrawer';
import { useSelectedOffer } from '../../../offers/context/SelectedOfferContext';
import { useDisplayDealDrawersNavigation } from '../../../offers/context/useDisplayDealDrawersNavigation';
import { DisplayDeal } from '../../components/DisplayDeal';
import { VisualizationContext } from './VisualizationContext';
import { useRouteQuery } from './useRouteQuery';

export const DealsPage: React.FC = () => {
  const params = useParams<{
    positionId: string;
  }>();
  const query = useRouteQuery();
  const { selectedOfferId } = useSelectedOffer();
  const positionId = params.positionId as string;

  return (
    <Page title='InvestAI Carteira+ | Negociações'>
      <VisualizationContext.Provider
        initialState={{
          isPublic: false,
          hideSensitiveInformation: false,
        }}
      >
        <Container maxWidth={'xl'}>
          <FixedIncomePositionsListParamsContext.Provider>
            <DisplayDeal positionId={positionId} offerId={selectedOfferId} />
          </FixedIncomePositionsListParamsContext.Provider>
        </Container>
        <OfferDrawers
          positionIdToCompare={positionId}
          navigation={useDisplayDealDrawersNavigation}
        />
      </VisualizationContext.Provider>
    </Page>
  );
};
