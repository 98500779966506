import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import {
  CreateCustomPitchInput,
  CreateCustomPitchMutation,
  CreateCustomPitchMutationVariables,
} from 'src/__generated__/types';

export const CREATE_CUSTOM_PITCH_MUTATION = gql`
  mutation CreateCustomPitch($input: CreateCustomPitchInput!) {
  createCustomPitch(input: $input) {
    id
    customText
    fixedIncomeOfferId
    fixedIncomePositionId
  }
}
`;

export const useCreateCustomPitch = () => {
  const [_createCustomPitch, { data, loading }] = useMutation<
    CreateCustomPitchMutation,
    CreateCustomPitchMutationVariables
  >(CREATE_CUSTOM_PITCH_MUTATION);

  const createCustomPitch = (input: CreateCustomPitchInput) =>
    _createCustomPitch({
      variables: {
        input,
      },
    });

  return {
    createCustomPitch,
    data,
    loading,
  };
};
