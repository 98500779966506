import { createContainer } from 'unstated-next';

export interface IVisualizationContextProps {
  hideSensitiveInformation: boolean;
  isPublic: boolean;
}

export const VisualizationContext = createContainer<
  IVisualizationContextProps,
  IVisualizationContextProps
>((initialState) => {
  return {
    hideSensitiveInformation: false,
    isPublic: false,
    ...initialState,
  };
});
