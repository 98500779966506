import { fixPhone } from '../logic/fixPhone';

export class WhatsAppOpenChatService {
  openChat(targetPhone: string, text: string) {
    const fixedPhone = fixPhone(targetPhone);

    window.open(
      `https://wa.me/${fixedPhone}?text=${encodeURIComponent(text)}`,
      '_blank',
    );
  }
}

export const whatsAppService = new WhatsAppOpenChatService();
