import { Container } from '@mui/material';
import React from 'react';
import { TickerDataSource } from 'src/__generated__/types';
import { TickerDataSheetUpload } from './TickerDataSheetUpload';
import { useImportTickerData } from './useImportTickerData';

export const ImportTickerDataPage: React.FC = () => {
  return (
    <Container maxWidth={'xl'}>
      <TickerDataSheetUpload
        type={TickerDataSource.AMBIMA_CRI_CRA_SHEET}
        sheetName='ANBIMA CRI CRA'
        sx={{ mb: 1 }}
      />
      <TickerDataSheetUpload
        type={TickerDataSource.ECONOMATICA_DEBENTURE_SHEET}
        sheetName='ECONOMATICA DEB'
        sx={{ mb: 1 }}
      />
      <TickerDataSheetUpload
        type={TickerDataSource.ECONOMATICA_YIELD_TREASURY_SHEET}
        sheetName='ECONOMATICA TITULOS PÚBLICO'
        sx={{ mb: 1 }}
      />
    </Container>
  );
};
