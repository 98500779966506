import { isNil } from 'ramda';
import { Maybe } from './Maybe';
import { MaybeNil } from './MaybeNil';

export const getMinorDuration = (
  positionDuration: number,
  offerDuration: number,
): string =>
  positionDuration > offerDuration
    ? offerDuration.toFixed(2)
    : positionDuration.toFixed(2) || '';

export const getMinorDurationOrUndefined = (
  positionDuration: MaybeNil<number>,
  offerDuration: Maybe<number>,
): string | undefined => {
  if (isNil(positionDuration) || isNil(offerDuration)) {
    return undefined;
  }
  return getMinorDuration(positionDuration, offerDuration);
};
