import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LoadingButton from '@mui/lab/LoadingButton';
import { Tooltip } from '@mui/material';
import React from 'react';
import { toast } from 'react-toastify';

export const getTextForCode = (code: string) => `Vem comigo investir nos melhores e mais concorridos ativos de renda fixa do mercado de maneira simples e prática.\n
Investimentos segurados pelo FGC que rendem até 18% ao ano, nunca foi tão fácil investir bem.\nhttps://invest-ai.com.br?r=${code}`;

export const CopyCouponButton: React.FC<{
  code: string;
  loading: boolean;
}> = ({ loading, code }) => {
  const handleCopyButton = () => {
    navigator.clipboard.writeText(getTextForCode(code)).then(
      () => {
        toast.success('Código copiada para área de transferência');
      },
      (err) => {
        if (err instanceof Error) {
          toast.error(
            `Falha ao copiar para área de transferência: ${err.message}`,
          );
        }
      },
    );
  };

  return (
    <Tooltip title='Clique para copiar'>
      <span>
        <LoadingButton
          onClick={handleCopyButton}
          endIcon={<ContentCopyIcon />}
          size='large'
          color='primary'
          variant='contained'
          fullWidth
          loading={loading}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {code}
        </LoadingButton>
      </span>
    </Tooltip>
  );
};
