import { gql, useQuery } from '@apollo/client';
import {
  ComparisonChartsInput,
  ComparisonChartsQuery,
  ComparisonChartsQueryVariables,
} from '../../../__generated__/types';
export const COMPARISON_CHARTS_QUERY = gql`
  query ComparisonCharts($input: ComparisonChartsInput!) {
    comparisonCharts(input: $input) {
      intersectionPoint
      fixedIncomePositionChart {
        dataset {
          x
          y
        }
      }
      fixedIncomeOfferChart {
        dataset {
          x
          y
        }
      }
    }
  }
`;

export const useComparisonCharts = (input: ComparisonChartsInput) => {
  const { data, loading, error } = useQuery<
    ComparisonChartsQuery,
    ComparisonChartsQueryVariables
  >(COMPARISON_CHARTS_QUERY, {
    variables: {
      input,
    },
    skip: input.offerPriceApplied === 0 || input.positionPriceApplied === 0,
  });

  // TODO: Get custom yield description

  return {
    loading,
    error,
    fixedIncomePositionChart: data?.comparisonCharts.fixedIncomePositionChart,
    fixedIncomeOfferChart: data?.comparisonCharts.fixedIncomeOfferChart,
    intersectionPoint: data?.comparisonCharts.intersectionPoint,
  };
};
