import { DisplayError } from '@invest-ai/animatrix';
import React, { PropsWithChildren } from 'react';

export const DisplayErrorOrChildren: React.FC<
  PropsWithChildren<{
    error?: Error;
  }>
> = ({ children, error }) => {
  if (!error) {
    return children;
  }
  return <DisplayError error={error} />;
};
