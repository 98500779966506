import { Button, Color } from '@invest-ai/animatrix';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Badge } from '@mui/material';
import {
  GridFilterModel,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-premium';
import { useState } from 'react';
import { FixedIncomePositionsFilterDrawerContext } from '../../FixedIncomePositionsFilterDrawer/FixedIncomePositionsFilterDrawerContext';
import { usePositionsListFilterCount } from '../hooks/usePositionsListFilterCount';

export const CustomToolbar: React.FC<{
  initialSearch: string;
  onPressCreatePosition: () => void;
  setFilterModel: (model: GridFilterModel) => void;
}> = ({ initialSearch, onPressCreatePosition, setFilterModel }) => {
  const [filterValue, setFilterValue] = useState(initialSearch);

  const positionsFilterDrawer =
    FixedIncomePositionsFilterDrawerContext.useContainer();

  const handleKeyDown = (event: any) => {
    if (event.code === 'Enter') {
      setFilterModel({
        items: [],
        quickFilterValues: filterValue.split(' '),
      });
    }
  };

  const { filtersCount } = usePositionsListFilterCount();
  return (
    <GridToolbarContainer
      style={{ display: 'flex', justifyContent: 'flex-start' }}
    >
      <GridToolbarQuickFilter
        placeholder='Procurar posição...'
        debounceMs={500}
        onKeyDown={handleKeyDown}
        value={filterValue}
        onChange={(e) => {
          console.log(e);
          console.log('settinf filter value');
          setFilterValue(e.target.value);
        }}
        sx={{ mr: 2, flex: 1 }}
      />
      <GridToolbarExport />
      <Badge badgeContent={filtersCount} color='error' sx={{ mx: 1 }}>
        <Button
          variant='soft'
          startIcon={<FilterListIcon />}
          onClick={positionsFilterDrawer.toggleVisibility}
        >
          Filtrar
        </Button>
      </Badge>

      <Button
        onClick={onPressCreatePosition}
        color='primary'
        variant='contained'
        // Override toolbar default color
        style={{ color: Color.WHITE }}
      >
        Criar Posição
      </Button>
    </GridToolbarContainer>
  );
};
