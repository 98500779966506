import { Page } from '@invest-ai/animatrix';
import { Container, Typography } from '@mui/material';
import { OperationsList } from '../components/OperationsList';

export const OperationsPage: React.FC = () => {
  return (
    <Page title='InvestAI Carteira+ | Operações'>
      <Container>
        <OperationsList />
      </Container>
    </Page>
  );
};
