import { BuildFormProps } from '@invest-ai/animatrix';
import React from 'react';
import { Controller } from 'react-hook-form';
import { SearchableSelect } from './SearchableSelect';
import { ISearchableSelectOption } from './interfaces/ISearchableSelectOption';
import { ISearchableSelectProps } from './interfaces/ISearchableSelectProps';

export const FormSearchableSelect: React.FC<
  BuildFormProps<ISearchableSelectProps>
> = ({ name, control, rules, options, multiple, ...props }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { value, onChange } }) => {
        return (
          <SearchableSelect
            value={value}
            onChange={onChange}
            options={options}
            multiple={multiple}
            {...props}
          />
        );
      }}
    />
  );
};
