import {
  DataGridPremiumProps as DataGridProps,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  DataGridPremium as MUIDataGrid,
  ptBR,
} from '@mui/x-data-grid-premium';

export const DataGrid: React.FC<DataGridProps> = ({
  slots,
  slotProps,
  ...props
}) => {
  return (
    <MUIDataGrid
      localeText={{
        ...ptBR.components.MuiDataGrid.defaultProps.localeText,
        // ptBR's MUI DataGrid doesn't have translation for this option
        filterOperatorIsAnyOf: 'é qualquer um dos',
      }}
      slots={{
        toolbar: () => (
          <GridToolbarContainer>
            <GridToolbarFilterButton />
            <GridToolbarColumnsButton />
          </GridToolbarContainer>
        ),
        ...slots,
      }}
      slotProps={{
        filterPanel: {
          sx: {
            // It solves a style problem because of Minimals' styles
            // TODO: remove it when discovered how to style it in the  theme overrides way
            py: 1.5,
            px: 0,
            '& .MuiDataGrid-filterFormDeleteIcon': { width: 'unset' },
            '& .MuiDataGrid-filterFormLinkOperatorInput': { width: 'unset' },
            '& .MuiFormControl-root': {
              my: 0,
              mx: 0.5,
            },
            '& .MuiInput-root': {
              mt: 3,
              '&::before, &::after': {
                display: 'none',
              },
              '& .MuiNativeSelect-select, .MuiInput-input': {
                typography: 'body2',
                py: 0.75,
                px: 1,
                borderRadius: 1,
                backgroundColor: 'background.neutral',
              },
              '& .MuiSvgIcon-root': {
                right: 4,
              },
            },
          },
        },
        ...slotProps,
      }}
      {...props}
    />
  );
};
