import { GridSortDirection } from '@mui/x-data-grid-premium';
import { SortDirection } from '../../../../../__generated__/types';

export const mapGridSortDirectionToSortDirection = (
  gridSortDirection: GridSortDirection,
): SortDirection => {
  if (gridSortDirection === 'asc') {
    return SortDirection.ASC;
  }
  if (gridSortDirection === 'desc') {
    return SortDirection.DESC;
  }
  return SortDirection.ASC;
};

export const mapSortDirectionToGridSortDirection = (
  sortDirection: SortDirection,
): GridSortDirection => {
  if (sortDirection === SortDirection.ASC) {
    return 'asc';
  }
  if (sortDirection === SortDirection.DESC) {
    return 'desc';
  }
  return 'asc';
};
