import { Color } from '@invest-ai/animatrix';
import { Typography } from '@mui/material';
import { CSSProperties } from 'react';

interface INumberColorizedProps {
  value: string | undefined;
  valueStyle?: CSSProperties;
}

export const NumberColorized: React.FC<INumberColorizedProps> = ({
  value,
  valueStyle,
}) => {
  const isNegative = value?.charAt(0) === '-';
  const prefix = !isNegative ? '+' : '';

  return (
    <Typography
      color={isNegative ? Color.NEGATIVE : Color.POSITIVE}
      style={valueStyle}
    >
      {prefix}
      {value}
    </Typography>
  );
};
