import { FreeOfIRToken } from '../../../../../../fixed-income-positions/components/FakeSelectOffer/FreeOfIRToken';
import { QualifiedInvestorToken } from '../../../../../../fixed-income-positions/components/FakeSelectOffer/QualifiedInvestorToken';
import { ManualCreationToken } from '../../../../../../fixed-income-positions/components/FixedIncomePositionsList/components/ManualCreationToken';

interface ICustomNicknameCellProps {
  offerId: string;
  nickname: string;
  freeOfIR: boolean;
  investorQualified: boolean;
  offerCreatedByUser: boolean;
  onClickEditOffer: (offerId: string) => void;
}

export const CustomNicknameCell: React.FC<ICustomNicknameCellProps> = ({
  nickname,
  freeOfIR,
  investorQualified,
  offerCreatedByUser,
  offerId,
  onClickEditOffer,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <span>{nickname}</span>
      {freeOfIR && <FreeOfIRToken sx={{ marginLeft: 1 }} />}
      {investorQualified && <QualifiedInvestorToken sx={{ marginLeft: 1 }} />}
      {offerCreatedByUser && (
        <ManualCreationToken
          sx={{ marginLeft: 1 }}
          onClick={() => onClickEditOffer(offerId)}
        />
      )}
    </div>
  );
};
