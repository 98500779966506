import { gql, useMutation } from '@apollo/client';
import { FixedIncomePositionsListParamsContext } from 'src/modules/fixed-income-positions/components/FixedIncomePositionsList/hooks/useFixedIncomePositionsListParams';
import { FIXED_INCOME_POSITIONS_TO_DEAL_QUERY } from 'src/modules/fixed-income-positions/components/FixedIncomePositionsList/hooks/useFixedIncomePositionsToDeal';
import { getFixedIncomePositionsToDealVariables } from 'src/modules/fixed-income-positions/components/FixedIncomePositionsList/logic/getFixedIncomePositionsToDealVariables';
import {
  UpdateFixedIncomePositionInput,
  UpdateFixedIncomePositionMutation,
  UpdateFixedIncomePositionMutationVariables,
} from '../../../__generated__/types';

export const UPDATE_FIXED_INCOME_POSITION_MUTATION = gql`
  mutation UpdateFixedIncomePosition(
    $id: UUID!
    $input: UpdateFixedIncomePositionInput!
  ) {
    updateFixedIncomePosition(id: $id, input: $input) {
      id
      createdBy
      rebuyUnitPrice
    }
  }
`;

export const useUpdateFixedIncomePosition = () => {
  const { search, filterParams, paginationParams, sortParams } =
    FixedIncomePositionsListParamsContext.useContainer();

  const [_updateFixedIncomePosition, { data, loading }] = useMutation<
    UpdateFixedIncomePositionMutation,
    UpdateFixedIncomePositionMutationVariables
  >(UPDATE_FIXED_INCOME_POSITION_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: FIXED_INCOME_POSITIONS_TO_DEAL_QUERY,
        variables: getFixedIncomePositionsToDealVariables(
          search,
          paginationParams,
          sortParams,
          filterParams,
        ),
      },
    ],
  });

  const updateFixedIncomePosition = (
    id: string,
    input: UpdateFixedIncomePositionInput,
  ) =>
    _updateFixedIncomePosition({
      variables: {
        id,
        input,
      },
    });

  return {
    updateFixedIncomePosition,
    data,
    loading,
  };
};
