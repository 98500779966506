import { YieldDescription } from '@common/interfaces/YieldDescription';
import { _formatYieldDescription } from '@common/logic/formatYieldDescription';
import { GridValueFormatterParams } from '@mui/x-data-grid-premium';

export const columnYieldDescriptionFormatter = (
  params: GridValueFormatterParams<YieldDescription>,
) =>
  _formatYieldDescription(
    params.value.indexer,
    params.value.yieldRate,
    params.value.indexerPercentRate,
  );
