import { Indexers } from '@common/enum/Indexers';
import { AvailableOfferIndexers } from '../../../../constants/AVAILABLE_OFFER_INDEXERS';

export const mapIndexers = (indexers: Indexers): AvailableOfferIndexers => {
  switch (indexers) {
    case Indexers.CDI:
      return AvailableOfferIndexers.CDI;
    case Indexers.IPCA:
      return AvailableOfferIndexers.IPCA;
    case Indexers.PREFIXADO:
      return AvailableOfferIndexers.PREFIXADO;
    default:
      return AvailableOfferIndexers.PREFIXADO;
  }
};
