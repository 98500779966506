import ShowChartIcon from '@mui/icons-material/ShowChart';
import { Alert, Button, Stack, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { FEATURE_FLAGS } from 'src/config/FeatureFlags';
import { routerPaths } from 'src/config/routePaths';

export const MGMBanner: React.FC = () => {
  if (!FEATURE_FLAGS.DISPLAY_MGM) {
    return null;
  }
  return (
    <Alert
      sx={{ mb: 2 }}
      icon={<ShowChartIcon />}
      action={
        <Link to={routerPaths.app.mgm}>
          <Button variant='contained' color='primary'>
            Clique aqui e saiba mais!
          </Button>
        </Link>
      }
    >
      <Stack sx={{ flex: 1 }}>
        <Typography>
          Quer entregar as <strong>melhores taxas do mercado secundário</strong>{' '}
          para seus clientes?
        </Typography>
      </Stack>
    </Alert>
  );
};
