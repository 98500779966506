import { gql, useLazyQuery } from '@apollo/client';

export const GET_UPLOAD_SIGNED_URL_QUERY = gql`
query GetUploadSignedUrl($type: String!) {
  getUploadSignedUrl(type: $type) {
    filename
    uploadUrl
  }
}
`;

export const useGetSignedUrl = () => {
  const [_getSignedUrl] = useLazyQuery(GET_UPLOAD_SIGNED_URL_QUERY);

  const getSignedUrl = (type: string) =>
    _getSignedUrl({
      variables: {
        type,
      },
    }).then((response) => response.data.getUploadSignedUrl);

  return {
    getSignedUrl,
  };
};
