import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import { createContainer } from 'unstated-next';
import { useRouteQuery } from '../../deals/pages/DealsPage/useRouteQuery';

export const useSelectedOffer = () => {
  const navigate = useNavigate();

  const params = useParams<{ positionId: string }>();
  function setSelectedOfferUsingId(id: string) {
    navigate({
      pathname: `/negociacoes/${params.positionId}`,
      search: `?${createSearchParams({
        offer: id,
      })}`,
    });
  }

  const query = useRouteQuery();
  const selectedOfferId = query.get('offer');

  return {
    setSelectedOfferUsingId,
    selectedOfferId,
  };
};

export const SelectedOfferContext = createContainer(useSelectedOffer);
