import { Box, Tooltip, Typography, TypographyTypeMap } from '@mui/material';
import React, { CSSProperties } from 'react';
import infoSymbol from '../../../../assets/info.svg';
import { LoadingText } from './LoadingText';

export interface IInformationBlockProps {
  label: string | React.ReactNode;
  value: React.ReactNode;
  labelVariant?: TypographyTypeMap['props']['variant'];
  valueStyle?: CSSProperties;
  displayLoading?: boolean;
  tooltip?: string;
  inactive?: boolean;
  loading?: boolean;
  placeholder?: string;
  addornment?: React.ReactNode;
  loadingHeight?: number;
}
export const InformationBlock: React.FC<IInformationBlockProps> = ({
  label,
  value,
  labelVariant = 'caption',
  valueStyle,
  tooltip,
  inactive,
  loading = false,
  placeholder = '-',
  addornment,
  loadingHeight,
}) => (
  <Box>
    <Box display='flex' flexDirection='row' alignItems='center'>
      <Typography
        variant={labelVariant}
        lineHeight={2}
        color={inactive ? '#919EAB' : '#637381'}
      >
        {label}
      </Typography>

      {tooltip && (
        <Tooltip title={tooltip} sx={{ width: 12, height: 12 }}>
          <Box
            alt='img'
            component='img'
            sx={{
              display: 'inline',
              ml: 1,
              width: 12,
              height: 12,
            }}
            src={infoSymbol}
          />
        </Tooltip>
      )}

      {addornment}
    </Box>

    {loading ? (
      <LoadingText height={loadingHeight} />
    ) : typeof value === 'string' ? (
      <Typography
        style={valueStyle}
        color={inactive ? '#919EAB !important' : '#212B36'}
      >
        {value ?? placeholder}
      </Typography>
    ) : (
      value
    )}
  </Box>
);
