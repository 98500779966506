import { Stack } from '@mui/material';
import { StyledTitle } from './StyledTitle';

export const SignInTitle: React.FC = () => {
  return (
    <Stack>
      <StyledTitle>
        <StyledTitle component='span' color='primary'>
          Mais
        </StyledTitle>{' '}
        receita para o escritório
      </StyledTitle>
      <StyledTitle>
        <StyledTitle component='span' color='primary'>
          Mais
        </StyledTitle>{' '}
        satisfação para o cliente
      </StyledTitle>{' '}
    </Stack>
  );
};
