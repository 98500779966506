import { safeFormatDate } from '@common/date/formatters';
import { Circle } from '@mui/icons-material';
import { Box, Divider, Typography } from '@mui/material';
import { PositionsImporterHistoric } from '../../../../__generated__/types';

const IMPORT_DATE_TIME_PATTERN = "dd 'de' MMMM 'de' yyyy, HH'h'mm'm'";
const REFERENCE_DATE_TIME_PATTERN = "dd 'de' MMMM 'de' yyyy";

interface ImportedItemProps {
  data: PositionsImporterHistoric;
  first: boolean;
}

export const ImportedOffersItem: React.FC<ImportedItemProps> = ({
  data,
  first,
}) => {
  const importedDateFormatted = safeFormatDate(
    new Date(data.date),
    IMPORT_DATE_TIME_PATTERN,
  );
  const referenceDateFormatted = safeFormatDate(
    new Date(data.referenceDate),
    REFERENCE_DATE_TIME_PATTERN,
  );
  return (
    <Box flex={1}>
      {!first ? <Divider /> : null}
      <Box
        flexDirection='row'
        display='flex'
        alignItems='center'
        paddingTop={3}
        paddingBottom={3}
        justifyContent='space-between'
      >
        <Box flexDirection='row' display='flex' alignItems='center'>
          <Box marginRight={2} display='flex' alignItems='center'>
            <Circle color='primary' />
          </Box>
          <Box>
            <Typography fontWeight={600} fontSize={14}>
              {importedDateFormatted}
            </Typography>
            {data?.referenceDate && (
              <Typography fontSize={12} color='#637381'>
                Data de referência das ofertas: {referenceDateFormatted}
              </Typography>
            )}
          </Box>
        </Box>
        <Box>
          <Typography fontSize={12} color='GrayText'>
            Upload realizado
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
