import { MGMBoletadorPage } from 'src/modules/mgm/pages/MGMBoletadorPage';
import { ImportOffersPage } from 'src/modules/upload-offers/pages/ImportOffersPage/ImportOffersPage';
import { ImportTickerDataPage } from 'src/modules/upload-offers/pages/ImportOffersPage/ImportTickerDataPage';
import { urlJoin } from 'url-join-ts';
import { DealsPage } from '../modules/deals/pages/DealsPage';
import { CompareOffersPage } from '../modules/fixed-income-positions/pages/CompareOffers';
import { ImportPositionsPage } from '../modules/fixed-income-positions/pages/ImportPositions';
import { ListPositionsPage } from '../modules/fixed-income-positions/pages/ListPositions';
import { OperationPage } from '../modules/operations/pages/OperationPage';
import { OperationsPage } from '../modules/operations/pages/OperationsPage';
import { routerPaths } from './routePaths';

export const privateRoutes = [
  {
    path: routerPaths.app.positions,
    element: <ListPositionsPage />,
  },
  {
    path: urlJoin('posicoes', 'criar'),
    element: <ListPositionsPage />,
  },
  {
    path: urlJoin('posicoes', 'editar', ':positionId'),
    element: <ListPositionsPage />,
  },

  {
    path: urlJoin('negociacoes', ':positionId'),
    element: <DealsPage />,
  },
  {
    path: urlJoin('negociacoes', ':positionId', 'ofertas'),
    element: <DealsPage />,
  },
  {
    path: urlJoin('negociacoes', ':positionId', 'ofertas', 'criar'),
    element: <DealsPage />,
  },
  {
    path: urlJoin('negociacoes', ':positionId', 'ofertas', 'editar'),
    element: <DealsPage />,
  },
  {
    path: urlJoin(
      'negociacoes',
      ':positionId',
      'ofertas',
      'editar',
      ':editOfferId',
    ),
    element: <DealsPage />,
  },
  {
    path: urlJoin('operacoes'),
    element: <OperationsPage />,
  },
  {
    path: urlJoin('operacoes', ':operationId'),
    element: <OperationPage />,
  },
  {
    path: 'importar-posicoes',
    element: <ImportPositionsPage />,
  },
  {
    path: 'importar-ofertas',
    element: <ImportOffersPage />,
  },
  {
    path: routerPaths.app.importTickerData,
    element: <ImportTickerDataPage />,
  },

  {
    path: 'comparador',
    element: <CompareOffersPage />,
  },
  {
    path: urlJoin('comparador', 'ofertas'),
    element: <CompareOffersPage />,
  },
  {
    path: urlJoin('comparador', 'ofertas', 'criar'),
    element: <CompareOffersPage />,
  },
  {
    path: urlJoin('comparador', 'ofertas', 'editar', ':offerId'),
    element: <CompareOffersPage />,
  },
  {
    path: urlJoin('comparador', 'posicoes', 'criar'),
    element: <CompareOffersPage />,
  },
  {
    path: urlJoin('comparador', 'posicoes', 'editar', ':positionId'),
    element: <CompareOffersPage />,
  },
  {
    path: routerPaths.app.mgm,
    element: <MGMBoletadorPage />,
  },
];
