import { InputLabel, type SelectProps, Skeleton } from '@mui/material';
import MUISelect from '@mui/material/Select';

interface ISelectProps {
  loading?: boolean;
}
export type OptionValue = string | string[];

export const Select: React.FC<ISelectProps & SelectProps<OptionValue>> = ({
  loading,
  sx,
  label,
  ...props
}) => {
  if (loading) {
    return (
      <Skeleton
        variant='rectangular'
        width='100%'
        sx={{ ...sx, borderRadius: 1 }}
      >
        <MUISelect />
      </Skeleton>
    );
  }
  return (
    <>
      <InputLabel>{label}</InputLabel>
      <MUISelect
        variant='outlined'
        fullWidth
        sx={sx}
        label={label}
        {...props}
      />
    </>
  );
};
