import React from 'react';
import { Controller } from 'react-hook-form';
import { Checkbox, type ICheckboxProps } from '../components/Checkbox';
import { type BuildFormProps } from '../types/BuildFormProps';

// TODO Show errors
export const FormCheckbox: React.FC<BuildFormProps<ICheckboxProps>> = ({
  control,
  name,
  rules,
  ...props
}) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({ field }) => (
      <Checkbox value={field.value} onChangeValue={field.onChange} {...props} />
    )}
  />
);
