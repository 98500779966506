import axios, { AxiosError, AxiosProgressEvent } from 'axios';
import { isNil } from 'ramda';
import { useState } from 'react';
import { useGetSignedUrl } from './useGetSignedUrl';

export const useUploadFile = () => {
  const { getSignedUrl } = useGetSignedUrl();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState<number | null>(null);
  const uploadFile = async (file: File) => {
    setLoading(true);
    try {
      const { filename, uploadUrl } = await getSignedUrl(file.type);

      await axios.put(uploadUrl, file, {
        onUploadProgress: (e: AxiosProgressEvent): void => {
          if (isNil(e.total) || e.total === 0) {
            return;
          }
          setProgress(e.loaded / e.total);
        },
        headers: {
          'Content-Type': file.type,
        },
      });
      return {
        filename,
      };
    } finally {
      setLoading(false);
      setProgress(null);
    }
  };

  return {
    uploadFile,
    progress,
    loading,
  };
};
