import { GridSortItem } from '@mui/x-data-grid-premium';
import { ISortParams } from '../interfaces/ISortParams';
import { mapGridSortDirectionToSortDirection } from './mapGridSortDirectionToSortDirection';

export const mapGridSortItemToSortParams = (
  gridSortItem: GridSortItem | undefined,
): ISortParams | undefined => {
  if (!gridSortItem) {
    return undefined;
  }
  return {
    direction: mapGridSortDirectionToSortDirection(gridSortItem.sort),
    field: gridSortItem.field,
  };
};
