import { Page } from '@invest-ai/animatrix';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import { Container, useTheme } from '@mui/material';
import Tab from '@mui/material/Tab';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MGMBoletador } from '../components/MGMBoletador';
import { MGMCarteira } from '../components/MGMCarteira';

export const MGMBoletadorPage: React.FC = () => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const params = new URLSearchParams(search);
  const currentTabValue = params.get('tab') ?? 'customers';

  const handleTabChange = (value: string) => {
    navigate({
      search: `tab=${value}`,
    });
  };
  const theme = useTheme();

  return (
    <Page title='InvestAI Carteira+ | Indique'>
      <Container maxWidth={'xl'}>
        <TabContext value={currentTabValue}>
          <TabList
            onChange={(_, value) => handleTabChange(value)}
            indicatorColor='primary'
            TabIndicatorProps={{
              sx: {
                backgroundColor: theme.palette.primary.main,
              },
            }}
          >
            <Tab label='Indique seus clientes' value='customers' />
          </TabList>
          <TabPanel value='customers'>
            <MGMBoletador />
          </TabPanel>
          <TabPanel value='advisors'>
            <MGMCarteira />
          </TabPanel>
        </TabContext>
      </Container>
    </Page>
  );
};
