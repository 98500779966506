import { TickerDataSource } from 'src/__generated__/types';

export const getSourceLabelFromTickerDataSource = (
  tickerDataSource: TickerDataSource,
): string => {
  const map: Record<TickerDataSource, string> = {
    [TickerDataSource.AMBIMA]: 'ANBIMA',
    [TickerDataSource.ECONOMATICA]: 'Economática',
    [TickerDataSource.AMBIMA_CRI_CRA_SHEET]: 'ANBIMA',
    [TickerDataSource.ECONOMATICA_YIELD_TREASURY_SHEET]: 'Economática',
    [TickerDataSource.ECONOMATICA_DEBENTURE_SHEET]: 'Economática',
    [TickerDataSource.POSITIONS_SHEET]: 'Planilha de Posições',
    [TickerDataSource.USER]: 'Fornecido pelo Usuário',
  };
  return map[tickerDataSource] ?? 'Desconhecido';
};
