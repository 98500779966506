import { TransformOperations } from '@invest-ai/animatrix';

export const floatTransformer: TransformOperations<number> = {
  input: (value: number) =>
    Number.isNaN(value) || value === 0 ? '' : value.toString(),
  output: (value: string) => {
    const output = Number.parseFloat(value);
    return Number.isNaN(output) ? 0 : output;
  },
};
