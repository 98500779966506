import { Controller } from 'react-hook-form';
import { EasySelect, type IEasySelectProps } from '../components/EasySelect';
import { type BuildFormProps } from '../types/BuildFormProps';

export const FormEasySelect: React.FC<BuildFormProps<IEasySelectProps>> = ({
  name,
  control,
  rules,
  ...props
}) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({ field, fieldState }) => (
      <EasySelect
        value={field.value}
        onChange={field.onChange}
        error={!!fieldState?.error}
        helperText={fieldState?.error?.message}
        {...props}
      />
    )}
  />
);
