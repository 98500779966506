import { Indexers } from '../../../../../__generated__/types';

export const getTooltipYearlyYieldRate = (
  indexer: Indexers | undefined | null,
  yearlyYieldRate?: number,
): string => {
  if (!yearlyYieldRate || !indexer) {
    return '';
  }

  const rate = (yearlyYieldRate * 100).toFixed(2).replace('.', ',');

  if (indexer === Indexers.CDI || indexer === Indexers.CDI_PLUS) {
    return `Taxa de juros considerada de ${rate}% para o período.`;
  }

  if (indexer === Indexers.IPCA) {
    return `Inflação implícita de ${rate}% considerada para o período.`;
  }

  return '';
};
