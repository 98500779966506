import { gql, useQuery } from '@apollo/client';

import { UserBrokerAccountsQuery } from '../../../__generated__/types';
export const USER_BROKER_ACCOUNTS_QUERY = gql`
  query UserBrokerAccounts {
    userBrokerAccounts {
      id
      accountNumber
    }
  }
`;

export const useUserBrokerAccounts = () => {
  const { data, loading, error, refetch } = useQuery<UserBrokerAccountsQuery>(
    USER_BROKER_ACCOUNTS_QUERY,
  );

  return {
    brokerAccounts: data?.userBrokerAccounts ?? [],
    loading,
    error,
    refetch,
  };
};
