import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  type FormControlProps,
  FormHelperText,
  ListItemText,
  MenuItem,
  type SelectProps,
  type SxProps,
} from '@mui/material';
import { type OptionValue, Select } from '../../components/Select';

export interface SelectOption<T> {
  value: T;
  label: string;
}
export type IEasySelectProps<T = any> = Omit<SelectProps<T>, 'onChange'> & {
  value: OptionValue | null;
  options: SelectOption<string | number>[];
  label: string;
  onChange?: (value: OptionValue) => void;
  style?: React.CSSProperties;
  multiple?: boolean;
  displayEmpty?: boolean;
  disabled?: boolean;
  renderEmptyValue?: (value: OptionValue) => React.ReactNode;
  loading?: boolean;
  error?: boolean;
  helperText?: string;
  sx?: SxProps;
  size?: FormControlProps['size'];
  // TODO: implement
  clearable?: boolean;
};

export const EasySelect: React.FC<IEasySelectProps> = ({
  onChange,
  value,
  options,
  label,
  style,
  multiple,
  displayEmpty,
  disabled,
  renderEmptyValue,
  loading,
  error,
  helperText,
  sx,
  size,
  fullWidth,
  ...props
}) => {
  const renderValue = (selected: OptionValue) => {
    if (multiple && Array.isArray(selected)) {
      if (selected.length) {
        return (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => {
              const option = options.find((option) => option.value === value);
              return (
                <Chip
                  component='div'
                  key={value}
                  label={option?.label}
                  size='small'
                  color='primary'
                  variant='soft'
                />
              );
            })}
          </Box>
        );
      }
      return renderEmptyValue?.(selected) ?? null;
    }
    const option = options.find((option) => option.value === selected);
    return `${option?.label ?? ''}`;
  };

  const renderOptions = () =>
    options.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {multiple ? (
          <>
            <Checkbox
              checked={
                (value as Array<string | number>)?.indexOf(option.value) > -1 ??
                false
              }
            />
            <ListItemText primary={option.label} />
          </>
        ) : (
          <ListItemText primary={option.label} />
        )}
      </MenuItem>
    ));

  return (
    <FormControl style={style} sx={sx} size={size} fullWidth={fullWidth}>
      <Select
        displayEmpty={displayEmpty}
        renderValue={renderValue}
        multiple={multiple}
        value={value}
        label={label}
        disabled={disabled}
        onChange={(e) => {
          if (!onChange) return;
          onChange(e.target.value);
        }}
        MenuProps={{
          PaperProps: { sx: { maxHeight: 300 } },
        }}
        loading={loading}
        error={error}
        fullWidth={fullWidth}
        {...props}
      >
        {renderOptions()}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
