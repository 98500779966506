import React from 'react';
import { InvestAIBU } from 'src/__generated__/types';
import { MGMCarteiraHowItWorks } from './MGMCarteiraHowItWorks';
import { MGMCarteiraMyReferrals } from './MGMCarteiraMyReferrals';
import { MyCoupon } from './MyCoupon';

export const MGMCarteira: React.FC = () => {
  return (
    <>
      <MyCoupon target={InvestAIBU.CARTEIRA} />

      <MGMCarteiraHowItWorks />

      <MGMCarteiraMyReferrals />
    </>
  );
};
