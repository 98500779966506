import { gql, useQuery } from '@apollo/client';
import { InvestAIBU, MGMDashboardQuery } from 'src/__generated__/types';

const MGM_DASHBOARD_QUERY = gql`
  query MGMDashboard($source: InvestAIBU!, $target: InvestAIBU!) {
    mgmCoupon(source: $source, target: $target) {
      code
      id
      couponUsage {
        id
        createdAt
        user {
          email
          name
        }
      }
    }
  }
`;

export const useMGMDashboard = ({
  source,
  target,
}: {
  source: InvestAIBU;
  target: InvestAIBU;
}) => {
  const { data, loading, error } = useQuery<MGMDashboardQuery>(
    MGM_DASHBOARD_QUERY,
    {
      variables: {
        target,
        source,
      },
    },
  );

  return {
    couponUsage: data?.mgmCoupon?.couponUsage,
    code: data?.mgmCoupon.code,
    loading,
    boletador: {
      referralPrize: {
        value: 5000,
        unit: 'points',
      },
      refereePrize: {
        value: 50,
        unit: 'reais',
      },
    },
    carteira: {
      referralPrize: {
        value: 5000,
        unit: 'points',
      },
      refereePrize: {
        value: 50,
        unit: 'reais',
      },
    },
    error,
  };
};
