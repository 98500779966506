import { FloatIntervalInput } from 'src/__generated__/types';
import { MaybeNil } from 'src/modules/deals/components/DisplayDeal/logic/MaybeNil';

export const isFloatIntervalFilterActive = (
  maybeFloatInterval: MaybeNil<FloatIntervalInput>,
  defaultLowerBoundary: number,
) => {
  return (
    maybeFloatInterval &&
    (maybeFloatInterval.min !== defaultLowerBoundary || maybeFloatInterval.max)
  );
};
