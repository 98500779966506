import { GridPaginationModel } from '@mui/x-data-grid-premium';
import { IPaginationParams } from '../interfaces/IPaginationParams';

export const mapPaginationParamsToPaginationModel = (
  paginationParams: IPaginationParams,
): GridPaginationModel => {
  return {
    page: paginationParams.page,
    pageSize: paginationParams.first,
  };
};
