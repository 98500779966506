import { MATURITY_MONTHS_FILTER_MAX } from 'src/modules/fixed-income-positions/components/FixedIncomePositionsFilterDrawer/MATURITY_MONTHS_FILTER_MAX';

export const MATURITY_DAYS_MARKS = [
  {
    value: 12,
    label: '1a',
  },
  {
    value: 24,
    label: '2a',
  },
  {
    value: 48,
    label: '4a',
  },
  {
    value: 72,
    label: '6a',
  },
  {
    value: 96,
    label: '8a',
  },
  {
    value: 120,
    label: '10a',
  },
  {
    value: 144,
    label: '12a',
  },
  {
    value: 168,
    label: '14a',
  },
  {
    value: 25 * 12,
    label: '25a',
  },
  {
    value: MATURITY_MONTHS_FILTER_MAX,
    label: `${MATURITY_MONTHS_FILTER_MAX / 12}a+`,
  },
];
