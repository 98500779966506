import { gql, useQuery } from '@apollo/client';
import { parseServerDate } from '@common/date/parsers';
import { useOfferCustomYieldDescription } from 'src/modules/deals/components/DisplayDeal/hooks/useOfferCustomYieldDescription';
import { MaybeNil } from 'src/modules/deals/components/DisplayDeal/logic/MaybeNil';
import {
  FixedIncomePositionFilter,
  FixedIncomePositionsToDealQuery,
  FixedIncomePositionsToDealQueryVariables,
} from '../../../../../__generated__/types';
import { IPaginationParams } from '../interfaces/IPaginationParams';
import { ISortParams } from '../interfaces/ISortParams';
import { getFixedIncomePositionsToDealVariables } from '../logic/getFixedIncomePositionsToDealVariables';

export const FIXED_INCOME_POSITIONS_TO_DEAL_QUERY = gql`
  query FixedIncomePositionsToDeal(
    $page: Int!
    $first: Int!
    $sortBy: String
    $sortDirection: SortDirection
    $search: String
    $financialParams: CalculatePositionFinancialsInput!
    $withFinancials: Boolean!
    $filter: FixedIncomePositionFilter
  ) {
    paginatedPositionsToDeal(
      page: $page
      first: $first
      sortBy: $sortBy
      sortDirection: $sortDirection
      search: $search
      filter: $filter
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        totalEdges
      }
      edges {
        node {
          ...FixedIncomePositionFragment
        }
      }
    }
  }

  fragment FixedIncomePositionFragment on FixedIncomePosition {
    id
    nickname

    duration
    quantity
    referenceUnitPrice
    currentPrice
    applicationDate
    maturityDate
    productKind
    averageAgio
    creationMode

    brokerAccount {
      customerName
      accountNumber
      advisor {
        externalId
      }
    }

    rebuyUnitPrice
    rebuyYieldDescription {
      indexer
      yieldRate
      indexerPercentRate
    }
    yieldDescription {
      indexer
      indexerPercentRate
      yieldRate
    }
    financials(financialParams: $financialParams) @include(if: $withFinancials) {
      advisorROAReais
      customerMarkUp
      dealLink
    }
    createdBy
  }
`;

export const useFixedIncomePositionsToDeal = (
  search: string,
  pagination: IPaginationParams,
  sort: ISortParams | undefined,
  filter: FixedIncomePositionFilter | undefined,
  offerId: MaybeNil<string>,
  quantity: number,
) => {
  const { offerCustomYieldDescriptionMap } = useOfferCustomYieldDescription();

  const variables = getFixedIncomePositionsToDealVariables(
    search,
    pagination,
    sort,
    filter,
    offerId
      ? {
          offerId,
          quantity,
          offerCustomYieldDescriptionMap,
        }
      : undefined,
  );

  const { data, loading, error } = useQuery<
    FixedIncomePositionsToDealQuery,
    FixedIncomePositionsToDealQueryVariables
  >(FIXED_INCOME_POSITIONS_TO_DEAL_QUERY, {
    variables,
    fetchPolicy: 'cache-and-network',
  });

  return {
    totalRows: data?.paginatedPositionsToDeal.pageInfo.totalEdges,
    fixedIncomePositions: data?.paginatedPositionsToDeal.edges.map(
      ({ node: position }) => ({
        ...position,
        applicationDate: parseServerDate(position.applicationDate),
        maturityDate: parseServerDate(position.maturityDate),
      }),
    ),
    loading,
    error,
  };
};
