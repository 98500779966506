import { Button } from '@invest-ai/animatrix';
import React from 'react';

export const ImportSheetButton: React.FC<{
  onSelectFile: (formData: File) => void;
  loading: boolean;
}> = ({ onSelectFile: onClick, loading }) => {
  return (
    <Button
      variant='contained'
      component='label'
      color='primary'
      loading={loading}
    >
      Selecionar Planilha
      <input
        type='file'
        hidden
        onChange={(event) => {
          const target = event.target as HTMLInputElement;
          const file = target.files as FileList;

          onClick(file[0]);
        }}
        accept='.xlsx,.xls,.csv,.xlsm'
      />
    </Button>
  );
};
