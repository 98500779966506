import { GridSortModel } from '@mui/x-data-grid-premium';
import { ISortParams } from '../interfaces/ISortParams';
import { mapSortDirectionToGridSortDirection } from './mapGridSortDirectionToSortDirection';

export const mapSortParamsToSortModel = (
  sortParams: ISortParams | undefined,
): GridSortModel => {
  if (!sortParams) {
    return [];
  }
  return [
    {
      field: sortParams.field,
      sort: mapSortDirectionToGridSortDirection(sortParams.direction),
    },
  ];
};
