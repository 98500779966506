import useLocalStorageState from 'use-local-storage-state';
import { YieldDescription } from '../../../../../__generated__/types';

export type ICustomYieldDescriptionStorage = Record<string, YieldDescription>;

const OFFER_CUSTOM_YIELD_DESCRIPTION_STORAGE =
  'OFFER_CUSTOM_YIELD_DESCRIPTION_STORAGE';

export const useOfferCustomYieldDescription = () => {
  const [offerCustomYieldDescriptionMap, _setOfferYieldDescription] =
    useLocalStorageState<ICustomYieldDescriptionStorage>(
      OFFER_CUSTOM_YIELD_DESCRIPTION_STORAGE,
      {
        defaultValue: {},
      },
    );

  const setOfferYieldDescription = (
    id: string,
    yieldDescription: YieldDescription,
  ): void => {
    _setOfferYieldDescription((s) => ({
      ...s,
      [id]: yieldDescription,
    }));
  };

  return {
    offerCustomYieldDescriptionMap,
    setOfferYieldDescription,
  };
};
