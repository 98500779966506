import { isNil } from 'ramda';
import { MaybeNil } from '../../../../deals/components/DisplayDeal/logic/MaybeNil';

export const mapIntervalToGraphQLInterval = (
  interval: [MaybeNil<number>, MaybeNil<number>],
): MaybeNil<{ min: MaybeNil<number>; max: MaybeNil<number> }> => {
  if (!interval) {
    return null;
  }
  if (isNil(interval[0]) && isNil(interval[1])) {
    return null;
  }
  return {
    min: interval[0],
    max: interval[1],
  };
};
