import { AxiosError } from 'axios';

export const getMessageFromAxiosError = (
  error: AxiosError<{
    code?: string;
    financialInstitutionId?: string;
    message?: string;
    customerIds?: string[];
  }>,
): string => {
  const code = error.response?.data?.code;
  if (code) {
    if (code === 'NO_ADVISORY_OFFICE') {
      return 'Usuário não está atrelado a nenhum escritório. Entre em contato com o suporte';
    }
    if (code === 'FINANCIAL_INSTITUTION_NOT_SUPPORTED') {
      const financialInstitutionId =
        error.response?.data?.financialInstitutionId;
      return `Corretora não suportada: ${financialInstitutionId}. Entre em contato com o suporte`;
    }
    if (code === 'NO_DATA') {
      return 'Nenhum dado foi extraído da planilha. Verifique o arquivo enviado';
    }
    if (code === 'NO_FILTERED_DATA') {
      return 'Nenhuma linha válida foi extraída da planilha. Verifique o arquivo enviado';
    }
    if (code === 'EMPTY_POSITIONS_ARRAY') {
      return 'Nenhuma posição foi extraída da planilha. Verifique o arquivo enviado';
    }
    if (code === 'WORKBOOK_NOT_FOUND') {
      return 'Nenhum dado foi extraída da planilha. Verifique o arquivo enviado';
    }
    if (code === 'WORKSHEET_NOT_FOUND') {
      return 'Nenhum dado foi extraída da planilha. Verifique o arquivo enviado';
    }
    if (code === 'CUSTOMER_WITH_MULTIPLE_ADVISORS') {
      const customerIds = error.response?.data?.customerIds ?? [];
      return `Existem clientes atrelados a mais de um assessor. Códigos: ${customerIds.join(
        ', ',
      )}`;
    }
  }

  return error.response?.data?.message ?? error.message;
};
