import { Page } from '@invest-ai/animatrix';
import { PositionDrawer } from '../../../custom-position/components/PositionDrawer';
import { CompareOffersDashboard } from '../../components/CompareOffersDashboard';

import { Container } from '@mui/material';
import { OfferDrawers } from '../../../offers/components/OffersDrawer';
import { useComparadorOfferDrawersNavigation } from '../../../offers/context/useComparadorOfferDrawersNavigation';
import {
  COMPARATOR_POSITIONS_FILTER_KEY,
  FixedIncomePositionsListParamsContext,
} from '../../components/FixedIncomePositionsList/hooks/useFixedIncomePositionsListParams';
import { MGMBanner } from '../ListPositions/MGMBanner';

export const CompareOffersPage: React.FC = () => {
  return (
    <Page title='InvestAI Carteira+ | Comparador'>
      <Container maxWidth='xl'>
        <MGMBanner />
        <FixedIncomePositionsListParamsContext.Provider
          initialState={{
            localStorageFilterKey: COMPARATOR_POSITIONS_FILTER_KEY,
          }}
        >
          <CompareOffersDashboard />
          <PositionDrawer baseUrl='/comparador/' />
          <OfferDrawers navigation={useComparadorOfferDrawersNavigation} />
        </FixedIncomePositionsListParamsContext.Provider>
      </Container>
    </Page>
  );
};
