import { formatReais } from '@invest-ai/common';

export const formatReaisShortened = (n: number) => {
  if (n >= 1000000000) {
    return `${formatReais(n / 1000000000)} B`;
  }
  if (n >= 1000000) {
    return `${formatReais(n / 1000000)} M`;
  }

  if (n >= 1000) {
    return `${formatReais(n / 1000)} K`;
  }
  return formatReais(n);
};
