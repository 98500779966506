import { FixedIncomePositionCreationMode } from '../../../../__generated__/types';

export const CREATION_MODE_OPTIONS = [
  {
    label: 'PLANILHA',
    value: FixedIncomePositionCreationMode.XLSX_IMPORT,
  },
  {
    label: 'MANUAL',
    value: FixedIncomePositionCreationMode.MANUAL,
  },
];
