import Bugsnag from '@bugsnag/js';
import { BugsnagErrorBoundary } from '@bugsnag/plugin-react';
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import React from 'react';
import { createRoot } from 'react-dom/client';

import { LicenseInfo } from '@mui/x-license-pro';
import { CorleoneApp } from './CorleoneApp';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  TimeScale,
);

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_LICENSE_KEY as string);

const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(
  React,
) as BugsnagErrorBoundary;

const container = document.getElementById('root');
// biome-ignore lint/style/noNonNullAssertion: <explanation>
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <CorleoneApp />
    </ErrorBoundary>
  </React.StrictMode>,
);
