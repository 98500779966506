import React from 'react';
import { toast } from 'react-toastify';
import { InvestAIBU } from 'src/__generated__/types';
import { MGMBoletadorHowItWorks } from './MGMBoletadorHowItWorks';
import { MGMBoletadorMyReferrals } from './MGMBoletadorMyReferrals';
import { MyCoupon } from './MyCoupon';

export const MGMBoletador: React.FC = () => {
  return (
    <>
      <MyCoupon target={InvestAIBU.BOLETADOR} />

      <MGMBoletadorHowItWorks />

      <MGMBoletadorMyReferrals />
    </>
  );
};
