import { QueryHookOptions, gql, useQuery } from '@apollo/client';
import {
  MyAdvisoryOfficeQuery,
  MyAdvisoryOfficeQueryVariables,
} from '../../../__generated__/types';

export const MY_ADVISORY_OFFICE_QUERY = gql`
  query MyAdvisoryOffice {
  myAdvisoryOffice {
    id
    name
    financialInstitution {
      id
      name
      iconImgSrc
    }
  }
}
`;

export const useMyAdvisoryOffice = (
  options?: QueryHookOptions<
    MyAdvisoryOfficeQuery,
    MyAdvisoryOfficeQueryVariables
  >,
) => {
  const { data, loading, error } = useQuery<
    MyAdvisoryOfficeQuery,
    MyAdvisoryOfficeQueryVariables
  >(MY_ADVISORY_OFFICE_QUERY, {
    ...options,
  });

  return {
    advisoryOffice: data?.myAdvisoryOffice,
    loading,
    error,
  };
};
