import ContentLoader from 'react-content-loader';

export const LoadingText: React.FC<{
  height?: number;
}> = ({ height = 24 }) => {
  return (
    <ContentLoader
      style={{ display: 'block' }}
      speed={1}
      backgroundColor='#f3f3f3'
      foregroundColor='#ecebeb'
      width={100}
      height={height}
    >
      <rect x='0' y='0' rx='5' ry='5' width={100} height={height} />
    </ContentLoader>
  );
};
