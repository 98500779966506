import { CreatePositionDrawer } from '../CreatePositionDrawer';
import { EditPositionDrawer } from '../EditPositonDrawer';

interface IPositonsDrawerProps {
  baseUrl: string;
}

export const PositionDrawer: React.FC<IPositonsDrawerProps> = ({ baseUrl }) => {
  return (
    <>
      <CreatePositionDrawer baseUrl={baseUrl} />
      <EditPositionDrawer baseUrl={baseUrl} />
    </>
  );
};
