import { useOfferCustomYieldDescription } from '../components/DisplayDeal/hooks/useOfferCustomYieldDescription';
import { MaybeNil } from '../components/DisplayDeal/logic/MaybeNil';
import { useDealDashboard } from './useDealDashboard';

export const useCalculateDealFromRoute = ({
  offerId,
  positionId,
}: {
  offerId: MaybeNil<string>;
  positionId: string;
}) => {
  const shouldSkip = !positionId;

  const {
    fixedIncomeOffer,
    fixedIncomePosition,
    dealFinancials,
    customPitch,
    error,
    loading,
    refetch,
  } = useDealDashboard(
    {
      positionId,
      offerId,
    },
    {
      skip: shouldSkip,
    },
  );

  return {
    fixedIncomeOffer,
    fixedIncomePosition,
    dealFinancials,
    customPitch,
    error,
    loading,
    refetch,
  };
};
