import { FixedIncomeOfferTarget } from '../../../__generated__/types';

export const TARGET_PUBLIC_OPTIONS = [
  {
    label: 'Qualificado',
    value: FixedIncomeOfferTarget.QUALIFIED,
  },
  {
    label: 'Geral',
    value: FixedIncomeOfferTarget.GENERAL,
  },
];
