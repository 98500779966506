import { Indexers } from '@common/enum/Indexers';
import { YieldDescription } from '../../../../../../__generated__/types';

export const isCDUPlus = (yieldDescription: YieldDescription) => {
  return (
    yieldDescription.indexer === Indexers.CDI &&
    yieldDescription.indexerPercentRate === 1 &&
    yieldDescription.yieldRate !== 0
  );
};
