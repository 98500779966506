import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

export const bugsnagClient = Bugsnag.start({
  apiKey:
    (import.meta.env.VITE_BUGSNAG_API_KEY as string) ??
    '69e46590e0c32c13ecf09d606d2c3c00',
  plugins: [new BugsnagPluginReact()],

  enabledReleaseStages: ['production', 'staging'],
});
