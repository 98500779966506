import { GridFilterModel } from '@mui/x-data-grid-premium';

export const mapGridFilterModelToSearch = (
  gridFilterModel: GridFilterModel,
): string => {
  if (
    gridFilterModel.quickFilterValues &&
    gridFilterModel.quickFilterValues.length > 1
  ) {
    return gridFilterModel.quickFilterValues.join(' ');
  }
  return gridFilterModel.quickFilterValues?.[0] ?? '';
};
