import { IOfferDrawersNavigationHook } from '../../context/IOfferDrawersNavigationHook';
import { AvailableOffersDrawer } from './components/AvailableOffersDrawer';
import { CreateOfferDrawer } from './components/CreateOfferDrawer';
import { EditOfferDrawer } from './components/EditOfferDrawer';

interface IOffersDrawerProps {
  dealId?: string;
  positionIdToCompare?: string;
  navigation: IOfferDrawersNavigationHook;
}

export const OfferDrawers: React.FC<IOffersDrawerProps> = ({
  positionIdToCompare,
  navigation,
}) => {
  return (
    <>
      <AvailableOffersDrawer
        navigation={navigation}
        positionIdToCompare={positionIdToCompare}
      />
      <CreateOfferDrawer navigation={navigation} />
      <EditOfferDrawer navigation={navigation} />
    </>
  );
};
